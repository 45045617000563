@charset "UTF-8";
meta.foundation-mq-small {
  font-family: "/only screen/";
  width: 0;
}

meta.foundation-mq-small-only {
  font-family: "/only screen and (max-width: 40em)/";
  width: 0;
}

meta.foundation-mq-medium {
  font-family: "/only screen and (min-width:40.0625em)/";
  width: 40.0625em;
}

meta.foundation-mq-medium-only {
  font-family: "/only screen and (min-width:40.0625em) and (max-width:64em)/";
  width: 40.0625em;
}

meta.foundation-mq-large {
  font-family: "/only screen and (min-width:64.0625em)/";
  width: 64.0625em;
}

meta.foundation-mq-large-only {
  font-family: "/only screen and (min-width:64.0625em) and (max-width:90em)/";
  width: 64.0625em;
}

meta.foundation-mq-xlarge {
  font-family: "/only screen and (min-width:90.0625em)/";
  width: 90.0625em;
}

meta.foundation-mq-xlarge-only {
  font-family: "/only screen and (min-width:90.0625em) and (max-width:120em)/";
  width: 90.0625em;
}

meta.foundation-mq-xxlarge {
  font-family: "/only screen and (min-width:120.0625em)/";
  width: 120.0625em;
}

meta.foundation-data-attribute-namespace {
  font-family: false;
}

body,
html {
  height: 100%;
}

*,
:after,
:before {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

body,
html {
  font-size: 100%;
}

body {
  background: #fff;
  color: #222;
  cursor: auto;
  font-family: Helvetica Neue, Helvetica, Roboto, Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  padding: 0;
  position: relative;
}

a:hover {
  cursor: pointer;
}

img {
  max-width: 100%;
  height: auto;
  -ms-interpolation-mode: bicubic;
}

#map_canvas embed,
#map_canvas img,
#map_canvas object,
.map_canvas embed,
.map_canvas img,
.map_canvas object,
.mqa-display embed,
.mqa-display img,
.mqa-display object {
  max-width: none !important;
}

.left {
  float: left !important;
}

.right {
  float: right !important;
}

.clearfix:after,
.clearfix:before {
  content: " ";
  display: table;
}

.clearfix:after {
  clear: both;
}

.hide {
  display: none;
}

.invisible {
  visibility: hidden;
}

.antialiased {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

img {
  display: inline-block;
  vertical-align: middle;
}

textarea {
  height: auto;
  min-height: 50px;
}

select {
  width: 100%;
}

.button,
button {
  -webkit-appearance: none;
  -moz-appearance: none;
  border-radius: 0;
  cursor: pointer;
  font-family: Helvetica Neue, Helvetica, Roboto, Arial, sans-serif;
  font-weight: 400;
  line-height: normal;
  margin: 0 0 1.25rem;
  position: relative;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  padding: 1rem 2rem 1.0625rem;
  font-size: 1rem;
  background-color: #008cba;
  border: 0 solid #007095;
  color: #fff;
  -webkit-transition: background-color 0.3s ease-out;
  -o-transition: background-color 0.3s ease-out;
  transition: background-color 0.3s ease-out;
}

.button:focus,
.button:hover,
button:focus,
button:hover {
  background-color: #007095;
  color: #fff;
}

.button.secondary,
button.secondary {
  background-color: #e7e7e7;
  border-color: #b9b9b9;
  color: #333;
}

.button.secondary:focus,
.button.secondary:hover,
button.secondary:focus,
button.secondary:hover {
  background-color: #b9b9b9;
  color: #333;
}

.button.success,
button.success {
  background-color: #43ac6a;
  border-color: #368a55;
  color: #fff;
}

.button.success:focus,
.button.success:hover,
button.success:focus,
button.success:hover {
  background-color: #368a55;
  color: #fff;
}

.button.alert,
button.alert {
  background-color: #f04124;
  border-color: #cf2a0e;
  color: #fff;
}

.button.alert:focus,
.button.alert:hover,
button.alert:focus,
button.alert:hover {
  background-color: #cf2a0e;
  color: #fff;
}

.button.warning,
button.warning {
  background-color: #f08a24;
  border-color: #cf6e0e;
  color: #fff;
}

.button.warning:focus,
.button.warning:hover,
button.warning:focus,
button.warning:hover {
  background-color: #cf6e0e;
  color: #fff;
}

.button.info,
button.info {
  background-color: #a0d3e8;
  border-color: #61b6d9;
  color: #333;
}

.button.info:focus,
.button.info:hover,
button.info:focus,
button.info:hover {
  background-color: #61b6d9;
  color: #fff;
}

.button.large,
button.large {
  padding: 1.125rem 2.25rem 1.1875rem;
  font-size: 1.25rem;
}

.button.small,
button.small {
  padding: 0.875rem 1.75rem 0.9375rem;
  font-size: 0.8125rem;
}

.button.tiny,
button.tiny {
  padding: 0.625rem 1.25rem 0.6875rem;
  font-size: 0.6875rem;
}

.button.expand,
button.expand {
  padding: 1rem 2rem 1.0625rem;
  font-size: 1rem;
  padding: 1rem 1rem 1.0625rem;
  width: 100%;
}

.button.left-align,
button.left-align {
  text-align: left;
  text-indent: 0.75rem;
}

.button.right-align,
button.right-align {
  text-align: right;
  padding-right: 0.75rem;
}

.button.radius,
button.radius {
  border-radius: 3px;
}

.button.round,
button.round {
  border-radius: 1000px;
}

.button.disabled,
.button[disabled],
button.disabled,
button[disabled] {
  background-color: #008cba;
  border-color: #007095;
  color: #fff;
  -webkit-box-shadow: none;
  box-shadow: none;
  cursor: default;
  opacity: 0.7;
}

.button.disabled:focus,
.button.disabled:hover,
.button[disabled]:focus,
.button[disabled]:hover,
button.disabled:focus,
button.disabled:hover,
button[disabled]:focus,
button[disabled]:hover {
  background-color: #007095;
  color: #fff;
  background-color: #008cba;
}

.button.disabled.secondary,
.button[disabled].secondary,
button.disabled.secondary,
button[disabled].secondary {
  background-color: #e7e7e7;
  border-color: #b9b9b9;
  color: #333;
  -webkit-box-shadow: none;
  box-shadow: none;
  cursor: default;
  opacity: 0.7;
}

.button.disabled.secondary:focus,
.button.disabled.secondary:hover,
.button[disabled].secondary:focus,
.button[disabled].secondary:hover,
button.disabled.secondary:focus,
button.disabled.secondary:hover,
button[disabled].secondary:focus,
button[disabled].secondary:hover {
  background-color: #b9b9b9;
  color: #333;
  background-color: #e7e7e7;
}

.button.disabled.success,
.button[disabled].success,
button.disabled.success,
button[disabled].success {
  background-color: #43ac6a;
  border-color: #368a55;
  color: #fff;
  -webkit-box-shadow: none;
  box-shadow: none;
  cursor: default;
  opacity: 0.7;
}

.button.disabled.success:focus,
.button.disabled.success:hover,
.button[disabled].success:focus,
.button[disabled].success:hover,
button.disabled.success:focus,
button.disabled.success:hover,
button[disabled].success:focus,
button[disabled].success:hover {
  background-color: #368a55;
  color: #fff;
  background-color: #43ac6a;
}

.button.disabled.alert,
.button[disabled].alert,
button.disabled.alert,
button[disabled].alert {
  background-color: #f04124;
  border-color: #cf2a0e;
  color: #fff;
  -webkit-box-shadow: none;
  box-shadow: none;
  cursor: default;
  opacity: 0.7;
}

.button.disabled.alert:focus,
.button.disabled.alert:hover,
.button[disabled].alert:focus,
.button[disabled].alert:hover,
button.disabled.alert:focus,
button.disabled.alert:hover,
button[disabled].alert:focus,
button[disabled].alert:hover {
  background-color: #cf2a0e;
  color: #fff;
  background-color: #f04124;
}

.button.disabled.warning,
.button[disabled].warning,
button.disabled.warning,
button[disabled].warning {
  background-color: #f08a24;
  border-color: #cf6e0e;
  color: #fff;
  -webkit-box-shadow: none;
  box-shadow: none;
  cursor: default;
  opacity: 0.7;
}

.button.disabled.warning:focus,
.button.disabled.warning:hover,
.button[disabled].warning:focus,
.button[disabled].warning:hover,
button.disabled.warning:focus,
button.disabled.warning:hover,
button[disabled].warning:focus,
button[disabled].warning:hover {
  background-color: #cf6e0e;
  color: #fff;
  background-color: #f08a24;
}

.button.disabled.info,
.button[disabled].info,
button.disabled.info,
button[disabled].info {
  background-color: #a0d3e8;
  border-color: #61b6d9;
  color: #333;
  -webkit-box-shadow: none;
  box-shadow: none;
  cursor: default;
  opacity: 0.7;
}

.button.disabled.info:focus,
.button.disabled.info:hover,
.button[disabled].info:focus,
.button[disabled].info:hover,
button.disabled.info:focus,
button.disabled.info:hover,
button[disabled].info:focus,
button[disabled].info:hover {
  background-color: #61b6d9;
  color: #fff;
  background-color: #a0d3e8;
}

button::-moz-focus-inner {
  border: 0;
  padding: 0;
}

@media only screen and (min-width: 40.0625em) {
  .button,
  button {
    display: inline-block;
  }
}

form {
  margin: 0 0 1rem;
}

form .row .row {
  margin: 0 -0.5rem;
}

form .row .row .column,
form .row .row .columns {
  padding: 0 0.5rem;
}

form .row .row.collapse {
  margin: 0;
}

form .row .row.collapse .column,
form .row .row.collapse .columns {
  padding: 0;
}

form .row .row.collapse input {
  -webkit-border-bottom-right-radius: 0;
  -webkit-border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

form .row input.column,
form .row input.columns,
form .row textarea.column,
form .row textarea.columns {
  padding-left: 0.5rem;
}

label {
  color: #4d4d4d;
  cursor: pointer;
  display: block;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  margin-bottom: 0;
}

label.right {
  float: none !important;
  text-align: right;
}

label.inline {
  margin: 0 0 1rem;
  padding: 0.5625rem 0;
}

label small {
  text-transform: capitalize;
  color: #676767;
}

.postfix,
.prefix {
  border-style: solid;
  border-width: 1px;
  display: block;
  font-size: 0.875rem;
  height: 2.3125rem;
  line-height: 2.3125rem;
  overflow: visible;
  padding-bottom: 0;
  padding-top: 0;
  position: relative;
  text-align: center;
  width: 100%;
  z-index: 2;
}

.postfix.button,
.prefix.button {
  border: none;
  padding: 0;
  text-align: center;
}

.prefix.button.radius {
  border-radius: 0;
  -webkit-border-bottom-left-radius: 3px;
  -webkit-border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px;
}

.postfix.button.radius {
  border-radius: 0;
  -webkit-border-bottom-right-radius: 3px;
  -webkit-border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px;
}

.prefix.button.round {
  border-radius: 0;
  -webkit-border-bottom-left-radius: 1000px;
  -webkit-border-top-left-radius: 1000px;
  border-bottom-left-radius: 1000px;
  border-top-left-radius: 1000px;
}

.postfix.button.round {
  border-radius: 0;
  -webkit-border-bottom-right-radius: 1000px;
  -webkit-border-top-right-radius: 1000px;
  border-bottom-right-radius: 1000px;
  border-top-right-radius: 1000px;
}

label.prefix,
span.prefix {
  background: #f2f2f2;
  border-right: none;
  color: #333;
  border-color: #ccc;
}

label.postfix,
span.postfix {
  background: #f2f2f2;
  border-left: none;
  color: #333;
  border-color: #ccc;
}

input:not([type]),
input[type="color"],
input[type="date"],
input[type="datetime-local"],
input[type="datetime"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="url"],
input[type="week"],
textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  border-radius: 0;
  background-color: #fff;
  border: 1px solid #ccc;
  -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  color: rgba(0, 0, 0, 0.75);
  display: block;
  font-family: inherit;
  font-size: 0.875rem;
  height: 2.3125rem;
  margin: 0 0 1rem;
  padding: 0.5rem;
  width: 100%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: border-color 0.15s linear, background 0.15s linear;
  -o-transition: border-color 0.15s linear, background 0.15s linear;
  transition: border-color 0.15s linear, background 0.15s linear;
}

input:not([type]):focus,
input[type="color"]:focus,
input[type="date"]:focus,
input[type="datetime-local"]:focus,
input[type="datetime"]:focus,
input[type="email"]:focus,
input[type="month"]:focus,
input[type="number"]:focus,
input[type="password"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="text"]:focus,
input[type="time"]:focus,
input[type="url"]:focus,
input[type="week"]:focus,
textarea:focus {
  background: #fafafa;
  border-color: #999;
  outline: none;
}

fieldset[disabled] input:not([type]),
fieldset[disabled] input[type="color"],
fieldset[disabled] input[type="date"],
fieldset[disabled] input[type="datetime-local"],
fieldset[disabled] input[type="datetime"],
fieldset[disabled] input[type="email"],
fieldset[disabled] input[type="month"],
fieldset[disabled] input[type="number"],
fieldset[disabled] input[type="password"],
fieldset[disabled] input[type="search"],
fieldset[disabled] input[type="tel"],
fieldset[disabled] input[type="text"],
fieldset[disabled] input[type="time"],
fieldset[disabled] input[type="url"],
fieldset[disabled] input[type="week"],
fieldset[disabled] textarea,
input:not([type]):disabled,
input:not([type])[disabled],
input:not([type])[readonly],
input[type="color"]:disabled,
input[type="color"][disabled],
input[type="color"][readonly],
input[type="date"]:disabled,
input[type="date"][disabled],
input[type="date"][readonly],
input[type="datetime-local"]:disabled,
input[type="datetime-local"][disabled],
input[type="datetime-local"][readonly],
input[type="datetime"]:disabled,
input[type="datetime"][disabled],
input[type="datetime"][readonly],
input[type="email"]:disabled,
input[type="email"][disabled],
input[type="email"][readonly],
input[type="month"]:disabled,
input[type="month"][disabled],
input[type="month"][readonly],
input[type="number"]:disabled,
input[type="number"][disabled],
input[type="number"][readonly],
input[type="password"]:disabled,
input[type="password"][disabled],
input[type="password"][readonly],
input[type="search"]:disabled,
input[type="search"][disabled],
input[type="search"][readonly],
input[type="tel"]:disabled,
input[type="tel"][disabled],
input[type="tel"][readonly],
input[type="text"]:disabled,
input[type="text"][disabled],
input[type="text"][readonly],
input[type="time"]:disabled,
input[type="time"][disabled],
input[type="time"][readonly],
input[type="url"]:disabled,
input[type="url"][disabled],
input[type="url"][readonly],
input[type="week"]:disabled,
input[type="week"][disabled],
input[type="week"][readonly],
textarea:disabled,
textarea[disabled],
textarea[readonly] {
  background-color: #ddd;
  cursor: default;
}

input:not([type]).radius,
input[type="color"].radius,
input[type="date"].radius,
input[type="datetime-local"].radius,
input[type="datetime"].radius,
input[type="email"].radius,
input[type="month"].radius,
input[type="number"].radius,
input[type="password"].radius,
input[type="search"].radius,
input[type="tel"].radius,
input[type="text"].radius,
input[type="time"].radius,
input[type="url"].radius,
input[type="week"].radius,
textarea.radius {
  border-radius: 3px;
}

form .row .prefix-radius.row.collapse button,
form .row .prefix-radius.row.collapse input,
form .row .prefix-radius.row.collapse select,
form .row .prefix-radius.row.collapse textarea {
  border-radius: 0;
  -webkit-border-bottom-right-radius: 3px;
  -webkit-border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px;
}

form .row .postfix-radius.row.collapse button,
form .row .postfix-radius.row.collapse input,
form .row .postfix-radius.row.collapse select,
form .row .postfix-radius.row.collapse textarea,
form .row .prefix-radius.row.collapse .prefix {
  border-radius: 0;
  -webkit-border-bottom-left-radius: 3px;
  -webkit-border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px;
}

form .row .postfix-radius.row.collapse .postfix {
  border-radius: 0;
  -webkit-border-bottom-right-radius: 3px;
  -webkit-border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px;
}

form .row .prefix-round.row.collapse button,
form .row .prefix-round.row.collapse input,
form .row .prefix-round.row.collapse select,
form .row .prefix-round.row.collapse textarea {
  border-radius: 0;
  -webkit-border-bottom-right-radius: 1000px;
  -webkit-border-top-right-radius: 1000px;
  border-bottom-right-radius: 1000px;
  border-top-right-radius: 1000px;
}

form .row .postfix-round.row.collapse button,
form .row .postfix-round.row.collapse input,
form .row .postfix-round.row.collapse select,
form .row .postfix-round.row.collapse textarea,
form .row .prefix-round.row.collapse .prefix {
  border-radius: 0;
  -webkit-border-bottom-left-radius: 1000px;
  -webkit-border-top-left-radius: 1000px;
  border-bottom-left-radius: 1000px;
  border-top-left-radius: 1000px;
}

form .row .postfix-round.row.collapse .postfix {
  border-radius: 0;
  -webkit-border-bottom-right-radius: 1000px;
  -webkit-border-top-right-radius: 1000px;
  border-bottom-right-radius: 1000px;
  border-top-right-radius: 1000px;
}

input[type="submit"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  border-radius: 0;
}

textarea[rows] {
  height: auto;
}

textarea {
  max-width: 100%;
}

::-webkit-input-placeholder {
  color: #666;
}

:-moz-placeholder,
::-moz-placeholder {
  color: #666;
}

:-ms-input-placeholder {
  color: #666;
}

select {
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  background-color: #fafafa;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgeD0iMTJweCIgeT0iMHB4IiB3aWR0aD0iMjRweCIgaGVpZ2h0PSIzcHgiIHZpZXdCb3g9IjAgMCA2IDMiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDYgMyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PHBvbHlnb24gcG9pbnRzPSI1Ljk5MiwwIDIuOTkyLDMgLTAuMDA4LDAgIi8+PC9zdmc+");
  background-position: 100%;
  background-repeat: no-repeat;
  border: 1px solid #ccc;
  color: rgba(0, 0, 0, 0.75);
  font-family: inherit;
  font-size: 0.875rem;
  line-height: normal;
  padding: 0.5rem;
  border-radius: 0;
  height: 2.3125rem;
}

select::-ms-expand {
  display: none;
}

select.radius {
  border-radius: 3px;
}

select:focus {
  background-color: #f3f3f3;
  border-color: #999;
}

select:disabled {
  background-color: #ddd;
  cursor: default;
}

select[multiple] {
  height: auto;
}

input[type="checkbox"],
input[type="file"],
input[type="radio"],
select {
  margin: 0 0 1rem;
}

input[type="checkbox"] + label,
input[type="radio"] + label {
  display: inline-block;
  margin-left: 0.5rem;
  margin-right: 1rem;
  margin-bottom: 0;
  vertical-align: baseline;
}

input[type="file"] {
  width: 100%;
}

fieldset {
  border: 1px solid #ddd;
  margin: 1.125rem 0;
  padding: 1.25rem;
}

fieldset legend {
  font-weight: 700;
  margin: 0;
  margin-left: -0.1875rem;
  padding: 0 0.1875rem;
}

[data-abide] .error small.error,
[data-abide] .error span.error,
[data-abide] small.error,
[data-abide] span.error {
  display: block;
  font-size: 0.75rem;
  font-style: italic;
  font-weight: 400;
  margin-bottom: 1rem;
  margin-top: -1px;
  padding: 0.375rem 0.5625rem 0.5625rem;
  background: #f04124;
  color: #fff;
}

[data-abide] small.error,
[data-abide] span.error {
  display: none;
}

small.error,
span.error {
  display: block;
  font-size: 0.75rem;
  font-style: italic;
  font-weight: 400;
  margin-bottom: 1rem;
  margin-top: -1px;
  padding: 0.375rem 0.5625rem 0.5625rem;
  background: #f04124;
  color: #fff;
}

.error input,
.error select,
.error textarea {
  margin-bottom: 0;
}

.error input[type="checkbox"],
.error input[type="radio"] {
  margin-bottom: 1rem;
}

.error label,
.error label.error {
  color: #f04124;
}

.error small.error {
  display: block;
  font-size: 0.75rem;
  font-style: italic;
  font-weight: 400;
  margin-bottom: 1rem;
  margin-top: -1px;
  padding: 0.375rem 0.5625rem 0.5625rem;
  background: #f04124;
  color: #fff;
}

.error > label > small {
  background: transparent;
  color: #676767;
  display: inline;
  font-size: 60%;
  font-style: normal;
  margin: 0;
  padding: 0;
  text-transform: capitalize;
}

.error span.error-message {
  display: block;
}

input.error,
select.error,
textarea.error {
  margin-bottom: 0;
}

label.error {
  color: #f04124;
}

.accordion {
  margin-bottom: 0;
  margin-left: 0;
}

.accordion:after,
.accordion:before {
  content: " ";
  display: table;
}

.accordion:after {
  clear: both;
}

.accordion .accordion-navigation,
.accordion dd {
  display: block;
  margin-bottom: 0 !important;
}

.accordion .accordion-navigation.active > a,
.accordion dd.active > a {
  background: #e8e8e8;
  color: #222;
}

.accordion .accordion-navigation > a,
.accordion dd > a {
  background: #efefef;
  color: #222;
  display: block;
  font-family: Helvetica Neue, Helvetica, Roboto, Arial, sans-serif;
  font-size: 1rem;
  padding: 1rem;
}

.accordion .accordion-navigation > a:hover,
.accordion dd > a:hover {
  background: #e3e3e3;
}

.accordion .accordion-navigation > .content,
.accordion dd > .content {
  display: none;
  padding: 0.9375rem;
}

.accordion .accordion-navigation > .content.active,
.accordion dd > .content.active {
  background: #fff;
  display: block;
}

html {
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body {
  margin: 0;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block;
}

audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

[hidden],
template {
  display: none;
}

a {
  background-color: transparent;
}

a:active,
a:hover {
  outline: 0;
}

abbr[title] {
  border-bottom: 1px dotted;
}

b,
strong {
  font-weight: 700;
}

dfn {
  font-style: italic;
}

h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

mark {
  background: #ff0;
  color: #000;
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

img {
  border: 0;
}

svg:not(:root) {
  overflow: hidden;
}

figure {
  margin: 1em 40px;
}

hr {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  height: 0;
}

pre {
  overflow: auto;
}

code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font: inherit;
  margin: 0;
}

button {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
}

button[disabled],
html input[disabled] {
  cursor: default;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

input {
  line-height: normal;
}

input[type="checkbox"],
input[type="radio"] {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

input[type="search"] {
  -webkit-appearance: textfield;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

fieldset {
  border: 1px solid silver;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}

legend {
  border: 0;
  padding: 0;
}

textarea {
  overflow: auto;
}

optgroup {
  font-weight: 700;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

td,
th {
  padding: 0;
}

.row {
  margin: 0 auto;
  max-width: 1200px;
  width: 100%;
}

.row:after,
.row:before {
  content: " ";
  display: table;
}

.row:after {
  clear: both;
}

.row.collapse > .column,
.row.collapse > .columns {
  padding-left: 0;
  padding-right: 0;
}

.row.collapse .row {
  margin-left: 0;
  margin-right: 0;
}

.row .row {
  margin: 0 -0.9375rem;
  max-width: none;
  width: auto;
}

.row .row:after,
.row .row:before {
  content: " ";
  display: table;
}

.row .row:after {
  clear: both;
}

.row .row.collapse {
  margin: 0;
  max-width: none;
  width: auto;
}

.row .row.collapse:after,
.row .row.collapse:before {
  content: " ";
  display: table;
}

.row .row.collapse:after {
  clear: both;
}

.column,
.columns {
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  width: 100%;
  float: left;
}

.column + .column:last-child,
.column + .columns:last-child,
.columns + .column:last-child,
.columns + .columns:last-child {
  float: right;
}

.column + .column.end,
.column + .columns.end,
.columns + .column.end,
.columns + .columns.end {
  float: left;
}

@media only screen {
  .small-push-0 {
    position: relative;
    left: 0;
    right: auto;
  }
  .small-pull-0 {
    position: relative;
    right: 0;
    left: auto;
  }
  .small-push-1 {
    position: relative;
    left: 8.33333%;
    right: auto;
  }
  .small-pull-1 {
    position: relative;
    right: 8.33333%;
    left: auto;
  }
  .small-push-2 {
    position: relative;
    left: 16.66667%;
    right: auto;
  }
  .small-pull-2 {
    position: relative;
    right: 16.66667%;
    left: auto;
  }
  .small-push-3 {
    position: relative;
    left: 25%;
    right: auto;
  }
  .small-pull-3 {
    position: relative;
    right: 25%;
    left: auto;
  }
  .small-push-4 {
    position: relative;
    left: 33.33333%;
    right: auto;
  }
  .small-pull-4 {
    position: relative;
    right: 33.33333%;
    left: auto;
  }
  .small-push-5 {
    position: relative;
    left: 41.66667%;
    right: auto;
  }
  .small-pull-5 {
    position: relative;
    right: 41.66667%;
    left: auto;
  }
  .small-push-6 {
    position: relative;
    left: 50%;
    right: auto;
  }
  .small-pull-6 {
    position: relative;
    right: 50%;
    left: auto;
  }
  .small-push-7 {
    position: relative;
    left: 58.33333%;
    right: auto;
  }
  .small-pull-7 {
    position: relative;
    right: 58.33333%;
    left: auto;
  }
  .small-push-8 {
    position: relative;
    left: 66.66667%;
    right: auto;
  }
  .small-pull-8 {
    position: relative;
    right: 66.66667%;
    left: auto;
  }
  .small-push-9 {
    position: relative;
    left: 75%;
    right: auto;
  }
  .small-pull-9 {
    position: relative;
    right: 75%;
    left: auto;
  }
  .small-push-10 {
    position: relative;
    left: 83.33333%;
    right: auto;
  }
  .small-pull-10 {
    position: relative;
    right: 83.33333%;
    left: auto;
  }
  .small-push-11 {
    position: relative;
    left: 91.66667%;
    right: auto;
  }
  .small-pull-11 {
    position: relative;
    right: 91.66667%;
    left: auto;
  }
  .column,
  .columns {
    position: relative;
    padding-left: 0.9375rem;
    padding-right: 0.9375rem;
    float: left;
  }
  .small-1 {
    width: 8.33333%;
  }
  .small-2 {
    width: 16.66667%;
  }
  .small-3 {
    width: 25%;
  }
  .small-4 {
    width: 33.33333%;
  }
  .small-5 {
    width: 41.66667%;
  }
  .small-6 {
    width: 50%;
  }
  .small-7 {
    width: 58.33333%;
  }
  .small-8 {
    width: 66.66667%;
  }
  .small-9 {
    width: 75%;
  }
  .small-10 {
    width: 83.33333%;
  }
  .small-11 {
    width: 91.66667%;
  }
  .small-12 {
    width: 100%;
  }
  .small-offset-0 {
    margin-left: 0 !important;
  }
  .small-offset-1 {
    margin-left: 8.33333% !important;
  }
  .small-offset-2 {
    margin-left: 16.66667% !important;
  }
  .small-offset-3 {
    margin-left: 25% !important;
  }
  .small-offset-4 {
    margin-left: 33.33333% !important;
  }
  .small-offset-5 {
    margin-left: 41.66667% !important;
  }
  .small-offset-6 {
    margin-left: 50% !important;
  }
  .small-offset-7 {
    margin-left: 58.33333% !important;
  }
  .small-offset-8 {
    margin-left: 66.66667% !important;
  }
  .small-offset-9 {
    margin-left: 75% !important;
  }
  .small-offset-10 {
    margin-left: 83.33333% !important;
  }
  .small-offset-11 {
    margin-left: 91.66667% !important;
  }
  .small-reset-order {
    float: left;
    left: auto;
    margin-left: 0;
    margin-right: 0;
    right: auto;
  }
  .column.small-centered,
  .columns.small-centered {
    margin-left: auto;
    margin-right: auto;
    float: none;
  }
  .column.small-uncentered,
  .columns.small-uncentered {
    float: left;
    margin-left: 0;
    margin-right: 0;
  }
  .column.small-centered:last-child,
  .columns.small-centered:last-child {
    float: none;
  }
  .column.small-uncentered:last-child,
  .columns.small-uncentered:last-child {
    float: left;
  }
  .column.small-uncentered.opposite,
  .columns.small-uncentered.opposite {
    float: right;
  }
  .row.small-collapse > .column,
  .row.small-collapse > .columns {
    padding-left: 0;
    padding-right: 0;
  }
  .row.small-collapse .row {
    margin-left: 0;
    margin-right: 0;
  }
  .row.small-uncollapse > .column,
  .row.small-uncollapse > .columns {
    padding-left: 0.9375rem;
    padding-right: 0.9375rem;
    float: left;
  }
}

@media only screen and (min-width: 40.0625em) {
  .medium-push-0 {
    position: relative;
    left: 0;
    right: auto;
  }
  .medium-pull-0 {
    position: relative;
    right: 0;
    left: auto;
  }
  .medium-push-1 {
    position: relative;
    left: 8.33333%;
    right: auto;
  }
  .medium-pull-1 {
    position: relative;
    right: 8.33333%;
    left: auto;
  }
  .medium-push-2 {
    position: relative;
    left: 16.66667%;
    right: auto;
  }
  .medium-pull-2 {
    position: relative;
    right: 16.66667%;
    left: auto;
  }
  .medium-push-3 {
    position: relative;
    left: 25%;
    right: auto;
  }
  .medium-pull-3 {
    position: relative;
    right: 25%;
    left: auto;
  }
  .medium-push-4 {
    position: relative;
    left: 33.33333%;
    right: auto;
  }
  .medium-pull-4 {
    position: relative;
    right: 33.33333%;
    left: auto;
  }
  .medium-push-5 {
    position: relative;
    left: 41.66667%;
    right: auto;
  }
  .medium-pull-5 {
    position: relative;
    right: 41.66667%;
    left: auto;
  }
  .medium-push-6 {
    position: relative;
    left: 50%;
    right: auto;
  }
  .medium-pull-6 {
    position: relative;
    right: 50%;
    left: auto;
  }
  .medium-push-7 {
    position: relative;
    left: 58.33333%;
    right: auto;
  }
  .medium-pull-7 {
    position: relative;
    right: 58.33333%;
    left: auto;
  }
  .medium-push-8 {
    position: relative;
    left: 66.66667%;
    right: auto;
  }
  .medium-pull-8 {
    position: relative;
    right: 66.66667%;
    left: auto;
  }
  .medium-push-9 {
    position: relative;
    left: 75%;
    right: auto;
  }
  .medium-pull-9 {
    position: relative;
    right: 75%;
    left: auto;
  }
  .medium-push-10 {
    position: relative;
    left: 83.33333%;
    right: auto;
  }
  .medium-pull-10 {
    position: relative;
    right: 83.33333%;
    left: auto;
  }
  .medium-push-11 {
    position: relative;
    left: 91.66667%;
    right: auto;
  }
  .medium-pull-11 {
    position: relative;
    right: 91.66667%;
    left: auto;
  }
  .column,
  .columns {
    position: relative;
    padding-left: 0.9375rem;
    padding-right: 0.9375rem;
    float: left;
  }
  .medium-1 {
    width: 8.33333%;
  }
  .medium-2 {
    width: 16.66667%;
  }
  .medium-3 {
    width: 25%;
  }
  .medium-4 {
    width: 33.33333%;
  }
  .medium-5 {
    width: 41.66667%;
  }
  .medium-6 {
    width: 50%;
  }
  .medium-7 {
    width: 58.33333%;
  }
  .medium-8 {
    width: 66.66667%;
  }
  .medium-9 {
    width: 75%;
  }
  .medium-10 {
    width: 83.33333%;
  }
  .medium-11 {
    width: 91.66667%;
  }
  .medium-12 {
    width: 100%;
  }
  .medium-offset-0 {
    margin-left: 0 !important;
  }
  .medium-offset-1 {
    margin-left: 8.33333% !important;
  }
  .medium-offset-2 {
    margin-left: 16.66667% !important;
  }
  .medium-offset-3 {
    margin-left: 25% !important;
  }
  .medium-offset-4 {
    margin-left: 33.33333% !important;
  }
  .medium-offset-5 {
    margin-left: 41.66667% !important;
  }
  .medium-offset-6 {
    margin-left: 50% !important;
  }
  .medium-offset-7 {
    margin-left: 58.33333% !important;
  }
  .medium-offset-8 {
    margin-left: 66.66667% !important;
  }
  .medium-offset-9 {
    margin-left: 75% !important;
  }
  .medium-offset-10 {
    margin-left: 83.33333% !important;
  }
  .medium-offset-11 {
    margin-left: 91.66667% !important;
  }
  .medium-reset-order {
    float: left;
    left: auto;
    margin-left: 0;
    margin-right: 0;
    right: auto;
  }
  .column.medium-centered,
  .columns.medium-centered {
    margin-left: auto;
    margin-right: auto;
    float: none;
  }
  .column.medium-uncentered,
  .columns.medium-uncentered {
    float: left;
    margin-left: 0;
    margin-right: 0;
  }
  .column.medium-centered:last-child,
  .columns.medium-centered:last-child {
    float: none;
  }
  .column.medium-uncentered:last-child,
  .columns.medium-uncentered:last-child {
    float: left;
  }
  .column.medium-uncentered.opposite,
  .columns.medium-uncentered.opposite {
    float: right;
  }
  .row.medium-collapse > .column,
  .row.medium-collapse > .columns {
    padding-left: 0;
    padding-right: 0;
  }
  .row.medium-collapse .row {
    margin-left: 0;
    margin-right: 0;
  }
  .row.medium-uncollapse > .column,
  .row.medium-uncollapse > .columns {
    padding-left: 0.9375rem;
    padding-right: 0.9375rem;
    float: left;
  }
  .push-0 {
    left: 0;
    right: auto;
  }
  .pull-0,
  .push-0 {
    position: relative;
  }
  .pull-0 {
    right: 0;
    left: auto;
  }
  .push-1 {
    left: 8.33333%;
    right: auto;
  }
  .pull-1,
  .push-1 {
    position: relative;
  }
  .pull-1 {
    right: 8.33333%;
    left: auto;
  }
  .push-2 {
    left: 16.66667%;
    right: auto;
  }
  .pull-2,
  .push-2 {
    position: relative;
  }
  .pull-2 {
    right: 16.66667%;
    left: auto;
  }
  .push-3 {
    left: 25%;
    right: auto;
  }
  .pull-3,
  .push-3 {
    position: relative;
  }
  .pull-3 {
    right: 25%;
    left: auto;
  }
  .push-4 {
    left: 33.33333%;
    right: auto;
  }
  .pull-4,
  .push-4 {
    position: relative;
  }
  .pull-4 {
    right: 33.33333%;
    left: auto;
  }
  .push-5 {
    left: 41.66667%;
    right: auto;
  }
  .pull-5,
  .push-5 {
    position: relative;
  }
  .pull-5 {
    right: 41.66667%;
    left: auto;
  }
  .push-6 {
    left: 50%;
    right: auto;
  }
  .pull-6,
  .push-6 {
    position: relative;
  }
  .pull-6 {
    right: 50%;
    left: auto;
  }
  .push-7 {
    left: 58.33333%;
    right: auto;
  }
  .pull-7,
  .push-7 {
    position: relative;
  }
  .pull-7 {
    right: 58.33333%;
    left: auto;
  }
  .push-8 {
    left: 66.66667%;
    right: auto;
  }
  .pull-8,
  .push-8 {
    position: relative;
  }
  .pull-8 {
    right: 66.66667%;
    left: auto;
  }
  .push-9 {
    left: 75%;
    right: auto;
  }
  .pull-9,
  .push-9 {
    position: relative;
  }
  .pull-9 {
    right: 75%;
    left: auto;
  }
  .push-10 {
    position: relative;
    left: 83.33333%;
    right: auto;
  }
  .pull-10 {
    position: relative;
    right: 83.33333%;
    left: auto;
  }
  .push-11 {
    position: relative;
    left: 91.66667%;
    right: auto;
  }
  .pull-11 {
    position: relative;
    right: 91.66667%;
    left: auto;
  }
}

@media only screen and (min-width: 64.0625em) {
  .large-push-0 {
    position: relative;
    left: 0;
    right: auto;
  }
  .large-pull-0 {
    position: relative;
    right: 0;
    left: auto;
  }
  .large-push-1 {
    position: relative;
    left: 8.33333%;
    right: auto;
  }
  .large-pull-1 {
    position: relative;
    right: 8.33333%;
    left: auto;
  }
  .large-push-2 {
    position: relative;
    left: 16.66667%;
    right: auto;
  }
  .large-pull-2 {
    position: relative;
    right: 16.66667%;
    left: auto;
  }
  .large-push-3 {
    position: relative;
    left: 25%;
    right: auto;
  }
  .large-pull-3 {
    position: relative;
    right: 25%;
    left: auto;
  }
  .large-push-4 {
    position: relative;
    left: 33.33333%;
    right: auto;
  }
  .large-pull-4 {
    position: relative;
    right: 33.33333%;
    left: auto;
  }
  .large-push-5 {
    position: relative;
    left: 41.66667%;
    right: auto;
  }
  .large-pull-5 {
    position: relative;
    right: 41.66667%;
    left: auto;
  }
  .large-push-6 {
    position: relative;
    left: 50%;
    right: auto;
  }
  .large-pull-6 {
    position: relative;
    right: 50%;
    left: auto;
  }
  .large-push-7 {
    position: relative;
    left: 58.33333%;
    right: auto;
  }
  .large-pull-7 {
    position: relative;
    right: 58.33333%;
    left: auto;
  }
  .large-push-8 {
    position: relative;
    left: 66.66667%;
    right: auto;
  }
  .large-pull-8 {
    position: relative;
    right: 66.66667%;
    left: auto;
  }
  .large-push-9 {
    position: relative;
    left: 75%;
    right: auto;
  }
  .large-pull-9 {
    position: relative;
    right: 75%;
    left: auto;
  }
  .large-push-10 {
    position: relative;
    left: 83.33333%;
    right: auto;
  }
  .large-pull-10 {
    position: relative;
    right: 83.33333%;
    left: auto;
  }
  .large-push-11 {
    position: relative;
    left: 91.66667%;
    right: auto;
  }
  .large-pull-11 {
    position: relative;
    right: 91.66667%;
    left: auto;
  }
  .column,
  .columns {
    position: relative;
    padding-left: 0.9375rem;
    padding-right: 0.9375rem;
    float: left;
  }
  .large-1 {
    width: 8.33333%;
  }
  .large-2 {
    width: 16.66667%;
  }
  .large-3 {
    width: 25%;
  }
  .large-4 {
    width: 33.33333%;
  }
  .large-5 {
    width: 41.66667%;
  }
  .large-6 {
    width: 50%;
  }
  .large-7 {
    width: 58.33333%;
  }
  .large-8 {
    width: 66.66667%;
  }
  .large-9 {
    width: 75%;
  }
  .large-10 {
    width: 83.33333%;
  }
  .large-11 {
    width: 91.66667%;
  }
  .large-12 {
    width: 100%;
  }
  .large-offset-0 {
    margin-left: 0 !important;
  }
  .large-offset-1 {
    margin-left: 8.33333% !important;
  }
  .large-offset-2 {
    margin-left: 16.66667% !important;
  }
  .large-offset-3 {
    margin-left: 25% !important;
  }
  .large-offset-4 {
    margin-left: 33.33333% !important;
  }
  .large-offset-5 {
    margin-left: 41.66667% !important;
  }
  .large-offset-6 {
    margin-left: 50% !important;
  }
  .large-offset-7 {
    margin-left: 58.33333% !important;
  }
  .large-offset-8 {
    margin-left: 66.66667% !important;
  }
  .large-offset-9 {
    margin-left: 75% !important;
  }
  .large-offset-10 {
    margin-left: 83.33333% !important;
  }
  .large-offset-11 {
    margin-left: 91.66667% !important;
  }
  .large-reset-order {
    float: left;
    left: auto;
    margin-left: 0;
    margin-right: 0;
    right: auto;
  }
  .column.large-centered,
  .columns.large-centered {
    margin-left: auto;
    margin-right: auto;
    float: none;
  }
  .column.large-uncentered,
  .columns.large-uncentered {
    float: left;
    margin-left: 0;
    margin-right: 0;
  }
  .column.large-centered:last-child,
  .columns.large-centered:last-child {
    float: none;
  }
  .column.large-uncentered:last-child,
  .columns.large-uncentered:last-child {
    float: left;
  }
  .column.large-uncentered.opposite,
  .columns.large-uncentered.opposite {
    float: right;
  }
  .row.large-collapse > .column,
  .row.large-collapse > .columns {
    padding-left: 0;
    padding-right: 0;
  }
  .row.large-collapse .row {
    margin-left: 0;
    margin-right: 0;
  }
  .row.large-uncollapse > .column,
  .row.large-uncollapse > .columns {
    padding-left: 0.9375rem;
    padding-right: 0.9375rem;
    float: left;
  }
  .push-0 {
    left: 0;
    right: auto;
  }
  .pull-0,
  .push-0 {
    position: relative;
  }
  .pull-0 {
    right: 0;
    left: auto;
  }
  .push-1 {
    left: 8.33333%;
    right: auto;
  }
  .pull-1,
  .push-1 {
    position: relative;
  }
  .pull-1 {
    right: 8.33333%;
    left: auto;
  }
  .push-2 {
    left: 16.66667%;
    right: auto;
  }
  .pull-2,
  .push-2 {
    position: relative;
  }
  .pull-2 {
    right: 16.66667%;
    left: auto;
  }
  .push-3 {
    left: 25%;
    right: auto;
  }
  .pull-3,
  .push-3 {
    position: relative;
  }
  .pull-3 {
    right: 25%;
    left: auto;
  }
  .push-4 {
    left: 33.33333%;
    right: auto;
  }
  .pull-4,
  .push-4 {
    position: relative;
  }
  .pull-4 {
    right: 33.33333%;
    left: auto;
  }
  .push-5 {
    left: 41.66667%;
    right: auto;
  }
  .pull-5,
  .push-5 {
    position: relative;
  }
  .pull-5 {
    right: 41.66667%;
    left: auto;
  }
  .push-6 {
    left: 50%;
    right: auto;
  }
  .pull-6,
  .push-6 {
    position: relative;
  }
  .pull-6 {
    right: 50%;
    left: auto;
  }
  .push-7 {
    left: 58.33333%;
    right: auto;
  }
  .pull-7,
  .push-7 {
    position: relative;
  }
  .pull-7 {
    right: 58.33333%;
    left: auto;
  }
  .push-8 {
    left: 66.66667%;
    right: auto;
  }
  .pull-8,
  .push-8 {
    position: relative;
  }
  .pull-8 {
    right: 66.66667%;
    left: auto;
  }
  .push-9 {
    left: 75%;
    right: auto;
  }
  .pull-9,
  .push-9 {
    position: relative;
  }
  .pull-9 {
    right: 75%;
    left: auto;
  }
  .push-10 {
    position: relative;
    left: 83.33333%;
    right: auto;
  }
  .pull-10 {
    position: relative;
    right: 83.33333%;
    left: auto;
  }
  .push-11 {
    position: relative;
    left: 91.66667%;
    right: auto;
  }
  .pull-11 {
    position: relative;
    right: 91.66667%;
    left: auto;
  }
}

@media only screen and (min-width: 90.0625em) {
  .xlarge-push-0 {
    position: relative;
    left: 0;
    right: auto;
  }
  .xlarge-pull-0 {
    position: relative;
    right: 0;
    left: auto;
  }
  .xlarge-push-1 {
    position: relative;
    left: 8.33333%;
    right: auto;
  }
  .xlarge-pull-1 {
    position: relative;
    right: 8.33333%;
    left: auto;
  }
  .xlarge-push-2 {
    position: relative;
    left: 16.66667%;
    right: auto;
  }
  .xlarge-pull-2 {
    position: relative;
    right: 16.66667%;
    left: auto;
  }
  .xlarge-push-3 {
    position: relative;
    left: 25%;
    right: auto;
  }
  .xlarge-pull-3 {
    position: relative;
    right: 25%;
    left: auto;
  }
  .xlarge-push-4 {
    position: relative;
    left: 33.33333%;
    right: auto;
  }
  .xlarge-pull-4 {
    position: relative;
    right: 33.33333%;
    left: auto;
  }
  .xlarge-push-5 {
    position: relative;
    left: 41.66667%;
    right: auto;
  }
  .xlarge-pull-5 {
    position: relative;
    right: 41.66667%;
    left: auto;
  }
  .xlarge-push-6 {
    position: relative;
    left: 50%;
    right: auto;
  }
  .xlarge-pull-6 {
    position: relative;
    right: 50%;
    left: auto;
  }
  .xlarge-push-7 {
    position: relative;
    left: 58.33333%;
    right: auto;
  }
  .xlarge-pull-7 {
    position: relative;
    right: 58.33333%;
    left: auto;
  }
  .xlarge-push-8 {
    position: relative;
    left: 66.66667%;
    right: auto;
  }
  .xlarge-pull-8 {
    position: relative;
    right: 66.66667%;
    left: auto;
  }
  .xlarge-push-9 {
    position: relative;
    left: 75%;
    right: auto;
  }
  .xlarge-pull-9 {
    position: relative;
    right: 75%;
    left: auto;
  }
  .xlarge-push-10 {
    position: relative;
    left: 83.33333%;
    right: auto;
  }
  .xlarge-pull-10 {
    position: relative;
    right: 83.33333%;
    left: auto;
  }
  .xlarge-push-11 {
    position: relative;
    left: 91.66667%;
    right: auto;
  }
  .xlarge-pull-11 {
    position: relative;
    right: 91.66667%;
    left: auto;
  }
  .column,
  .columns {
    position: relative;
    padding-left: 0.9375rem;
    padding-right: 0.9375rem;
    float: left;
  }
  .xlarge-1 {
    width: 8.33333%;
  }
  .xlarge-2 {
    width: 16.66667%;
  }
  .xlarge-3 {
    width: 25%;
  }
  .xlarge-4 {
    width: 33.33333%;
  }
  .xlarge-5 {
    width: 41.66667%;
  }
  .xlarge-6 {
    width: 50%;
  }
  .xlarge-7 {
    width: 58.33333%;
  }
  .xlarge-8 {
    width: 66.66667%;
  }
  .xlarge-9 {
    width: 75%;
  }
  .xlarge-10 {
    width: 83.33333%;
  }
  .xlarge-11 {
    width: 91.66667%;
  }
  .xlarge-12 {
    width: 100%;
  }
  .xlarge-offset-0 {
    margin-left: 0 !important;
  }
  .xlarge-offset-1 {
    margin-left: 8.33333% !important;
  }
  .xlarge-offset-2 {
    margin-left: 16.66667% !important;
  }
  .xlarge-offset-3 {
    margin-left: 25% !important;
  }
  .xlarge-offset-4 {
    margin-left: 33.33333% !important;
  }
  .xlarge-offset-5 {
    margin-left: 41.66667% !important;
  }
  .xlarge-offset-6 {
    margin-left: 50% !important;
  }
  .xlarge-offset-7 {
    margin-left: 58.33333% !important;
  }
  .xlarge-offset-8 {
    margin-left: 66.66667% !important;
  }
  .xlarge-offset-9 {
    margin-left: 75% !important;
  }
  .xlarge-offset-10 {
    margin-left: 83.33333% !important;
  }
  .xlarge-offset-11 {
    margin-left: 91.66667% !important;
  }
  .xlarge-reset-order {
    float: left;
    left: auto;
    margin-left: 0;
    margin-right: 0;
    right: auto;
  }
  .column.xlarge-centered,
  .columns.xlarge-centered {
    margin-left: auto;
    margin-right: auto;
    float: none;
  }
  .column.xlarge-uncentered,
  .columns.xlarge-uncentered {
    float: left;
    margin-left: 0;
    margin-right: 0;
  }
  .column.xlarge-centered:last-child,
  .columns.xlarge-centered:last-child {
    float: none;
  }
  .column.xlarge-uncentered:last-child,
  .columns.xlarge-uncentered:last-child {
    float: left;
  }
  .column.xlarge-uncentered.opposite,
  .columns.xlarge-uncentered.opposite {
    float: right;
  }
  .row.xlarge-collapse > .column,
  .row.xlarge-collapse > .columns {
    padding-left: 0;
    padding-right: 0;
  }
  .row.xlarge-collapse .row {
    margin-left: 0;
    margin-right: 0;
  }
  .row.xlarge-uncollapse > .column,
  .row.xlarge-uncollapse > .columns {
    padding-left: 0.9375rem;
    padding-right: 0.9375rem;
    float: left;
  }
}

@media only screen and (min-width: 120.0625em) {
  .xxlarge-push-0 {
    position: relative;
    left: 0;
    right: auto;
  }
  .xxlarge-pull-0 {
    position: relative;
    right: 0;
    left: auto;
  }
  .xxlarge-push-1 {
    position: relative;
    left: 8.33333%;
    right: auto;
  }
  .xxlarge-pull-1 {
    position: relative;
    right: 8.33333%;
    left: auto;
  }
  .xxlarge-push-2 {
    position: relative;
    left: 16.66667%;
    right: auto;
  }
  .xxlarge-pull-2 {
    position: relative;
    right: 16.66667%;
    left: auto;
  }
  .xxlarge-push-3 {
    position: relative;
    left: 25%;
    right: auto;
  }
  .xxlarge-pull-3 {
    position: relative;
    right: 25%;
    left: auto;
  }
  .xxlarge-push-4 {
    position: relative;
    left: 33.33333%;
    right: auto;
  }
  .xxlarge-pull-4 {
    position: relative;
    right: 33.33333%;
    left: auto;
  }
  .xxlarge-push-5 {
    position: relative;
    left: 41.66667%;
    right: auto;
  }
  .xxlarge-pull-5 {
    position: relative;
    right: 41.66667%;
    left: auto;
  }
  .xxlarge-push-6 {
    position: relative;
    left: 50%;
    right: auto;
  }
  .xxlarge-pull-6 {
    position: relative;
    right: 50%;
    left: auto;
  }
  .xxlarge-push-7 {
    position: relative;
    left: 58.33333%;
    right: auto;
  }
  .xxlarge-pull-7 {
    position: relative;
    right: 58.33333%;
    left: auto;
  }
  .xxlarge-push-8 {
    position: relative;
    left: 66.66667%;
    right: auto;
  }
  .xxlarge-pull-8 {
    position: relative;
    right: 66.66667%;
    left: auto;
  }
  .xxlarge-push-9 {
    position: relative;
    left: 75%;
    right: auto;
  }
  .xxlarge-pull-9 {
    position: relative;
    right: 75%;
    left: auto;
  }
  .xxlarge-push-10 {
    position: relative;
    left: 83.33333%;
    right: auto;
  }
  .xxlarge-pull-10 {
    position: relative;
    right: 83.33333%;
    left: auto;
  }
  .xxlarge-push-11 {
    position: relative;
    left: 91.66667%;
    right: auto;
  }
  .xxlarge-pull-11 {
    position: relative;
    right: 91.66667%;
    left: auto;
  }
  .column,
  .columns {
    position: relative;
    padding-left: 0.9375rem;
    padding-right: 0.9375rem;
    float: left;
  }
  .xxlarge-1 {
    width: 8.33333%;
  }
  .xxlarge-2 {
    width: 16.66667%;
  }
  .xxlarge-3 {
    width: 25%;
  }
  .xxlarge-4 {
    width: 33.33333%;
  }
  .xxlarge-5 {
    width: 41.66667%;
  }
  .xxlarge-6 {
    width: 50%;
  }
  .xxlarge-7 {
    width: 58.33333%;
  }
  .xxlarge-8 {
    width: 66.66667%;
  }
  .xxlarge-9 {
    width: 75%;
  }
  .xxlarge-10 {
    width: 83.33333%;
  }
  .xxlarge-11 {
    width: 91.66667%;
  }
  .xxlarge-12 {
    width: 100%;
  }
  .xxlarge-offset-0 {
    margin-left: 0 !important;
  }
  .xxlarge-offset-1 {
    margin-left: 8.33333% !important;
  }
  .xxlarge-offset-2 {
    margin-left: 16.66667% !important;
  }
  .xxlarge-offset-3 {
    margin-left: 25% !important;
  }
  .xxlarge-offset-4 {
    margin-left: 33.33333% !important;
  }
  .xxlarge-offset-5 {
    margin-left: 41.66667% !important;
  }
  .xxlarge-offset-6 {
    margin-left: 50% !important;
  }
  .xxlarge-offset-7 {
    margin-left: 58.33333% !important;
  }
  .xxlarge-offset-8 {
    margin-left: 66.66667% !important;
  }
  .xxlarge-offset-9 {
    margin-left: 75% !important;
  }
  .xxlarge-offset-10 {
    margin-left: 83.33333% !important;
  }
  .xxlarge-offset-11 {
    margin-left: 91.66667% !important;
  }
  .xxlarge-reset-order {
    float: left;
    left: auto;
    margin-left: 0;
    margin-right: 0;
    right: auto;
  }
  .column.xxlarge-centered,
  .columns.xxlarge-centered {
    margin-left: auto;
    margin-right: auto;
    float: none;
  }
  .column.xxlarge-uncentered,
  .columns.xxlarge-uncentered {
    float: left;
    margin-left: 0;
    margin-right: 0;
  }
  .column.xxlarge-centered:last-child,
  .columns.xxlarge-centered:last-child {
    float: none;
  }
  .column.xxlarge-uncentered:last-child,
  .columns.xxlarge-uncentered:last-child {
    float: left;
  }
  .column.xxlarge-uncentered.opposite,
  .columns.xxlarge-uncentered.opposite {
    float: right;
  }
  .row.xxlarge-collapse > .column,
  .row.xxlarge-collapse > .columns {
    padding-left: 0;
    padding-right: 0;
  }
  .row.xxlarge-collapse .row {
    margin-left: 0;
    margin-right: 0;
  }
  .row.xxlarge-uncollapse > .column,
  .row.xxlarge-uncollapse > .columns {
    padding-left: 0.9375rem;
    padding-right: 0.9375rem;
    float: left;
  }
}

@media only screen {
  .hide-for-large,
  .hide-for-large-only,
  .hide-for-large-up,
  .hide-for-medium,
  .hide-for-medium-only,
  .hide-for-medium-up,
  .hide-for-xlarge,
  .hide-for-xlarge-only,
  .hide-for-xlarge-up,
  .hide-for-xxlarge,
  .hide-for-xxlarge-only,
  .hide-for-xxlarge-up,
  .show-for-large-down,
  .show-for-medium-down,
  .show-for-small,
  .show-for-small-down,
  .show-for-small-only,
  .show-for-small-up,
  .show-for-xlarge-down,
  .show-for-xxlarge-down {
    display: inherit !important;
  }
  .hide-for-large-down,
  .hide-for-medium-down,
  .hide-for-small,
  .hide-for-small-down,
  .hide-for-small-only,
  .hide-for-small-up,
  .hide-for-xlarge-down,
  .hide-for-xxlarge-down,
  .show-for-large,
  .show-for-large-only,
  .show-for-large-up,
  .show-for-medium,
  .show-for-medium-only,
  .show-for-medium-up,
  .show-for-xlarge,
  .show-for-xlarge-only,
  .show-for-xlarge-up,
  .show-for-xxlarge,
  .show-for-xxlarge-only,
  .show-for-xxlarge-up {
    display: none !important;
  }
  .hidden-for-large,
  .hidden-for-large-only,
  .hidden-for-large-up,
  .hidden-for-medium,
  .hidden-for-medium-only,
  .hidden-for-medium-up,
  .hidden-for-xlarge,
  .hidden-for-xlarge-only,
  .hidden-for-xlarge-up,
  .hidden-for-xxlarge,
  .hidden-for-xxlarge-only,
  .hidden-for-xxlarge-up,
  .visible-for-large-down,
  .visible-for-medium-down,
  .visible-for-small,
  .visible-for-small-down,
  .visible-for-small-only,
  .visible-for-small-up,
  .visible-for-xlarge-down,
  .visible-for-xxlarge-down {
    position: static !important;
    height: auto;
    width: auto;
    overflow: visible;
    clip: auto;
  }
  .hidden-for-large-down,
  .hidden-for-medium-down,
  .hidden-for-small,
  .hidden-for-small-down,
  .hidden-for-small-only,
  .hidden-for-small-up,
  .hidden-for-xlarge-down,
  .hidden-for-xxlarge-down,
  .visible-for-large,
  .visible-for-large-only,
  .visible-for-large-up,
  .visible-for-medium,
  .visible-for-medium-only,
  .visible-for-medium-up,
  .visible-for-xlarge,
  .visible-for-xlarge-only,
  .visible-for-xlarge-up,
  .visible-for-xxlarge,
  .visible-for-xxlarge-only,
  .visible-for-xxlarge-up {
    clip: rect(1px, 1px, 1px, 1px);
    height: 1px;
    overflow: hidden;
    position: absolute !important;
    width: 1px;
  }
  table.hide-for-large,
  table.hide-for-large-only,
  table.hide-for-large-up,
  table.hide-for-medium,
  table.hide-for-medium-only,
  table.hide-for-medium-up,
  table.hide-for-xlarge,
  table.hide-for-xlarge-only,
  table.hide-for-xlarge-up,
  table.hide-for-xxlarge,
  table.hide-for-xxlarge-only,
  table.hide-for-xxlarge-up,
  table.show-for-large-down,
  table.show-for-medium-down,
  table.show-for-small,
  table.show-for-small-down,
  table.show-for-small-only,
  table.show-for-small-up,
  table.show-for-xlarge-down,
  table.show-for-xxlarge-down {
    display: table !important;
  }
  thead.hide-for-large,
  thead.hide-for-large-only,
  thead.hide-for-large-up,
  thead.hide-for-medium,
  thead.hide-for-medium-only,
  thead.hide-for-medium-up,
  thead.hide-for-xlarge,
  thead.hide-for-xlarge-only,
  thead.hide-for-xlarge-up,
  thead.hide-for-xxlarge,
  thead.hide-for-xxlarge-only,
  thead.hide-for-xxlarge-up,
  thead.show-for-large-down,
  thead.show-for-medium-down,
  thead.show-for-small,
  thead.show-for-small-down,
  thead.show-for-small-only,
  thead.show-for-small-up,
  thead.show-for-xlarge-down,
  thead.show-for-xxlarge-down {
    display: table-header-group !important;
  }
  tbody.hide-for-large,
  tbody.hide-for-large-only,
  tbody.hide-for-large-up,
  tbody.hide-for-medium,
  tbody.hide-for-medium-only,
  tbody.hide-for-medium-up,
  tbody.hide-for-xlarge,
  tbody.hide-for-xlarge-only,
  tbody.hide-for-xlarge-up,
  tbody.hide-for-xxlarge,
  tbody.hide-for-xxlarge-only,
  tbody.hide-for-xxlarge-up,
  tbody.show-for-large-down,
  tbody.show-for-medium-down,
  tbody.show-for-small,
  tbody.show-for-small-down,
  tbody.show-for-small-only,
  tbody.show-for-small-up,
  tbody.show-for-xlarge-down,
  tbody.show-for-xxlarge-down {
    display: table-row-group !important;
  }
  tr.hide-for-large,
  tr.hide-for-large-only,
  tr.hide-for-large-up,
  tr.hide-for-medium,
  tr.hide-for-medium-only,
  tr.hide-for-medium-up,
  tr.hide-for-xlarge,
  tr.hide-for-xlarge-only,
  tr.hide-for-xlarge-up,
  tr.hide-for-xxlarge,
  tr.hide-for-xxlarge-only,
  tr.hide-for-xxlarge-up,
  tr.show-for-large-down,
  tr.show-for-medium-down,
  tr.show-for-small,
  tr.show-for-small-down,
  tr.show-for-small-only,
  tr.show-for-small-up,
  tr.show-for-xlarge-down,
  tr.show-for-xxlarge-down {
    display: table-row;
  }
  td.hide-for-large,
  td.hide-for-large-only,
  td.hide-for-large-up,
  td.hide-for-medium,
  td.hide-for-medium-only,
  td.hide-for-medium-up,
  td.hide-for-xlarge,
  td.hide-for-xlarge-only,
  td.hide-for-xlarge-up,
  td.hide-for-xxlarge,
  td.hide-for-xxlarge-only,
  td.hide-for-xxlarge-up,
  td.show-for-large-down,
  td.show-for-medium-down,
  td.show-for-small,
  td.show-for-small-down,
  td.show-for-small-only,
  td.show-for-small-up,
  td.show-for-xlarge-down,
  td.show-for-xxlarge-down,
  th.hide-for-large,
  th.hide-for-large-only,
  th.hide-for-large-up,
  th.hide-for-medium,
  th.hide-for-medium-only,
  th.hide-for-medium-up,
  th.hide-for-xlarge,
  th.hide-for-xlarge-only,
  th.hide-for-xlarge-up,
  th.hide-for-xxlarge,
  th.hide-for-xxlarge-only,
  th.hide-for-xxlarge-up,
  th.show-for-large-down,
  th.show-for-medium-down,
  th.show-for-small,
  th.show-for-small-down,
  th.show-for-small-only,
  th.show-for-small-up,
  th.show-for-xlarge-down,
  th.show-for-xxlarge-down {
    display: table-cell !important;
  }
}

@media only screen and (min-width: 40.0625em) {
  .hide-for-large,
  .hide-for-large-only,
  .hide-for-large-up,
  .hide-for-small,
  .hide-for-small-down,
  .hide-for-small-only,
  .hide-for-xlarge,
  .hide-for-xlarge-only,
  .hide-for-xlarge-up,
  .hide-for-xxlarge,
  .hide-for-xxlarge-only,
  .hide-for-xxlarge-up,
  .show-for-large-down,
  .show-for-medium,
  .show-for-medium-down,
  .show-for-medium-only,
  .show-for-medium-up,
  .show-for-small-up,
  .show-for-xlarge-down,
  .show-for-xxlarge-down {
    display: inherit !important;
  }
  .hide-for-large-down,
  .hide-for-medium,
  .hide-for-medium-down,
  .hide-for-medium-only,
  .hide-for-medium-up,
  .hide-for-small-up,
  .hide-for-xlarge-down,
  .hide-for-xxlarge-down,
  .show-for-large,
  .show-for-large-only,
  .show-for-large-up,
  .show-for-small,
  .show-for-small-down,
  .show-for-small-only,
  .show-for-xlarge,
  .show-for-xlarge-only,
  .show-for-xlarge-up,
  .show-for-xxlarge,
  .show-for-xxlarge-only,
  .show-for-xxlarge-up {
    display: none !important;
  }
  .hidden-for-large,
  .hidden-for-large-only,
  .hidden-for-large-up,
  .hidden-for-small,
  .hidden-for-small-down,
  .hidden-for-small-only,
  .hidden-for-xlarge,
  .hidden-for-xlarge-only,
  .hidden-for-xlarge-up,
  .hidden-for-xxlarge,
  .hidden-for-xxlarge-only,
  .hidden-for-xxlarge-up,
  .visible-for-large-down,
  .visible-for-medium,
  .visible-for-medium-down,
  .visible-for-medium-only,
  .visible-for-medium-up,
  .visible-for-small-up,
  .visible-for-xlarge-down,
  .visible-for-xxlarge-down {
    position: static !important;
    height: auto;
    width: auto;
    overflow: visible;
    clip: auto;
  }
  .hidden-for-large-down,
  .hidden-for-medium,
  .hidden-for-medium-down,
  .hidden-for-medium-only,
  .hidden-for-medium-up,
  .hidden-for-small-up,
  .hidden-for-xlarge-down,
  .hidden-for-xxlarge-down,
  .visible-for-large,
  .visible-for-large-only,
  .visible-for-large-up,
  .visible-for-small,
  .visible-for-small-down,
  .visible-for-small-only,
  .visible-for-xlarge,
  .visible-for-xlarge-only,
  .visible-for-xlarge-up,
  .visible-for-xxlarge,
  .visible-for-xxlarge-only,
  .visible-for-xxlarge-up {
    clip: rect(1px, 1px, 1px, 1px);
    height: 1px;
    overflow: hidden;
    position: absolute !important;
    width: 1px;
  }
  table.hide-for-large,
  table.hide-for-large-only,
  table.hide-for-large-up,
  table.hide-for-small,
  table.hide-for-small-down,
  table.hide-for-small-only,
  table.hide-for-xlarge,
  table.hide-for-xlarge-only,
  table.hide-for-xlarge-up,
  table.hide-for-xxlarge,
  table.hide-for-xxlarge-only,
  table.hide-for-xxlarge-up,
  table.show-for-large-down,
  table.show-for-medium,
  table.show-for-medium-down,
  table.show-for-medium-only,
  table.show-for-medium-up,
  table.show-for-small-up,
  table.show-for-xlarge-down,
  table.show-for-xxlarge-down {
    display: table !important;
  }
  thead.hide-for-large,
  thead.hide-for-large-only,
  thead.hide-for-large-up,
  thead.hide-for-small,
  thead.hide-for-small-down,
  thead.hide-for-small-only,
  thead.hide-for-xlarge,
  thead.hide-for-xlarge-only,
  thead.hide-for-xlarge-up,
  thead.hide-for-xxlarge,
  thead.hide-for-xxlarge-only,
  thead.hide-for-xxlarge-up,
  thead.show-for-large-down,
  thead.show-for-medium,
  thead.show-for-medium-down,
  thead.show-for-medium-only,
  thead.show-for-medium-up,
  thead.show-for-small-up,
  thead.show-for-xlarge-down,
  thead.show-for-xxlarge-down {
    display: table-header-group !important;
  }
  tbody.hide-for-large,
  tbody.hide-for-large-only,
  tbody.hide-for-large-up,
  tbody.hide-for-small,
  tbody.hide-for-small-down,
  tbody.hide-for-small-only,
  tbody.hide-for-xlarge,
  tbody.hide-for-xlarge-only,
  tbody.hide-for-xlarge-up,
  tbody.hide-for-xxlarge,
  tbody.hide-for-xxlarge-only,
  tbody.hide-for-xxlarge-up,
  tbody.show-for-large-down,
  tbody.show-for-medium,
  tbody.show-for-medium-down,
  tbody.show-for-medium-only,
  tbody.show-for-medium-up,
  tbody.show-for-small-up,
  tbody.show-for-xlarge-down,
  tbody.show-for-xxlarge-down {
    display: table-row-group !important;
  }
  tr.hide-for-large,
  tr.hide-for-large-only,
  tr.hide-for-large-up,
  tr.hide-for-small,
  tr.hide-for-small-down,
  tr.hide-for-small-only,
  tr.hide-for-xlarge,
  tr.hide-for-xlarge-only,
  tr.hide-for-xlarge-up,
  tr.hide-for-xxlarge,
  tr.hide-for-xxlarge-only,
  tr.hide-for-xxlarge-up,
  tr.show-for-large-down,
  tr.show-for-medium,
  tr.show-for-medium-down,
  tr.show-for-medium-only,
  tr.show-for-medium-up,
  tr.show-for-small-up,
  tr.show-for-xlarge-down,
  tr.show-for-xxlarge-down {
    display: table-row;
  }
  td.hide-for-large,
  td.hide-for-large-only,
  td.hide-for-large-up,
  td.hide-for-small,
  td.hide-for-small-down,
  td.hide-for-small-only,
  td.hide-for-xlarge,
  td.hide-for-xlarge-only,
  td.hide-for-xlarge-up,
  td.hide-for-xxlarge,
  td.hide-for-xxlarge-only,
  td.hide-for-xxlarge-up,
  td.show-for-large-down,
  td.show-for-medium,
  td.show-for-medium-down,
  td.show-for-medium-only,
  td.show-for-medium-up,
  td.show-for-small-up,
  td.show-for-xlarge-down,
  td.show-for-xxlarge-down,
  th.hide-for-large,
  th.hide-for-large-only,
  th.hide-for-large-up,
  th.hide-for-small,
  th.hide-for-small-down,
  th.hide-for-small-only,
  th.hide-for-xlarge,
  th.hide-for-xlarge-only,
  th.hide-for-xlarge-up,
  th.hide-for-xxlarge,
  th.hide-for-xxlarge-only,
  th.hide-for-xxlarge-up,
  th.show-for-large-down,
  th.show-for-medium,
  th.show-for-medium-down,
  th.show-for-medium-only,
  th.show-for-medium-up,
  th.show-for-small-up,
  th.show-for-xlarge-down,
  th.show-for-xxlarge-down {
    display: table-cell !important;
  }
}

@media only screen and (min-width: 64.0625em) {
  .hide-for-medium,
  .hide-for-medium-down,
  .hide-for-medium-only,
  .hide-for-small,
  .hide-for-small-down,
  .hide-for-small-only,
  .hide-for-xlarge,
  .hide-for-xlarge-only,
  .hide-for-xlarge-up,
  .hide-for-xxlarge,
  .hide-for-xxlarge-only,
  .hide-for-xxlarge-up,
  .show-for-large,
  .show-for-large-down,
  .show-for-large-only,
  .show-for-large-up,
  .show-for-medium-up,
  .show-for-small-up,
  .show-for-xlarge-down,
  .show-for-xxlarge-down {
    display: inherit !important;
  }
  .hide-for-large,
  .hide-for-large-down,
  .hide-for-large-only,
  .hide-for-large-up,
  .hide-for-medium-up,
  .hide-for-small-up,
  .hide-for-xlarge-down,
  .hide-for-xxlarge-down,
  .show-for-medium,
  .show-for-medium-down,
  .show-for-medium-only,
  .show-for-small,
  .show-for-small-down,
  .show-for-small-only,
  .show-for-xlarge,
  .show-for-xlarge-only,
  .show-for-xlarge-up,
  .show-for-xxlarge,
  .show-for-xxlarge-only,
  .show-for-xxlarge-up {
    display: none !important;
  }
  .hidden-for-medium,
  .hidden-for-medium-down,
  .hidden-for-medium-only,
  .hidden-for-small,
  .hidden-for-small-down,
  .hidden-for-small-only,
  .hidden-for-xlarge,
  .hidden-for-xlarge-only,
  .hidden-for-xlarge-up,
  .hidden-for-xxlarge,
  .hidden-for-xxlarge-only,
  .hidden-for-xxlarge-up,
  .visible-for-large,
  .visible-for-large-down,
  .visible-for-large-only,
  .visible-for-large-up,
  .visible-for-medium-up,
  .visible-for-small-up,
  .visible-for-xlarge-down,
  .visible-for-xxlarge-down {
    position: static !important;
    height: auto;
    width: auto;
    overflow: visible;
    clip: auto;
  }
  .hidden-for-large,
  .hidden-for-large-down,
  .hidden-for-large-only,
  .hidden-for-large-up,
  .hidden-for-medium-up,
  .hidden-for-small-up,
  .hidden-for-xlarge-down,
  .hidden-for-xxlarge-down,
  .visible-for-medium,
  .visible-for-medium-down,
  .visible-for-medium-only,
  .visible-for-small,
  .visible-for-small-down,
  .visible-for-small-only,
  .visible-for-xlarge,
  .visible-for-xlarge-only,
  .visible-for-xlarge-up,
  .visible-for-xxlarge,
  .visible-for-xxlarge-only,
  .visible-for-xxlarge-up {
    clip: rect(1px, 1px, 1px, 1px);
    height: 1px;
    overflow: hidden;
    position: absolute !important;
    width: 1px;
  }
  table.hide-for-medium,
  table.hide-for-medium-down,
  table.hide-for-medium-only,
  table.hide-for-small,
  table.hide-for-small-down,
  table.hide-for-small-only,
  table.hide-for-xlarge,
  table.hide-for-xlarge-only,
  table.hide-for-xlarge-up,
  table.hide-for-xxlarge,
  table.hide-for-xxlarge-only,
  table.hide-for-xxlarge-up,
  table.show-for-large,
  table.show-for-large-down,
  table.show-for-large-only,
  table.show-for-large-up,
  table.show-for-medium-up,
  table.show-for-small-up,
  table.show-for-xlarge-down,
  table.show-for-xxlarge-down {
    display: table !important;
  }
  thead.hide-for-medium,
  thead.hide-for-medium-down,
  thead.hide-for-medium-only,
  thead.hide-for-small,
  thead.hide-for-small-down,
  thead.hide-for-small-only,
  thead.hide-for-xlarge,
  thead.hide-for-xlarge-only,
  thead.hide-for-xlarge-up,
  thead.hide-for-xxlarge,
  thead.hide-for-xxlarge-only,
  thead.hide-for-xxlarge-up,
  thead.show-for-large,
  thead.show-for-large-down,
  thead.show-for-large-only,
  thead.show-for-large-up,
  thead.show-for-medium-up,
  thead.show-for-small-up,
  thead.show-for-xlarge-down,
  thead.show-for-xxlarge-down {
    display: table-header-group !important;
  }
  tbody.hide-for-medium,
  tbody.hide-for-medium-down,
  tbody.hide-for-medium-only,
  tbody.hide-for-small,
  tbody.hide-for-small-down,
  tbody.hide-for-small-only,
  tbody.hide-for-xlarge,
  tbody.hide-for-xlarge-only,
  tbody.hide-for-xlarge-up,
  tbody.hide-for-xxlarge,
  tbody.hide-for-xxlarge-only,
  tbody.hide-for-xxlarge-up,
  tbody.show-for-large,
  tbody.show-for-large-down,
  tbody.show-for-large-only,
  tbody.show-for-large-up,
  tbody.show-for-medium-up,
  tbody.show-for-small-up,
  tbody.show-for-xlarge-down,
  tbody.show-for-xxlarge-down {
    display: table-row-group !important;
  }
  tr.hide-for-medium,
  tr.hide-for-medium-down,
  tr.hide-for-medium-only,
  tr.hide-for-small,
  tr.hide-for-small-down,
  tr.hide-for-small-only,
  tr.hide-for-xlarge,
  tr.hide-for-xlarge-only,
  tr.hide-for-xlarge-up,
  tr.hide-for-xxlarge,
  tr.hide-for-xxlarge-only,
  tr.hide-for-xxlarge-up,
  tr.show-for-large,
  tr.show-for-large-down,
  tr.show-for-large-only,
  tr.show-for-large-up,
  tr.show-for-medium-up,
  tr.show-for-small-up,
  tr.show-for-xlarge-down,
  tr.show-for-xxlarge-down {
    display: table-row;
  }
  td.hide-for-medium,
  td.hide-for-medium-down,
  td.hide-for-medium-only,
  td.hide-for-small,
  td.hide-for-small-down,
  td.hide-for-small-only,
  td.hide-for-xlarge,
  td.hide-for-xlarge-only,
  td.hide-for-xlarge-up,
  td.hide-for-xxlarge,
  td.hide-for-xxlarge-only,
  td.hide-for-xxlarge-up,
  td.show-for-large,
  td.show-for-large-down,
  td.show-for-large-only,
  td.show-for-large-up,
  td.show-for-medium-up,
  td.show-for-small-up,
  td.show-for-xlarge-down,
  td.show-for-xxlarge-down,
  th.hide-for-medium,
  th.hide-for-medium-down,
  th.hide-for-medium-only,
  th.hide-for-small,
  th.hide-for-small-down,
  th.hide-for-small-only,
  th.hide-for-xlarge,
  th.hide-for-xlarge-only,
  th.hide-for-xlarge-up,
  th.hide-for-xxlarge,
  th.hide-for-xxlarge-only,
  th.hide-for-xxlarge-up,
  th.show-for-large,
  th.show-for-large-down,
  th.show-for-large-only,
  th.show-for-large-up,
  th.show-for-medium-up,
  th.show-for-small-up,
  th.show-for-xlarge-down,
  th.show-for-xxlarge-down {
    display: table-cell !important;
  }
}

@media only screen and (min-width: 90.0625em) {
  .hide-for-large,
  .hide-for-large-down,
  .hide-for-large-only,
  .hide-for-medium,
  .hide-for-medium-down,
  .hide-for-medium-only,
  .hide-for-small,
  .hide-for-small-down,
  .hide-for-small-only,
  .hide-for-xxlarge,
  .hide-for-xxlarge-only,
  .hide-for-xxlarge-up,
  .show-for-large-up,
  .show-for-medium-up,
  .show-for-small-up,
  .show-for-xlarge,
  .show-for-xlarge-down,
  .show-for-xlarge-only,
  .show-for-xlarge-up,
  .show-for-xxlarge-down {
    display: inherit !important;
  }
  .hide-for-large-up,
  .hide-for-medium-up,
  .hide-for-small-up,
  .hide-for-xlarge,
  .hide-for-xlarge-down,
  .hide-for-xlarge-only,
  .hide-for-xlarge-up,
  .hide-for-xxlarge-down,
  .show-for-large,
  .show-for-large-down,
  .show-for-large-only,
  .show-for-medium,
  .show-for-medium-down,
  .show-for-medium-only,
  .show-for-small,
  .show-for-small-down,
  .show-for-small-only,
  .show-for-xxlarge,
  .show-for-xxlarge-only,
  .show-for-xxlarge-up {
    display: none !important;
  }
  .hidden-for-large,
  .hidden-for-large-down,
  .hidden-for-large-only,
  .hidden-for-medium,
  .hidden-for-medium-down,
  .hidden-for-medium-only,
  .hidden-for-small,
  .hidden-for-small-down,
  .hidden-for-small-only,
  .hidden-for-xxlarge,
  .hidden-for-xxlarge-only,
  .hidden-for-xxlarge-up,
  .visible-for-large-up,
  .visible-for-medium-up,
  .visible-for-small-up,
  .visible-for-xlarge,
  .visible-for-xlarge-down,
  .visible-for-xlarge-only,
  .visible-for-xlarge-up,
  .visible-for-xxlarge-down {
    position: static !important;
    height: auto;
    width: auto;
    overflow: visible;
    clip: auto;
  }
  .hidden-for-large-up,
  .hidden-for-medium-up,
  .hidden-for-small-up,
  .hidden-for-xlarge,
  .hidden-for-xlarge-down,
  .hidden-for-xlarge-only,
  .hidden-for-xlarge-up,
  .hidden-for-xxlarge-down,
  .visible-for-large,
  .visible-for-large-down,
  .visible-for-large-only,
  .visible-for-medium,
  .visible-for-medium-down,
  .visible-for-medium-only,
  .visible-for-small,
  .visible-for-small-down,
  .visible-for-small-only,
  .visible-for-xxlarge,
  .visible-for-xxlarge-only,
  .visible-for-xxlarge-up {
    clip: rect(1px, 1px, 1px, 1px);
    height: 1px;
    overflow: hidden;
    position: absolute !important;
    width: 1px;
  }
  table.hide-for-large,
  table.hide-for-large-down,
  table.hide-for-large-only,
  table.hide-for-medium,
  table.hide-for-medium-down,
  table.hide-for-medium-only,
  table.hide-for-small,
  table.hide-for-small-down,
  table.hide-for-small-only,
  table.hide-for-xxlarge,
  table.hide-for-xxlarge-only,
  table.hide-for-xxlarge-up,
  table.show-for-large-up,
  table.show-for-medium-up,
  table.show-for-small-up,
  table.show-for-xlarge,
  table.show-for-xlarge-down,
  table.show-for-xlarge-only,
  table.show-for-xlarge-up,
  table.show-for-xxlarge-down {
    display: table !important;
  }
  thead.hide-for-large,
  thead.hide-for-large-down,
  thead.hide-for-large-only,
  thead.hide-for-medium,
  thead.hide-for-medium-down,
  thead.hide-for-medium-only,
  thead.hide-for-small,
  thead.hide-for-small-down,
  thead.hide-for-small-only,
  thead.hide-for-xxlarge,
  thead.hide-for-xxlarge-only,
  thead.hide-for-xxlarge-up,
  thead.show-for-large-up,
  thead.show-for-medium-up,
  thead.show-for-small-up,
  thead.show-for-xlarge,
  thead.show-for-xlarge-down,
  thead.show-for-xlarge-only,
  thead.show-for-xlarge-up,
  thead.show-for-xxlarge-down {
    display: table-header-group !important;
  }
  tbody.hide-for-large,
  tbody.hide-for-large-down,
  tbody.hide-for-large-only,
  tbody.hide-for-medium,
  tbody.hide-for-medium-down,
  tbody.hide-for-medium-only,
  tbody.hide-for-small,
  tbody.hide-for-small-down,
  tbody.hide-for-small-only,
  tbody.hide-for-xxlarge,
  tbody.hide-for-xxlarge-only,
  tbody.hide-for-xxlarge-up,
  tbody.show-for-large-up,
  tbody.show-for-medium-up,
  tbody.show-for-small-up,
  tbody.show-for-xlarge,
  tbody.show-for-xlarge-down,
  tbody.show-for-xlarge-only,
  tbody.show-for-xlarge-up,
  tbody.show-for-xxlarge-down {
    display: table-row-group !important;
  }
  tr.hide-for-large,
  tr.hide-for-large-down,
  tr.hide-for-large-only,
  tr.hide-for-medium,
  tr.hide-for-medium-down,
  tr.hide-for-medium-only,
  tr.hide-for-small,
  tr.hide-for-small-down,
  tr.hide-for-small-only,
  tr.hide-for-xxlarge,
  tr.hide-for-xxlarge-only,
  tr.hide-for-xxlarge-up,
  tr.show-for-large-up,
  tr.show-for-medium-up,
  tr.show-for-small-up,
  tr.show-for-xlarge,
  tr.show-for-xlarge-down,
  tr.show-for-xlarge-only,
  tr.show-for-xlarge-up,
  tr.show-for-xxlarge-down {
    display: table-row;
  }
  td.hide-for-large,
  td.hide-for-large-down,
  td.hide-for-large-only,
  td.hide-for-medium,
  td.hide-for-medium-down,
  td.hide-for-medium-only,
  td.hide-for-small,
  td.hide-for-small-down,
  td.hide-for-small-only,
  td.hide-for-xxlarge,
  td.hide-for-xxlarge-only,
  td.hide-for-xxlarge-up,
  td.show-for-large-up,
  td.show-for-medium-up,
  td.show-for-small-up,
  td.show-for-xlarge,
  td.show-for-xlarge-down,
  td.show-for-xlarge-only,
  td.show-for-xlarge-up,
  td.show-for-xxlarge-down,
  th.hide-for-large,
  th.hide-for-large-down,
  th.hide-for-large-only,
  th.hide-for-medium,
  th.hide-for-medium-down,
  th.hide-for-medium-only,
  th.hide-for-small,
  th.hide-for-small-down,
  th.hide-for-small-only,
  th.hide-for-xxlarge,
  th.hide-for-xxlarge-only,
  th.hide-for-xxlarge-up,
  th.show-for-large-up,
  th.show-for-medium-up,
  th.show-for-small-up,
  th.show-for-xlarge,
  th.show-for-xlarge-down,
  th.show-for-xlarge-only,
  th.show-for-xlarge-up,
  th.show-for-xxlarge-down {
    display: table-cell !important;
  }
}

@media only screen and (min-width: 120.0625em) {
  .hide-for-large,
  .hide-for-large-down,
  .hide-for-large-only,
  .hide-for-medium,
  .hide-for-medium-down,
  .hide-for-medium-only,
  .hide-for-small,
  .hide-for-small-down,
  .hide-for-small-only,
  .hide-for-xlarge,
  .hide-for-xlarge-down,
  .hide-for-xlarge-only,
  .show-for-large-up,
  .show-for-medium-up,
  .show-for-small-up,
  .show-for-xlarge-up,
  .show-for-xxlarge,
  .show-for-xxlarge-down,
  .show-for-xxlarge-only,
  .show-for-xxlarge-up {
    display: inherit !important;
  }
  .hide-for-large-up,
  .hide-for-medium-up,
  .hide-for-small-up,
  .hide-for-xlarge-up,
  .hide-for-xxlarge,
  .hide-for-xxlarge-down,
  .hide-for-xxlarge-only,
  .hide-for-xxlarge-up,
  .show-for-large,
  .show-for-large-down,
  .show-for-large-only,
  .show-for-medium,
  .show-for-medium-down,
  .show-for-medium-only,
  .show-for-small,
  .show-for-small-down,
  .show-for-small-only,
  .show-for-xlarge,
  .show-for-xlarge-down,
  .show-for-xlarge-only {
    display: none !important;
  }
  .hidden-for-large,
  .hidden-for-large-down,
  .hidden-for-large-only,
  .hidden-for-medium,
  .hidden-for-medium-down,
  .hidden-for-medium-only,
  .hidden-for-small,
  .hidden-for-small-down,
  .hidden-for-small-only,
  .hidden-for-xlarge,
  .hidden-for-xlarge-down,
  .hidden-for-xlarge-only,
  .visible-for-large-up,
  .visible-for-medium-up,
  .visible-for-small-up,
  .visible-for-xlarge-up,
  .visible-for-xxlarge,
  .visible-for-xxlarge-down,
  .visible-for-xxlarge-only,
  .visible-for-xxlarge-up {
    position: static !important;
    height: auto;
    width: auto;
    overflow: visible;
    clip: auto;
  }
  .hidden-for-large-up,
  .hidden-for-medium-up,
  .hidden-for-small-up,
  .hidden-for-xlarge-up,
  .hidden-for-xxlarge,
  .hidden-for-xxlarge-down,
  .hidden-for-xxlarge-only,
  .hidden-for-xxlarge-up,
  .visible-for-large,
  .visible-for-large-down,
  .visible-for-large-only,
  .visible-for-medium,
  .visible-for-medium-down,
  .visible-for-medium-only,
  .visible-for-small,
  .visible-for-small-down,
  .visible-for-small-only,
  .visible-for-xlarge,
  .visible-for-xlarge-down,
  .visible-for-xlarge-only {
    clip: rect(1px, 1px, 1px, 1px);
    height: 1px;
    overflow: hidden;
    position: absolute !important;
    width: 1px;
  }
  table.hide-for-large,
  table.hide-for-large-down,
  table.hide-for-large-only,
  table.hide-for-medium,
  table.hide-for-medium-down,
  table.hide-for-medium-only,
  table.hide-for-small,
  table.hide-for-small-down,
  table.hide-for-small-only,
  table.hide-for-xlarge,
  table.hide-for-xlarge-down,
  table.hide-for-xlarge-only,
  table.show-for-large-up,
  table.show-for-medium-up,
  table.show-for-small-up,
  table.show-for-xlarge-up,
  table.show-for-xxlarge,
  table.show-for-xxlarge-down,
  table.show-for-xxlarge-only,
  table.show-for-xxlarge-up {
    display: table !important;
  }
  thead.hide-for-large,
  thead.hide-for-large-down,
  thead.hide-for-large-only,
  thead.hide-for-medium,
  thead.hide-for-medium-down,
  thead.hide-for-medium-only,
  thead.hide-for-small,
  thead.hide-for-small-down,
  thead.hide-for-small-only,
  thead.hide-for-xlarge,
  thead.hide-for-xlarge-down,
  thead.hide-for-xlarge-only,
  thead.show-for-large-up,
  thead.show-for-medium-up,
  thead.show-for-small-up,
  thead.show-for-xlarge-up,
  thead.show-for-xxlarge,
  thead.show-for-xxlarge-down,
  thead.show-for-xxlarge-only,
  thead.show-for-xxlarge-up {
    display: table-header-group !important;
  }
  tbody.hide-for-large,
  tbody.hide-for-large-down,
  tbody.hide-for-large-only,
  tbody.hide-for-medium,
  tbody.hide-for-medium-down,
  tbody.hide-for-medium-only,
  tbody.hide-for-small,
  tbody.hide-for-small-down,
  tbody.hide-for-small-only,
  tbody.hide-for-xlarge,
  tbody.hide-for-xlarge-down,
  tbody.hide-for-xlarge-only,
  tbody.show-for-large-up,
  tbody.show-for-medium-up,
  tbody.show-for-small-up,
  tbody.show-for-xlarge-up,
  tbody.show-for-xxlarge,
  tbody.show-for-xxlarge-down,
  tbody.show-for-xxlarge-only,
  tbody.show-for-xxlarge-up {
    display: table-row-group !important;
  }
  tr.hide-for-large,
  tr.hide-for-large-down,
  tr.hide-for-large-only,
  tr.hide-for-medium,
  tr.hide-for-medium-down,
  tr.hide-for-medium-only,
  tr.hide-for-small,
  tr.hide-for-small-down,
  tr.hide-for-small-only,
  tr.hide-for-xlarge,
  tr.hide-for-xlarge-down,
  tr.hide-for-xlarge-only,
  tr.show-for-large-up,
  tr.show-for-medium-up,
  tr.show-for-small-up,
  tr.show-for-xlarge-up,
  tr.show-for-xxlarge,
  tr.show-for-xxlarge-down,
  tr.show-for-xxlarge-only,
  tr.show-for-xxlarge-up {
    display: table-row;
  }
  td.hide-for-large,
  td.hide-for-large-down,
  td.hide-for-large-only,
  td.hide-for-medium,
  td.hide-for-medium-down,
  td.hide-for-medium-only,
  td.hide-for-small,
  td.hide-for-small-down,
  td.hide-for-small-only,
  td.hide-for-xlarge,
  td.hide-for-xlarge-down,
  td.hide-for-xlarge-only,
  td.show-for-large-up,
  td.show-for-medium-up,
  td.show-for-small-up,
  td.show-for-xlarge-up,
  td.show-for-xxlarge,
  td.show-for-xxlarge-down,
  td.show-for-xxlarge-only,
  td.show-for-xxlarge-up,
  th.hide-for-large,
  th.hide-for-large-down,
  th.hide-for-large-only,
  th.hide-for-medium,
  th.hide-for-medium-down,
  th.hide-for-medium-only,
  th.hide-for-small,
  th.hide-for-small-down,
  th.hide-for-small-only,
  th.hide-for-xlarge,
  th.hide-for-xlarge-down,
  th.hide-for-xlarge-only,
  th.show-for-large-up,
  th.show-for-medium-up,
  th.show-for-small-up,
  th.show-for-xlarge-up,
  th.show-for-xxlarge,
  th.show-for-xxlarge-down,
  th.show-for-xxlarge-only,
  th.show-for-xxlarge-up {
    display: table-cell !important;
  }
}

.hide-for-portrait,
.show-for-landscape {
  display: inherit !important;
}

.hide-for-landscape,
.show-for-portrait {
  display: none !important;
}

table.hide-for-landscape,
table.show-for-portrait {
  display: table !important;
}

thead.hide-for-landscape,
thead.show-for-portrait {
  display: table-header-group !important;
}

tbody.hide-for-landscape,
tbody.show-for-portrait {
  display: table-row-group !important;
}

tr.hide-for-landscape,
tr.show-for-portrait {
  display: table-row !important;
}

td.hide-for-landscape,
td.show-for-portrait,
th.hide-for-landscape,
th.show-for-portrait {
  display: table-cell !important;
}

@media only screen and (orientation: landscape) {
  .hide-for-portrait,
  .show-for-landscape {
    display: inherit !important;
  }
  .hide-for-landscape,
  .show-for-portrait {
    display: none !important;
  }
  table.hide-for-portrait,
  table.show-for-landscape {
    display: table !important;
  }
  thead.hide-for-portrait,
  thead.show-for-landscape {
    display: table-header-group !important;
  }
  tbody.hide-for-portrait,
  tbody.show-for-landscape {
    display: table-row-group !important;
  }
  tr.hide-for-portrait,
  tr.show-for-landscape {
    display: table-row !important;
  }
  td.hide-for-portrait,
  td.show-for-landscape,
  th.hide-for-portrait,
  th.show-for-landscape {
    display: table-cell !important;
  }
}

@media only screen and (orientation: portrait) {
  .hide-for-landscape,
  .show-for-portrait {
    display: inherit !important;
  }
  .hide-for-portrait,
  .show-for-landscape {
    display: none !important;
  }
  table.hide-for-landscape,
  table.show-for-portrait {
    display: table !important;
  }
  thead.hide-for-landscape,
  thead.show-for-portrait {
    display: table-header-group !important;
  }
  tbody.hide-for-landscape,
  tbody.show-for-portrait {
    display: table-row-group !important;
  }
  tr.hide-for-landscape,
  tr.show-for-portrait {
    display: table-row !important;
  }
  td.hide-for-landscape,
  td.show-for-portrait,
  th.hide-for-landscape,
  th.show-for-portrait {
    display: table-cell !important;
  }
}

.show-for-touch {
  display: none !important;
}

.hide-for-touch,
.touch .show-for-touch {
  display: inherit !important;
}

.touch .hide-for-touch {
  display: none !important;
}

.touch table.show-for-touch,
table.hide-for-touch {
  display: table !important;
}

.touch thead.show-for-touch,
thead.hide-for-touch {
  display: table-header-group !important;
}

.touch tbody.show-for-touch,
tbody.hide-for-touch {
  display: table-row-group !important;
}

.touch tr.show-for-touch,
tr.hide-for-touch {
  display: table-row !important;
}

.touch td.show-for-touch,
.touch th.show-for-touch,
td.hide-for-touch,
th.hide-for-touch {
  display: table-cell !important;
}

.show-for-sr,
.show-on-focus {
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute !important;
  width: 1px;
}

.show-on-focus:active,
.show-on-focus:focus {
  position: static !important;
  height: auto;
  width: auto;
  overflow: visible;
  clip: auto;
}

.print-only,
.show-for-print {
  display: none !important;
}

@media print {
  .print-only,
  .show-for-print {
    display: block !important;
  }
  .hide-for-print,
  .hide-on-print {
    display: none !important;
  }
  table.show-for-print {
    display: table !important;
  }
  thead.show-for-print {
    display: table-header-group !important;
  }
  tbody.show-for-print {
    display: table-row-group !important;
  }
  tr.show-for-print {
    display: table-row !important;
  }
  td.show-for-print,
  th.show-for-print {
    display: table-cell !important;
  }
}

a,
div,
footer,
h1,
h2,
h3,
h4,
h5,
h6,
header,
img,
li,
ol,
p,
section,
span,
ul {
  margin: 0;
  padding: 0;
}

ul {
  padding: 0 0 0 20px;
}

button {
  margin: 5px 0;
}

.affix {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;
}

.clear:after {
  display: table;
  clear: both;
  content: "";
}

.layout-2-column {
  padding: 50px 0;
}

.layout-2-column .column-wrapper {
  max-width: 1200px;
  margin: auto;
  overflow: hidden;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.layout-3-column {
  padding: 50px 0;
}

.layout-3-column .column-wrapper {
  max-width: 1200px;
  margin: auto;
  overflow: hidden;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.layout-4-column {
  padding: 50px 0;
}

.layout-4-column .column-wrapper {
  max-width: 1200px;
  margin: auto;
  overflow: hidden;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.layout-5-column {
  padding: 50px 0;
}

.layout-5-column .column-wrapper {
  max-width: 1200px;
  margin: auto;
  overflow: hidden;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.layout-6-column {
  padding: 50px 0;
}

.layout-6-column .column-wrapper {
  max-width: 1200px;
  margin: auto;
  overflow: hidden;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.layout-basic-content {
  padding: 25px 0;
}

.layout-call-to-action {
  padding-top: 25px;
  padding-bottom: 25px;
}

.layout-call-to-action h1,
.layout-call-to-action h2,
.layout-call-to-action h3,
.layout-call-to-action h4,
.layout-call-to-action h5,
.layout-call-to-action h6,
.layout-call-to-action p {
  text-align: center;
}

.layout-call-to-action a {
  color: inherit;
  text-decoration: none;
  cursor: default;
  pointer-events: none;
}

@media screen and (min-width: 0px) and (max-width: 640px) {
  .layout-call-to-action a {
    display: block;
    width: 100%;
    text-decoration: underline;
    cursor: pointer;
    pointer-events: auto;
  }
}

.layout-call-to-action a span {
  text-decoration: inherit;
}

.layout-call-to-action a span.text-main {
  display: inline-block;
}

@media screen and (min-width: 0px) and (max-width: 640px) {
  .layout-call-to-action a span.text-main {
    display: none;
  }
  .layout-call-to-action a span.text-main.no-mobile-text {
    display: inline-block;
  }
}

.layout-call-to-action a span.text-mobile {
  display: inline-block;
}

@media screen and (min-width: 641px) {
  .layout-call-to-action a span.text-mobile {
    display: none;
  }
}

.layout-call-to-action.has-desktop-click a {
  text-decoration: underline;
  cursor: pointer;
  pointer-events: auto;
}

.layout-channel-lineup {
  padding: 60px 15px;
  background-color: #333;
  text-align: center;
}

.layout-channel-lineup * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.layout-channel-lineup h3 {
  color: #fff;
}

.layout-channel-lineup h4 {
  font-size: 1.125rem;
}

.layout-channel-lineup img.icon-close {
  width: 15px;
  height: 15px;
}

@media screen and (min-width: 1025px) {
  .layout-channel-lineup img.negative-icon,
  .layout-channel-lineup img.positive-icon {
    width: 50px;
    height: 50px;
    margin: 0;
  }
}

.layout-channel-lineup svg.icon-close {
  width: 15px;
  height: 15px;
}

.layout-channel-lineup svg.positive-icon {
  display: inline-block;
  width: 20px;
  height: 15px;
  margin-right: 15px;
  fill: #000;
}

@media screen and (min-width: 1025px) {
  .layout-channel-lineup svg.positive-icon {
    width: 25px;
    height: 20px;
    margin: 0;
  }
}

.layout-channel-lineup svg.negative-icon {
  display: inline-block;
  width: 20px;
  height: 15px;
  margin-right: 15px;
  fill: #000;
}

@media screen and (min-width: 1025px) {
  .layout-channel-lineup svg.negative-icon {
    width: 25px;
    height: 20px;
    margin: 0;
  }
}

.layout-channel-lineup .accordion {
  position: relative;
  margin-bottom: 20px;
  color: #999;
}

.layout-channel-lineup .accordion > .accordion-content {
  display: none;
  padding: 20px;
  background-color: #4d4d4d;
}

.layout-channel-lineup .accordion .categories {
  font-size: 0.875rem;
  width: 80%;
  margin-top: 15px;
  padding-right: 35px;
  padding-left: 35px;
  float: left;
  border-left: 1px solid;
  text-align: left;
}

@media screen and (min-width: 0px) and (max-width: 640px) {
  .layout-channel-lineup .accordion .categories {
    width: 100%;
    padding-top: 25px;
    padding-right: 0;
    padding-left: 0;
    float: none;
    border-top: 1px solid;
    border-left: 0;
  }
}

.layout-channel-lineup .accordion .categories h4 {
  font-size: 1rem;
  padding-bottom: 15px;
  font-weight: 400;
}

.layout-channel-lineup .accordion .categories span {
  font-size: 0.75rem;
  display: inline-block;
  margin-right: 5px;
  margin-bottom: 13px;
  padding: 10px 35px;
  border-radius: 20px;
  background-color: #333;
  color: #fff;
  text-align: center;
  cursor: pointer;
}

.layout-channel-lineup .accordion .categories span.active,
.layout-channel-lineup .accordion .categories span:hover {
  background-color: #00aeff;
}

@media screen and (min-width: 0px) and (max-width: 640px) {
  .layout-channel-lineup .accordion .categories .accordion-header {
    position: relative;
    cursor: pointer;
  }
}

@media screen and (min-width: 0px) and (max-width: 640px) {
  .layout-channel-lineup .accordion .categories .accordion-header:after {
    font-size: 1.5rem;
    position: absolute;
    top: -5px;
    right: 0;
    content: "\0002B";
  }
}

@media screen and (min-width: 0px) and (max-width: 640px) {
  .layout-channel-lineup .accordion .categories .accordion-header.active:after {
    content: "\02212";
  }
}

.layout-channel-lineup .accordion .filters {
  font-size: 0.875rem;
  width: 20%;
  margin-top: 15px;
  float: left;
  text-align: left;
}

@media screen and (min-width: 0px) and (max-width: 640px) {
  .layout-channel-lineup .accordion .filters {
    width: 100%;
    float: none;
  }
}

.layout-channel-lineup .accordion .filters h4 {
  font-size: 1rem;
  padding-bottom: 15px;
  font-weight: 400;
}

.layout-channel-lineup .accordion .filters span {
  display: block;
  position: relative;
  padding-bottom: 5px;
  padding-left: 25px;
  color: #fff;
  cursor: pointer;
}

.layout-channel-lineup .accordion .filters span:before {
  position: absolute;
  top: 2px;
  left: 0;
  width: 15px;
  height: 15px;
  border: 2px solid #999;
  border-radius: 50%;
  content: "";
}

.layout-channel-lineup .accordion .filters span.active:before,
.layout-channel-lineup .accordion .filters span:hover:before {
  border: 5px solid #00aeff;
  background-color: #fff;
}

@media screen and (min-width: 0px) and (max-width: 640px) {
  .layout-channel-lineup .accordion .filters .accordion-header {
    position: relative;
    cursor: pointer;
  }
}

@media screen and (min-width: 0px) and (max-width: 640px) {
  .layout-channel-lineup .accordion .filters .accordion-header.active:after {
    content: "\02212";
  }
}

@media screen and (min-width: 0px) and (max-width: 640px) {
  .layout-channel-lineup .accordion .filters .accordion-header:after {
    font-size: 1.5rem;
    position: absolute;
    top: -5px;
    right: 0;
    content: "\0002B";
  }
}

.layout-channel-lineup .accordion .search {
  font-size: 0.875rem;
  position: relative;
  padding: 5px;
  padding-left: 35px;
}

.layout-channel-lineup .accordion .search:before {
  display: block;
  position: absolute;
  top: 6px;
  left: 0;
  width: 24px;
  height: 24px;
  background-color: #fff;
  content: "";
}

.layout-channel-lineup .accordion .search input {
  width: 100%;
  border: 0;
  background-color: #4d4d4d;
  line-height: 24px;
}

.layout-channel-lineup .accordion .search input:focus {
  outline: 0;
}

.layout-channel-lineup .accordion .trigger-search {
  font-size: 0.75rem;
  position: absolute;
  top: -40px;
  right: 0;
  padding: 10px 25px 10px 10px;
  color: #fff;
  cursor: pointer;
}

.layout-channel-lineup .accordion .trigger-search:after {
  display: block;
  position: absolute;
  top: 10px;
  right: 0;
  width: 18px;
  height: 18px;
  background-color: #fff;
  content: "";
}

.layout-channel-lineup .accordion .trigger-search.spanish:before {
  content: "Buscar";
}

.layout-channel-lineup .accordion .trigger-search:before {
  content: "Search";
}

.layout-channel-lineup .accordion .trigger-search.active {
  color: #00aeff;
}

.layout-channel-lineup .accordion .trigger-search.active:after {
  background-color: transparent;
  content: "X";
}

.layout-channel-lineup .accordion .trigger-search.active.spanish:before {
  content: "Cancelar";
}

.layout-channel-lineup .accordion .trigger-search.active:before {
  content: "Cancel";
}

.layout-channel-lineup .accordion .trigger-search:hover {
  color: #00aeff;
}

.layout-channel-lineup .accordion .trigger-search:hover:after {
  background-color: #00aeff;
}

.layout-channel-lineup .accordion .trigger-sort {
  font-size: 0.75rem;
  position: absolute;
  top: -40px;
  right: 75px;
  padding: 10px 23px 10px 10px;
  color: #fff;
  cursor: pointer;
}

.layout-channel-lineup .accordion .trigger-sort:after {
  font-size: 0.625rem;
  display: block;
  position: absolute;
  top: 10px;
  right: 0;
  width: 18px;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  line-height: 18px;
  content: "\0276F";
}

.layout-channel-lineup .accordion .trigger-sort.active {
  color: #00aeff;
}

.layout-channel-lineup .accordion .trigger-sort.active:after {
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

.layout-channel-lineup .accordion .trigger-sort:hover {
  color: #00aeff;
}

.layout-channel-lineup .accordion .trigger-sort:hover:after {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.layout-channel-lineup .after,
.layout-channel-lineup .before {
  color: #fff;
}

.layout-channel-lineup .channel {
  width: 50%;
  min-height: 150px;
  padding: 10px 25px;
  float: left;
  border: 1px solid #333;
  background-color: #fff;
  text-align: center;
}

@media screen and (min-width: 641px) {
  .layout-channel-lineup .channel {
    width: 25%;
  }
}

@media screen and (min-width: 1025px) {
  .layout-channel-lineup .channel {
    width: 100%;
    min-height: auto;
    padding: 0;
    border: 0;
  }
}

.layout-channel-lineup .channel > h4 {
  position: relative;
  min-height: 100px;
  cursor: pointer;
}

@media screen and (min-width: 1025px) {
  .layout-channel-lineup .channel > h4 {
    display: none;
  }
}

.layout-channel-lineup .channel > h4 .channel-number {
  position: absolute;
  bottom: -25px;
  left: 50%;
  width: 30px;
  margin-left: -15px;
}

.layout-channel-lineup .channel:nth-of-type(-n + 2) {
  border-top: 0;
}

.layout-channel-lineup .channel:nth-last-of-type(-n + 2) {
  border-bottom: 0;
}

@media screen and (min-width: 641px) {
  .layout-channel-lineup .channel:nth-of-type(-n + 4) {
    border-top: 0;
  }
}

@media screen and (min-width: 641px) {
  .layout-channel-lineup .channel:nth-last-of-type(-n + 4) {
    border-bottom: 0;
  }
}

.layout-channel-lineup .channel:nth-of-type(odd) {
  border-left: 0;
}

@media screen and (min-width: 641px) {
  .layout-channel-lineup .channel:nth-of-type(odd) {
    border: 1px solid #333;
  }
}

@media screen and (min-width: 1025px) {
  .layout-channel-lineup .channel:nth-of-type(odd) {
    border: 0;
  }
}

.layout-channel-lineup .channel:nth-of-type(2n) {
  border-right: 0;
}

@media screen and (min-width: 641px) {
  .layout-channel-lineup .channel:nth-of-type(2n) {
    border: 1px solid #333;
  }
}

@media screen and (min-width: 1025px) {
  .layout-channel-lineup .channel:nth-of-type(2n) {
    border: 0;
  }
}

.layout-channel-lineup .channel-container {
  max-height: 525px;
  overflow-y: auto;
}

@media screen and (min-width: 1025px) {
  .layout-channel-lineup .channel-container {
    max-height: 475px;
    overflow-y: scroll;
  }
}

.layout-channel-lineup .channel-description {
  font-size: 0.875rem;
  display: table-cell;
  margin-bottom: 0;
  margin-left: 15px;
  padding: 0;
  background-color: #fff;
  color: #000;
  line-height: 1.25;
  text-align: left;
  vertical-align: top;
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .layout-channel-lineup .channel-description {
    border: 1px solid #333;
  }
}

.layout-channel-lineup .channel-description p {
  padding: 10px;
}

.layout-channel-lineup .channel-details {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  text-align: left;
  -webkit-box-shadow: 2px 2px 2px rgba(50, 50, 50, 0.25);
  box-shadow: 2px 2px 2px rgba(50, 50, 50, 0.25);
  z-index: 999;
}

@media screen and (min-width: 1025px) {
  .layout-channel-lineup .channel-details {
    display: table;
    position: static;
    background-color: #333;
    text-align: center;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
}

.layout-channel-lineup .channel-details div {
  padding: 15px;
  clear: both;
}

@media screen and (min-width: 1025px) {
  .layout-channel-lineup .channel-details div {
    padding: 0;
    clear: none;
  }
}

@media screen and (min-width: 1025px) {
  .layout-channel-lineup .channel-details h4 {
    font-size: 0.9375rem;
    margin-top: 0;
    line-height: 90px;
  }
}

.layout-channel-lineup .channel-header {
  width: 100%;
  margin-bottom: 15px;
  padding: 0;
  border-bottom: 2px solid #333;
  text-align: left;
}

@media screen and (min-width: 1025px) {
  .layout-channel-lineup .channel-header {
    display: table-cell;
    position: relative;
    margin-bottom: 0;
    border: 1px solid #333;
    background-color: #fff;
  }
}

@media screen and (min-width: 1025px) {
  .layout-channel-lineup .channel-header > h4 {
    line-height: 1;
  }
}

.layout-channel-lineup .channel-header .channel-logo {
  width: 70px;
  float: left;
}

@media screen and (min-width: 1025px) {
  .layout-channel-lineup .channel-header .channel-logo {
    margin: 10px 0;
    padding-left: 15px;
    float: none;
  }
}

.layout-channel-lineup .channel-header .channel-name {
  float: left;
  clear: left;
}

@media screen and (min-width: 1025px) {
  .layout-channel-lineup .channel-header .channel-name {
    padding-right: 5px;
    padding-left: 15px;
    float: none;
    clear: left;
  }
}

.layout-channel-lineup .channel-header .channel-number {
  padding-top: 11px;
  float: right;
}

@media screen and (min-width: 1025px) {
  .layout-channel-lineup .channel-header .channel-number {
    position: absolute;
    top: 5px;
    right: 0;
    width: 30px;
    margin-left: 0;
  }
}

.layout-channel-lineup .channel-logo {
  display: block;
  width: 45px;
  margin: 0 auto;
}

.layout-channel-lineup .channel-name {
  font-size: 0.8125rem;
  display: block;
  padding: 10px 5px;
  color: #999;
  line-height: 1;
}

.layout-channel-lineup .channel-number {
  font-size: 0.75rem;
  display: block;
  line-height: 1;
}

.layout-channel-lineup .channels {
  position: relative;
}

.layout-channel-lineup .filtered-container .mix {
  display: none;
}

.layout-channel-lineup .icon-close {
  position: absolute;
  top: 15px;
  right: 15px;
  color: #000;
  cursor: pointer;
}

@media screen and (min-width: 1025px) {
  .layout-channel-lineup .icon-close {
    display: none;
  }
}

.layout-channel-lineup .layout-legal {
  color: #fff;
}

@media screen and (min-width: 641px) {
  .layout-channel-lineup .lineup > div:first-child:nth-last-child(2),
  .layout-channel-lineup .lineup > div:first-child:nth-last-child(2) ~ div {
    width: 50%;
  }
}

@media screen and (min-width: 1025px) {
  .layout-channel-lineup .lineup > div:first-child:nth-last-child(3),
  .layout-channel-lineup .lineup > div:first-child:nth-last-child(3) ~ div {
    width: 33.33333%;
  }
}

@media screen and (min-width: 1025px) {
  .layout-channel-lineup .lineup > div:first-child:nth-last-child(4),
  .layout-channel-lineup .lineup > div:first-child:nth-last-child(4) ~ div {
    width: 25%;
  }
}

@media screen and (min-width: 1025px) {
  .layout-channel-lineup .lineup > div:first-child:nth-last-child(5),
  .layout-channel-lineup .lineup > div:first-child:nth-last-child(5) ~ div {
    width: 20%;
  }
}

@media screen and (min-width: 1025px) {
  .layout-channel-lineup .lineup > div:first-child:nth-last-child(6),
  .layout-channel-lineup .lineup > div:first-child:nth-last-child(6) ~ div {
    width: 16.66667%;
  }
}

@media screen and (min-width: 1025px) {
  .layout-channel-lineup .lineup > div:first-child:nth-last-child(7),
  .layout-channel-lineup .lineup > div:first-child:nth-last-child(7) ~ div {
    width: 14.28571%;
  }
}

@media screen and (min-width: 1025px) {
  .layout-channel-lineup .lineup > div:first-child:nth-last-child(8),
  .layout-channel-lineup .lineup > div:first-child:nth-last-child(8) ~ div {
    width: 12.5%;
  }
}

@media screen and (min-width: 1025px) {
  .layout-channel-lineup .listing-header {
    display: table-cell;
    padding: 50px 15px;
    border: 1px solid #333;
    background-color: #777;
    text-align: center;
    vertical-align: middle;
  }
}

@media screen and (min-width: 1025px) {
  .layout-channel-lineup .listing-header h3 {
    font-size: 1.0625rem;
  }
}

.layout-channel-lineup .mobile-intro {
  padding-bottom: 25px;
}

@media screen and (min-width: 1025px) {
  .layout-channel-lineup .mobile-intro {
    visibility: hidden;
  }
}

.layout-channel-lineup .negative-icon {
  font-size: 1.5625rem;
  display: inline-block;
  width: 25px;
  margin: 0 20px 0 10px;
  padding: 0;
  line-height: 1;
}

.layout-channel-lineup .no-results {
  padding: 20px;
  background-color: #fff;
}

.layout-channel-lineup .package-btn {
  display: none;
}

@media screen and (min-width: 1025px) {
  .layout-channel-lineup .package-btn {
    display: table-cell;
    margin-bottom: 0;
    margin-left: 0;
    padding: 0;
    border: 1px solid #333;
    background-color: #005b7f;
    text-align: center;
    vertical-align: middle;
  }
}

@media screen and (min-width: 1025px) {
  .layout-channel-lineup .package-btn:last-of-type {
    border-right: 0;
  }
}

@media screen and (min-width: 1025px) {
  .layout-channel-lineup .package-btn a {
    display: block;
    color: #fff;
    line-height: 50px;
  }
}

@media screen and (min-width: 1025px) {
  .layout-channel-lineup .package-btn h3 {
    padding: 10px;
  }
}

@media screen and (min-width: 1025px) {
  .layout-channel-lineup .package-btn h3:first-of-type {
    font-size: 1.0625rem;
    padding-top: 15px;
    border-bottom: 1px solid #333;
  }
}

@media screen and (min-width: 1025px) {
  .layout-channel-lineup .package-btn h3:first-of-type span {
    font-size: 0.8rem;
    display: block;
  }
}

@media screen and (min-width: 1025px) {
  .layout-channel-lineup .package-btn h3:nth-of-type(2) {
    font-size: 1.5625rem;
    padding-top: 5px;
    border-top: 1px solid #333;
    line-height: 1.75;
  }
}

@media screen and (min-width: 1025px) {
  .layout-channel-lineup .package-btn h3:nth-of-type(2) span {
    font-size: 0.78125rem;
    display: block;
  }
}

@media screen and (min-width: 1025px) {
  .layout-channel-lineup .package-btn span .package-name {
    display: none;
  }
}

@media screen and (min-width: 1025px) {
  .layout-channel-lineup .package-footers {
    display: table;
    width: 100%;
    border: 0;
  }
}

.layout-channel-lineup .package-headers {
  display: none;
}

@media screen and (min-width: 1025px) {
  .layout-channel-lineup .package-headers {
    display: table;
    width: 100%;
  }
}

.layout-channel-lineup .package-indicator {
  position: relative;
  margin-bottom: 10px;
  margin-left: 15px;
  padding-left: 35px;
  color: #000;
  vertical-align: middle;
}

@media screen and (min-width: 1025px) {
  .layout-channel-lineup .package-indicator {
    display: table-cell;
    margin-bottom: 0;
    margin-left: 0;
    padding: 0;
    border: 1px solid #333;
    background-color: #999;
    text-align: center;
    vertical-align: middle;
  }
}

@media screen and (min-width: 1025px) {
  .layout-channel-lineup .package-indicator:last-of-type {
    border-right: 0;
  }
}

@media screen and (min-width: 1025px) {
  .layout-channel-lineup .package-indicator span .package-name {
    display: none;
  }
}

.layout-channel-lineup .package-indicator:before {
  display: inline-block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 10px;
  height: 100%;
  background-color: #999;
  content: "";
}

@media screen and (min-width: 1025px) {
  .layout-channel-lineup .package-indicator:before {
    display: none;
  }
}

@media screen and (min-width: 1025px) {
  .layout-channel-lineup .package-info {
    display: table-cell;
    margin-bottom: 0;
    margin-left: 0;
    padding: 0;
    border: 1px solid #333;
    background-color: #005b7f;
    text-align: center;
    vertical-align: middle;
  }
}

@media screen and (min-width: 1025px) {
  .layout-channel-lineup .package-info:last-of-type {
    border-right: 0;
  }
}

@media screen and (min-width: 1025px) {
  .layout-channel-lineup .package-info h3 {
    padding: 10px;
  }
}

@media screen and (min-width: 1025px) {
  .layout-channel-lineup .package-info h3:first-of-type {
    font-size: 1.0625rem;
    padding-top: 15px;
    border-bottom: 1px solid #333;
  }
}

@media screen and (min-width: 1025px) {
  .layout-channel-lineup .package-info h3:first-of-type span {
    font-size: 0.85rem;
    display: block;
  }
}

@media screen and (min-width: 1025px) {
  .layout-channel-lineup .package-info h3:nth-of-type(2) {
    font-size: 1.5625rem;
    padding-top: 5px;
    border-top: 1px solid #333;
    line-height: 1.75;
  }
}

@media screen and (min-width: 1025px) {
  .layout-channel-lineup .package-info h3:nth-of-type(2) span {
    font-size: 0.78125rem;
    display: block;
  }
}

@media screen and (min-width: 1025px) {
  .layout-channel-lineup .package-info span .package-name {
    display: none;
  }
}

.layout-channel-lineup .package-markup {
  display: none;
}

@media screen and (min-width: 1025px) {
  .layout-channel-lineup .package-markup {
    display: table-cell;
  }
}

@media screen and (min-width: 1025px) {
  .layout-channel-lineup .package-placeholder {
    display: table-cell;
    margin-bottom: 0;
    margin-left: 0;
    padding: 0;
    border: 1px solid #333;
    background-color: #333;
    text-align: center;
    vertical-align: middle;
  }
}

@media screen and (min-width: 1025px) {
  .layout-channel-lineup .package-placeholder span .package-name {
    display: none;
  }
}

.layout-channel-lineup .positive-icon {
  font-size: 1.5625rem;
  display: inline-block;
  width: 25px;
  margin: 0 20px 0 10px;
  padding: 0;
  line-height: 1;
}

.layout-channel-lineup .single-package.channel > h4 {
  cursor: auto;
}

@media screen and (min-width: 641px) {
  .layout-channel-lineup .single-package .channel {
    width: 100%;
    min-height: auto;
    padding: 0;
    border: 0;
  }
}

@media screen and (min-width: 641px) {
  .layout-channel-lineup .single-package .channel > h4 {
    display: none;
  }
}

@media screen and (min-width: 641px) {
  .layout-channel-lineup .single-package .channel:nth-of-type(-n + 4) {
    border-top: 0;
  }
}

@media screen and (min-width: 641px) {
  .layout-channel-lineup .single-package .channel:nth-last-of-type(-n + 4) {
    border-bottom: 0;
  }
}

@media screen and (min-width: 641px) {
  .layout-channel-lineup .single-package .channel:nth-of-type(2n),
  .layout-channel-lineup .single-package .channel:nth-of-type(odd) {
    border: 0;
  }
}

@media screen and (min-width: 641px) {
  .layout-channel-lineup .single-package .channel-container {
    max-height: 475px;
    overflow-y: scroll;
  }
}

@media screen and (min-width: 641px) {
  .layout-channel-lineup .single-package .channel-description {
    border: 1px solid #333;
  }
}

@media screen and (min-width: 641px) {
  .layout-channel-lineup .single-package .channel-details {
    display: table;
    position: static;
    background-color: #fff;
    text-align: center;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
}

@media screen and (min-width: 641px) {
  .layout-channel-lineup .single-package .channel-details h4 {
    font-size: 0.9375rem;
    margin-top: 0;
    line-height: 90px;
  }
}

@media screen and (min-width: 641px) {
  .layout-channel-lineup .single-package .channel-details div {
    padding: 0;
    clear: none;
  }
}

@media screen and (min-width: 641px) {
  .layout-channel-lineup .single-package .channel-header {
    display: table-cell;
    position: relative;
    margin-bottom: 0;
    border: 1px solid #333;
    border-bottom: 0;
    background-color: #fff;
  }
}

@media screen and (min-width: 641px) {
  .layout-channel-lineup .single-package .channel-header > h4 {
    line-height: 1;
  }
}

@media screen and (min-width: 641px) {
  .layout-channel-lineup .single-package .channel-header .channel-logo {
    margin: 10px 0;
    padding-left: 15px;
    float: none;
  }
}

@media screen and (min-width: 641px) {
  .layout-channel-lineup .single-package .channel-header .channel-name {
    padding-right: 5px;
    padding-left: 15px;
    float: none;
    clear: left;
  }
}

@media screen and (min-width: 641px) {
  .layout-channel-lineup .single-package .channel-header .channel-number {
    position: absolute;
    top: 5px;
    right: 0;
    width: 30px;
    margin-left: 0;
  }
}

@media screen and (min-width: 641px) {
  .layout-channel-lineup .single-package .icon-close {
    display: none;
  }
}

.layout-channel-lineup
  .single-package
  .lineup
  > div:first-child:nth-last-child(2),
.layout-channel-lineup
  .single-package
  .lineup
  > div:first-child:nth-last-child(2)
  ~ div {
  width: 100%;
}

@media screen and (min-width: 641px) {
  .layout-channel-lineup
    .single-package
    .lineup
    > div:first-child:nth-last-child(2),
  .layout-channel-lineup
    .single-package
    .lineup
    > div:first-child:nth-last-child(2)
    ~ div {
    width: 50%;
  }
}

@media screen and (min-width: 641px) {
  .layout-channel-lineup
    .single-package
    .lineup
    > div:first-child:nth-last-child(3),
  .layout-channel-lineup
    .single-package
    .lineup
    > div:first-child:nth-last-child(3)
    ~ div {
    width: 33.3333%;
  }
}

@media screen and (min-width: 641px) {
  .layout-channel-lineup .single-package .listing-header {
    display: table-cell;
    padding: 50px 15px;
    border: 1px solid #333;
    background-color: #777;
    text-align: center;
    vertical-align: middle;
  }
}

@media screen and (min-width: 641px) {
  .layout-channel-lineup .single-package .listing-header h3 {
    font-size: 1.0625rem;
  }
}

.layout-channel-lineup .single-package .mobile-intro {
  display: none;
}

@media screen and (min-width: 641px) {
  .layout-channel-lineup .single-package .mobile-intro {
    visibility: hidden;
  }
}

@media screen and (min-width: 641px) {
  .layout-channel-lineup .single-package .package-btn {
    display: table-cell;
    margin-bottom: 0;
    margin-left: 0;
    padding: 0;
    border: 1px solid #333;
    background-color: #005b7f;
    text-align: center;
    vertical-align: middle;
  }
}

@media screen and (min-width: 641px) {
  .layout-channel-lineup .single-package .package-btn:last-of-type {
    border-right: 0;
  }
}

@media screen and (min-width: 641px) {
  .layout-channel-lineup .single-package .package-btn h3 {
    padding: 10px;
  }
}

@media screen and (min-width: 641px) {
  .layout-channel-lineup .single-package .package-btn h3:first-of-type {
    font-size: 1.0625rem;
    padding-top: 15px;
    border-bottom: 1px solid #333;
  }
}

@media screen and (min-width: 641px) {
  .layout-channel-lineup .single-package .package-btn h3:first-of-type span {
    font-size: 0.85rem;
    display: block;
  }
}

@media screen and (min-width: 641px) {
  .layout-channel-lineup .single-package .package-btn h3:nth-of-type(2) {
    font-size: 1.5625rem;
    padding-top: 5px;
    border-top: 1px solid #333;
    line-height: 1.75;
  }
}

@media screen and (min-width: 641px) {
  .layout-channel-lineup .single-package .package-btn h3:nth-of-type(2) span {
    font-size: 0.78125rem;
    display: block;
  }
}

@media screen and (min-width: 641px) {
  .layout-channel-lineup .single-package .package-btn span.package-name {
    display: none;
  }
}

.layout-channel-lineup .single-package .package-channels {
  font-size: 1.5625rem;
  padding-top: 5px;
  border-top: 1px solid #333;
  line-height: 1.75;
}

.layout-channel-lineup .single-package .package-channels span {
  font-size: 0.78125rem;
  display: block;
}

.layout-channel-lineup .single-package .package-headers {
  display: table;
  width: 100%;
}

@media screen and (min-width: 641px) {
  .layout-channel-lineup .single-package .package-indicator {
    display: table-cell;
    margin-bottom: 0;
    margin-left: 0;
    padding: 0;
    border: 1px solid #333;
    background-color: #999;
    text-align: center;
    vertical-align: middle;
  }
}

@media screen and (min-width: 641px) {
  .layout-channel-lineup .single-package .package-indicator:before {
    display: none;
  }
}

@media screen and (min-width: 641px) {
  .layout-channel-lineup .single-package .package-indicator:last-of-type {
    border-right: 0;
  }
}

@media screen and (min-width: 641px) {
  .layout-channel-lineup .single-package .package-indicator span.package-name {
    display: none;
  }
}

.layout-channel-lineup .single-package .package-info {
  background-color: #005b7f;
}

@media screen and (min-width: 641px) {
  .layout-channel-lineup .single-package .package-info {
    display: table-cell;
    margin-bottom: 0;
    margin-left: 0;
    padding: 0;
    border: 1px solid #333;
    background-color: #005b7f;
    text-align: center;
    vertical-align: middle;
  }
}

@media screen and (min-width: 641px) {
  .layout-channel-lineup .single-package .package-info:last-of-type {
    border-right: 0;
  }
}

.layout-channel-lineup .single-package .package-info h3 {
  padding: 10px;
}

@media screen and (min-width: 641px) {
  .layout-channel-lineup .single-package .package-info h3:first-of-type {
    font-size: 1.0625rem;
    padding-top: 15px;
    border-bottom: 1px solid #333;
  }
}

@media screen and (min-width: 641px) {
  .layout-channel-lineup .single-package .package-info h3:first-of-type span {
    font-size: 0.85rem;
    display: block;
  }
}

@media screen and (min-width: 641px) {
  .layout-channel-lineup .single-package .package-info h3:nth-of-type(2) {
    font-size: 1.5625rem;
    padding-top: 5px;
    border-top: 1px solid #333;
    line-height: 1.75;
  }
}

@media screen and (min-width: 641px) {
  .layout-channel-lineup .single-package .package-info h3:nth-of-type(2) span {
    font-size: 0.78125rem;
    display: block;
  }
}

@media screen and (min-width: 641px) {
  .layout-channel-lineup .single-package .package-info span.package-name {
    display: none;
  }
}

.layout-channel-lineup .single-package .package-placeholder {
  display: none;
}

@media screen and (min-width: 641px) {
  .layout-channel-lineup .single-package .package-placeholder {
    display: table-cell;
    margin-bottom: 0;
    margin-left: 0;
    padding: 0;
    border: 1px solid #333;
    background-color: #333;
    text-align: center;
    vertical-align: middle;
  }
}

@media screen and (min-width: 641px) {
  .layout-channel-lineup
    .single-package
    .package-placeholder
    span.package-name {
    display: none;
  }
}

.layout-channel-lineup .single-package .package-name {
  font-size: 1.0625rem;
  padding-top: 15px;
  border-bottom: 1px solid #333;
}

.layout-channel-lineup .single-package .package-name span {
  font-size: 0.85rem;
  display: block;
}

.layout-desktop-comparison-table {
  padding: 50px 0;
  background-color: #f2f2f2;
}

.layout-desktop-comparison-table .introduction {
  text-align: center;
}

.layout-desktop-comparison-table .introduction .intro-disclaimer p {
  font-size: 0.625rem;
}

.layout-desktop-comparison-table .table-container {
  padding-top: 25px;
}

.layout-desktop-comparison-table .table-container table {
  border: 0;
}

.layout-desktop-comparison-table .table-container thead {
  background-color: #f2f2f2;
}

.layout-desktop-comparison-table .table-container th {
  padding: 15px 0;
  color: #fff;
  text-align: center;
}

.layout-desktop-comparison-table .table-container th:nth-child(n + 2) {
  background-color: #b3b3b3;
}

.layout-desktop-comparison-table .table-container th:nth-child(-n + 3) {
  border-right: 2px solid #f2f2f2;
}

.layout-desktop-comparison-table .table-container th.main-header {
  background-color: #b3b3b3;
}

.layout-desktop-comparison-table .table-container td h5 {
  padding-bottom: 5px;
  font-weight: 700;
}

.layout-desktop-comparison-table .table-container td ul {
  font-size: 0.75rem;
  padding: 0;
  padding-left: 15px;
}

.layout-desktop-comparison-table .table-container td.unchecked div {
  display: none;
}

.layout-desktop-comparison-table .table-container td:first-child {
  padding: 25px;
}

.layout-desktop-comparison-table .table-container td:nth-child(-n + 3) {
  border-right: 2px solid #f2f2f2;
}

.layout-desktop-comparison-table .table-container td:nth-child(n + 2) {
  font-size: 1.5rem;
  padding: 0;
  text-align: center;
}

.layout-desktop-comparison-table .table-container td:nth-child(n + 2) a {
  display: block;
  padding: 15px 0;
  border-bottom: 2px solid #0092c8;
  background-color: #00aeef;
  color: #fff;
  font-weight: 700;
  text-align: center;
}

.layout-desktop-comparison-table .table-container a.hidden {
  visibility: hidden;
}

.layout-desktop-comparison-table .table-container tr {
  border-bottom: 2px solid #f2f2f2;
  background-color: #fff;
}

.layout-desktop-comparison-table .table-container tr.buttons {
  background-color: #f2f2f2;
}

.layout-desktop-comparison-table .table-container .layout-legal {
  text-align: center;
}

.layout-feature {
  padding: 25px 0;
}

.layout-feature img {
  display: block;
  margin: 0 auto;
}

.layout-feature .feature-content,
.layout-feature .image-legal,
.layout-feature .layout-legal {
  text-align: center;
}

.layout-flex-table {
  padding: 70px 0 50px;
}

@media screen and (max-width: 1024px) {
  .layout-flex-table {
    padding: 50px 30px;
  }
}

@media screen and (min-width: 0px) and (max-width: 640px) {
  .layout-flex-table {
    padding: 25px 0;
  }
}

@media screen and (max-width: 1024px) {
  .layout-flex-table table,
  .layout-flex-table tbody,
  .layout-flex-table td,
  .layout-flex-table th,
  .layout-flex-table thead,
  .layout-flex-table tr {
    display: block;
  }
}

.layout-flex-table table {
  width: 100%;
  border: 0;
  border-collapse: collapse;
}

@media screen and (max-width: 1024px) {
  .layout-flex-table thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }
}

.layout-flex-table td,
.layout-flex-table th {
  padding: 25px;
  text-align: center;
}

.layout-flex-table th {
  font-size: 1rem;
  background-color: #444;
  color: #fff;
}

.layout-flex-table td {
  font-size: 0.875rem;
  border-right: 1px solid #c3c0bf;
}

@media screen and (max-width: 1024px) {
  .layout-flex-table td {
    position: relative;
    padding: 25px 15px 25px 50%;
    border: 0;
    border-bottom: 1px solid #c3c0bf;
    background-color: #fff;
  }
}

.layout-flex-table td:first-of-type {
  font-weight: 700;
}

@media screen and (max-width: 1024px) {
  .layout-flex-table td:first-of-type {
    font-size: 1rem;
    padding-left: inherit;
    background-color: #444;
    color: #fff;
    text-align: center;
  }
}

.layout-flex-table td:last-of-type {
  border-right: 0;
}

@media screen and (max-width: 1024px) {
  .layout-flex-table td:nth-of-type(2n) {
    background-color: #f2f2f2;
  }
}

@media screen and (max-width: 1024px) {
  .layout-flex-table td:before {
    position: absolute;
    top: 50%;
    left: 15px;
    width: 45%;
    height: 20px;
    margin-top: -10px;
    padding-right: 10px;
    font-weight: 700;
    text-align: left;
  }
}

@media screen and (max-width: 1024px) {
  .layout-flex-table td:nth-of-type(2):before {
    content: "COL A";
  }
}

@media screen and (max-width: 1024px) {
  .layout-flex-table td:nth-of-type(3):before {
    content: "COL B";
  }
}

@media screen and (max-width: 1024px) {
  .layout-flex-table td:nth-of-type(4):before {
    content: "COL C";
  }
}

@media screen and (max-width: 1024px) {
  .layout-flex-table td:nth-of-type(5):before {
    content: "COL D";
  }
}

@media screen and (max-width: 1024px) {
  .layout-flex-table td:nth-of-type(6):before {
    content: "COL E";
  }
}

@media screen and (max-width: 1024px) {
  .layout-flex-table tr {
    margin-bottom: 25px;
  }
}

.layout-flex-table tr:nth-of-type(2n) {
  background-color: #f2f2f2;
}

.layout-form .control--checkbox,
.layout-form .control--radio {
  display: block;
  position: relative;
  padding-left: 20px;
  line-height: 20px;
  cursor: pointer;
}

.layout-form input[type="email"].open,
.layout-form input[type="number"].open,
.layout-form input[type="password"].open,
.layout-form input[type="tel"].open,
.layout-form input[type="text"].open,
.layout-form select.open ~ .select--styled,
.layout-form textarea.open {
  border-top-style: none;
  border-right-style: none;
  border-bottom-style: solid;
  border-left-style: none;
}

.layout-form {
  color: #666;
}

.layout-form * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.layout-form button {
  padding: 10px 25px;
  border: 0;
  background-color: #00aeef;
  color: #fff;
}

.layout-form button:hover {
  background-color: #0089bc;
}

.layout-form input[type="email"],
.layout-form input[type="number"],
.layout-form input[type="password"],
.layout-form input[type="tel"],
.layout-form input[type="text"] {
  font-size: 0.9375rem;
  height: 45px;
  padding: 5px 10px;
  border: 1px solid #ccc;
  line-height: 45px;
}

.layout-form input[type="email"][readonly],
.layout-form input[type="number"][readonly],
.layout-form input[type="password"][readonly],
.layout-form input[type="tel"][readonly],
.layout-form input[type="text"][readonly] {
  background-color: #ededed;
}

.layout-form input[type="email"]:focus,
.layout-form input[type="number"]:focus,
.layout-form input[type="password"]:focus,
.layout-form input[type="tel"]:focus,
.layout-form input[type="text"]:focus {
  border-color: #00aeef;
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.layout-form input[type="email"].completed,
.layout-form input[type="number"].completed,
.layout-form input[type="password"].completed,
.layout-form input[type="tel"].completed,
.layout-form input[type="text"].completed {
  border-color: #666;
  color: #666;
}

.layout-form input[type="email"].parsley-error,
.layout-form input[type="number"].parsley-error,
.layout-form input[type="password"].parsley-error,
.layout-form input[type="tel"].parsley-error,
.layout-form input[type="text"].parsley-error {
  border-color: #ed1c24;
  background-color: rgba(237, 28, 36, 0.15);
  color: #ed1c24;
}

.layout-form input[type="email"].parsley-error::-webkit-input-placeholder,
.layout-form input[type="number"].parsley-error::-webkit-input-placeholder,
.layout-form input[type="password"].parsley-error::-webkit-input-placeholder,
.layout-form input[type="tel"].parsley-error::-webkit-input-placeholder,
.layout-form input[type="text"].parsley-error::-webkit-input-placeholder {
  color: #ed1c24;
}

.layout-form input[type="email"].parsley-error:placeholder-shown,
.layout-form input[type="number"].parsley-error:placeholder-shown,
.layout-form input[type="password"].parsley-error:placeholder-shown,
.layout-form input[type="tel"].parsley-error:placeholder-shown,
.layout-form input[type="text"].parsley-error:placeholder-shown {
  color: #ed1c24;
}

.layout-form input[type="checkbox"],
.layout-form input[type="radio"] {
  position: absolute;
  opacity: 0;
  z-index: -1;
}

.layout-form label {
  font-size: 0.75rem;
  padding-bottom: 5px;
}

.layout-form label.required-field {
  position: relative;
  padding-left: 0.5em;
}

.layout-form label.required-field:before {
  font-size: 1.05rem;
  position: absolute;
  top: 0;
  left: 0;
  color: #00aeef;
  content: "*";
}

.layout-form select {
  width: auto;
}

.layout-form select.completed ~ .select--styled {
  border-color: #666;
  color: #666;
}

.layout-form select.parsley-error ~ .select--styled {
  border-color: #ed1c24;
  background-color: rgba(237, 28, 36, 0.15);
  color: #ed1c24;
}

.layout-form select.parsley-error ~ .select--styled::-webkit-input-placeholder {
  color: #ed1c24;
}

.layout-form select.parsley-error ~ .select--styled:placeholder-shown {
  color: #ed1c24;
}

.layout-form select.parsley-error.has-divider ~ .select--styled:after,
.layout-form select.parsley-error ~ .select--styled:after {
  width: 20px;
  height: 20px;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23ed1c24' viewBox='-3 -3 30 30'%3E%3Cpath d='M7.41 15.41L12 10.83l4.59 4.58L18 14l-6-6-6 6z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: 50% 50%;
  content: "";
  top: 7px;
  right: 0;
  width: 35px;
  height: 30px;
}

.layout-form select.parsley-error.has-divider ~ .select--styled:after {
  border-right: 1px solid #ed1c24;
}

.layout-form select.parsley-error.has-divider ~ .select--styled.active:after,
.layout-form select.parsley-error.has-divider ~ .select--styled:active:after {
  border-right: 0;
  border-left: 1px solid #ed1c24;
}

.layout-form select.has-divider ~ .select--styled:after {
  width: 20px;
  height: 20px;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%2300aeef' viewBox='-3 -3 30 30'%3E%3Cpath d='M7.41 15.41L12 10.83l4.59 4.58L18 14l-6-6-6 6z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: 50% 50%;
  content: "";
  top: 7px;
  right: 0;
  width: 35px;
  height: 30px;
  border-right: 1px solid #ccc;
}

.layout-form select.has-divider ~ .select--styled.active:after,
.layout-form select.has-divider ~ .select--styled:active:after {
  border-right: 0;
  border-left: 1px solid #ccc;
}

.layout-form textarea {
  position: relative;
  padding: 5px 10px;
  border: 1px solid #ccc;
  resize: none;
}

.layout-form textarea[readonly] {
  background-color: #ededed;
}

.layout-form textarea.completed {
  border-color: #666;
  color: #666;
}

.layout-form textarea:focus {
  border-color: #00aeef;
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.layout-form textarea.parsley-error {
  border-color: #ed1c24;
  background-color: rgba(237, 28, 36, 0.15);
  color: #ed1c24;
}

.layout-form textarea.parsley-error::-webkit-input-placeholder {
  color: #ed1c24;
}

.layout-form textarea.parsley-error:placeholder-shown {
  color: #ed1c24;
}

.layout-form .control--checkbox:hover input ~ .control__indicator,
.layout-form .control--checkbox input:focus ~ .control__indicator {
  border-color: #ccc;
}

.layout-form .control--checkbox .control__indicator:after {
  width: 20px;
  height: 20px;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 20 20'%3E%3Cpath d='M5.625 10.106L3.019 7.5l-.888.881 3.494 3.494 7.5-7.5-.881-.881z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: 50% 50%;
  content: "";
}

.layout-form .control--checkbox input:checked ~ .control__indicator {
  border: 0;
  background-color: #00aeef;
}

.layout-form .control--checkbox input:checked ~ .control__indicator:after {
  display: block;
}

.layout-form .control--radio .control__indicator {
  border-radius: 50%;
}

.layout-form .control--radio .control__indicator:after {
  top: 5px;
  left: 5px;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: #fff;
}

.layout-form .control--radio:hover input ~ .control__indicator,
.layout-form .control--radio input:focus ~ .control__indicator {
  border-color: #ccc;
}

.layout-form .control--radio input:checked ~ .control__indicator {
  border: 0;
  background-color: #00aeef;
}

.layout-form .control--radio input:checked ~ .control__indicator:after {
  display: block;
}

.layout-form .control__indicator {
  position: absolute;
  top: 2px;
  left: 0;
  width: 15px;
  height: 15px;
  border: 2px solid #e6e6e6;
}

.layout-form .control__indicator:after {
  display: none;
  position: absolute;
  content: "";
}

.layout-form .icon-wrapper {
  position: relative;
}

.layout-form .icon-wrapper input,
.layout-form .icon-wrapper textarea {
  padding-left: 55px;
}

.layout-form .icon-wrapper.envelope:before {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23ccc' viewBox='2 0 25 25'%3E%3Cpath d='M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 14H4V8l8 5 8-5v10zm-8-7L4 6h16l-8 5z'/%3E%3C/svg%3E");
  border-right: 1px solid #ccc;
}

.layout-form .icon-wrapper.envelope.active:before,
.layout-form .icon-wrapper.envelope:before {
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  content: "";
  height: 25px;
  width: 35px;
  position: absolute;
  top: 10px;
  left: 8px;
  z-index: 10;
}

.layout-form .icon-wrapper.envelope.active:before {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%2300aeef' viewBox='2 0 25 25'%3E%3Cpath d='M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 14H4V8l8 5 8-5v10zm-8-7L4 6h16l-8 5z'/%3E%3C/svg%3E");
  border-right: 1px solid #00aeef;
}

.layout-form .icon-wrapper.envelope.completed:before {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23666' viewBox='2 0 25 25'%3E%3Cpath d='M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 14H4V8l8 5 8-5v10zm-8-7L4 6h16l-8 5z'/%3E%3C/svg%3E");
  border-right: 1px solid #666;
}

.layout-form .icon-wrapper.envelope.completed:before,
.layout-form .icon-wrapper.envelope.has-errors:before {
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  content: "";
  height: 25px;
  width: 35px;
  position: absolute;
  top: 10px;
  left: 8px;
  z-index: 10;
}

.layout-form .icon-wrapper.envelope.has-errors:before {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23ed1c24' viewBox='2 0 25 25'%3E%3Cpath d='M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 14H4V8l8 5 8-5v10zm-8-7L4 6h16l-8 5z'/%3E%3C/svg%3E");
  border-right: 1px solid #ed1c24;
}

.layout-form .icon-wrapper.phone:before {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23ccc' viewBox='2 0 25 25'%3E%3Cpath d='M6.62 10.79c1.44 2.83 3.76 5.14 6.59 6.59l2.2-2.2c.27-.27.67-.36 1.02-.24 1.12.37 2.33.57 3.57.57.55 0 1 .45 1 1V20c0 .55-.45 1-1 1-9.39 0-17-7.61-17-17 0-.55.45-1 1-1h3.5c.55 0 1 .45 1 1 0 1.25.2 2.45.57 3.57.11.35.03.74-.25 1.02l-2.2 2.2z'/%3E%3C/svg%3E");
  border-right: 1px solid #ccc;
}

.layout-form .icon-wrapper.phone.active:before,
.layout-form .icon-wrapper.phone:before {
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  content: "";
  height: 25px;
  width: 35px;
  position: absolute;
  top: 10px;
  left: 8px;
  z-index: 10;
}

.layout-form .icon-wrapper.phone.active:before {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%2300aeef' viewBox='2 0 25 25'%3E%3Cpath d='M6.62 10.79c1.44 2.83 3.76 5.14 6.59 6.59l2.2-2.2c.27-.27.67-.36 1.02-.24 1.12.37 2.33.57 3.57.57.55 0 1 .45 1 1V20c0 .55-.45 1-1 1-9.39 0-17-7.61-17-17 0-.55.45-1 1-1h3.5c.55 0 1 .45 1 1 0 1.25.2 2.45.57 3.57.11.35.03.74-.25 1.02l-2.2 2.2z'/%3E%3C/svg%3E");
  border-right: 1px solid #00aeef;
}

.layout-form .icon-wrapper.phone.completed:before {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23666' viewBox='2 0 25 25'%3E%3Cpath d='M6.62 10.79c1.44 2.83 3.76 5.14 6.59 6.59l2.2-2.2c.27-.27.67-.36 1.02-.24 1.12.37 2.33.57 3.57.57.55 0 1 .45 1 1V20c0 .55-.45 1-1 1-9.39 0-17-7.61-17-17 0-.55.45-1 1-1h3.5c.55 0 1 .45 1 1 0 1.25.2 2.45.57 3.57.11.35.03.74-.25 1.02l-2.2 2.2z'/%3E%3C/svg%3E");
  border-right: 1px solid #666;
}

.layout-form .icon-wrapper.phone.completed:before,
.layout-form .icon-wrapper.phone.has-errors:before {
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  content: "";
  height: 25px;
  width: 35px;
  position: absolute;
  top: 10px;
  left: 8px;
  z-index: 10;
}

.layout-form .icon-wrapper.phone.has-errors:before {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23ed1c24' viewBox='2 0 25 25'%3E%3Cpath d='M6.62 10.79c1.44 2.83 3.76 5.14 6.59 6.59l2.2-2.2c.27-.27.67-.36 1.02-.24 1.12.37 2.33.57 3.57.57.55 0 1 .45 1 1V20c0 .55-.45 1-1 1-9.39 0-17-7.61-17-17 0-.55.45-1 1-1h3.5c.55 0 1 .45 1 1 0 1.25.2 2.45.57 3.57.11.35.03.74-.25 1.02l-2.2 2.2z'/%3E%3C/svg%3E");
  border-right: 1px solid #ed1c24;
}

.layout-form .icon-wrapper.pencil:before {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23ccc' viewBox='2 0 25 25'%3E%3Cpath d='M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04a.996.996 0 0 0 0-1.41l-2.34-2.34a.996.996 0 0 0-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z'/%3E%3C/svg%3E");
  border-right: 1px solid #ccc;
}

.layout-form .icon-wrapper.pencil.active:before,
.layout-form .icon-wrapper.pencil:before {
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  content: "";
  height: 25px;
  width: 35px;
  position: absolute;
  top: 10px;
  left: 8px;
  z-index: 10;
}

.layout-form .icon-wrapper.pencil.active:before {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%2300aeef' viewBox='2 0 25 25'%3E%3Cpath d='M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04a.996.996 0 0 0 0-1.41l-2.34-2.34a.996.996 0 0 0-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z'/%3E%3C/svg%3E");
  border-right: 1px solid #00aeef;
}

.layout-form .icon-wrapper.pencil.completed:before {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23666' viewBox='2 0 25 25'%3E%3Cpath d='M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04a.996.996 0 0 0 0-1.41l-2.34-2.34a.996.996 0 0 0-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z'/%3E%3C/svg%3E");
  border-right: 1px solid #666;
}

.layout-form .icon-wrapper.pencil.completed:before,
.layout-form .icon-wrapper.pencil.has-errors:before {
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  content: "";
  height: 25px;
  width: 35px;
  position: absolute;
  top: 10px;
  left: 8px;
  z-index: 10;
}

.layout-form .icon-wrapper.pencil.has-errors:before {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23ed1c24' viewBox='2 0 25 25'%3E%3Cpath d='M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04a.996.996 0 0 0 0-1.41l-2.34-2.34a.996.996 0 0 0-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z'/%3E%3C/svg%3E");
  border-right: 1px solid #ed1c24;
}

.layout-form .input-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  margin-bottom: 30px;
}

.layout-form .input-container .icon-wrapper {
  width: 100%;
}

.layout-form .input-container .input-container {
  margin-bottom: 0;
}

.layout-form .input-container.full-width {
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.layout-form .input-container.full-width .select,
.layout-form .input-container.full-width input[type="email"],
.layout-form .input-container.full-width input[type="number"],
.layout-form .input-container.full-width input[type="password"],
.layout-form .input-container.full-width input[type="tel"],
.layout-form .input-container.full-width input[type="text"],
.layout-form .input-container.full-width select,
.layout-form .input-container.full-width textarea {
  width: 100%;
}

.layout-form .input-container .input-group {
  position: relative;
}

.layout-form .input-container .input-group .input-container:not(:last-of-type) {
  margin-bottom: 30px;
}

@media screen and (min-width: 0px) and (max-width: 640px) {
  .layout-form .input-container.inline-group .input-group {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

.layout-form .input-container.inline-group .input-group .control--checkbox,
.layout-form .input-container.inline-group .input-group .control--radio {
  margin-right: 10px;
}

.layout-form .input-container.inline-group .input-group .input-container {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  margin-right: 10px;
  margin-bottom: 0;
}

@media screen and (min-width: 0px) and (max-width: 640px) {
  .layout-form .input-container.inline-group .input-group .input-container {
    margin-right: 0;
    margin-bottom: 10px;
  }
}

.layout-form
  .input-container.inline-group
  .input-group
  .input-container:last-of-type {
  margin-right: 0;
}

.layout-form .parsley-errors-list {
  font-size: 0.75rem;
  position: absolute;
  top: 100%;
  right: 0;
  margin: 0;
  padding: 0;
  color: #ed1c24;
  font-weight: 700;
  opacity: 0;
  list-style-type: none;
}

.layout-form .control--checkbox + .parsley-errors-list,
.layout-form .control--radio + .parsley-errors-list {
  left: 0;
  width: 200%;
}

.layout-form .parsley-error + .parsley-errors-list {
  opacity: 1;
}

.layout-form .left-align-errors .parsley-errors-list {
  right: auto;
  left: 0;
}

.layout-form .select {
  font-size: 0.9375rem;
  display: inline-block;
  position: relative;
  width: 220px;
  height: 45px;
  color: #999;
  line-height: 30px;
  cursor: pointer;
}

.layout-form .select--hidden {
  display: none;
  padding-right: 10px;
  visibility: hidden;
}

.layout-form .select--styled {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 8px 15px;
  border: 1px solid #ccc;
  background-color: #fff;
}

.layout-form .select--styled:after {
  width: 20px;
  height: 20px;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%2300aeef' viewBox='0 0 20 20'%3E%3Cpath d='M7.41 15.41L12 10.83l4.59 4.58L18 14l-6-6-6 6z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: 50% 50%;
  position: absolute;
  top: 15px;
  right: 10px;
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  content: "";
}

.layout-form .select--styled:hover {
  background-color: #fafafa;
}

.layout-form .select--styled.active,
.layout-form .select--styled:active {
  border-color: #00aeef;
  background-color: #f2f2f2;
}

.layout-form .select--styled.active:after,
.layout-form .select--styled:active:after {
  width: 20px;
  height: 20px;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%2300aeef' viewBox='0 0 20 20'%3E%3Cpath d='M7.41 15.41L12 10.83l4.59 4.58L18 14l-6-6-6 6z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: 50% 50%;
  content: "";
  top: 10px;
  right: 15px;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}

.layout-form .select__options {
  display: none;
  position: absolute;
  top: 100%;
  right: 0;
  left: 0;
  margin: 0;
  padding: 0;
  background-color: #fff;
  list-style: none;
  -webkit-box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.33);
  box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.33);
  z-index: 999;
}

.layout-form .select__options li {
  margin: 0;
  padding: 12px 0;
  border: 1px solid #e6e6e6;
  text-indent: 15px;
}

.layout-form .select__options li + li {
  border-top: 0;
}

.layout-form .select__options li:hover {
  background-color: #666;
  color: #fff;
}

.layout-form .select__options li[rel="hide"] {
  display: none;
}

.layout-geo-finder-bar {
  padding: 25px 0;
  overflow: hidden;
}

.layout-geo-finder-bar section form label {
  padding: 8px 5px 0 0;
  color: #fff;
  text-align: right;
}

.layout-geo-finder-bar section form select {
  margin: 0;
}

.layout-geo-finder-bar section .tfn {
  padding: 0;
  color: #fff;
}

.layout-geo-finder-bar #loctypelist {
  display: none;
}

.layout-hero {
  padding-top: 25px;
  padding-bottom: 25px;
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
}

.layout-hero .container {
  position: relative;
}

.layout-hero .promotion {
  background-color: #fff;
}

.layout-hero .legal {
  padding-top: 10px;
}

.layout-hero .legal p {
  font-size: 0.625rem;
  padding: 0;
  line-height: 1;
}

.layout-hero .callout {
  display: none;
  position: absolute;
  top: 50px;
  right: 25px;
  width: 250px;
  padding: 20px;
  background-color: #fff;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

@media screen and (min-width: 641px) {
  .layout-hero .callout {
    display: block;
  }
}

.layout-hero.reverse .content-wrapper {
  float: right;
}

.layout-hero.reverse .callout {
  left: 25px;
}

.layout-legal p {
  font-size: 0.625rem;
  line-height: 14px;
}

.layout-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  overflow: auto;
  z-index: 100;
}

.layout-modal.is-hidden {
  display: none;
}

.layout-modal .modal__content {
  position: relative;
  width: 80%;
  margin: 5% auto;
  padding: 20px;
  border: 1px solid #888;
  background-color: #fff;
}

.layout-modal .modal__close {
  font-size: 1.75rem;
  position: absolute;
  top: -10px;
  right: 5px;
  color: #888;
  font-weight: 700;
}

.layout-modal .modal__close:focus,
.layout-modal .modal__close:hover {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.layout-navigation * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.layout-navigation label span {
  pointer-events: none;
}

.layout-navigation li,
.layout-navigation ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.layout-navigation input[type="checkbox"] {
  display: none;
}

.layout-navigation .submenu {
  width: 100%;
}

.layout-navigation .menu {
  display: none;
  background-color: #bbb;
}

@media screen and (min-width: 1025px) {
  .layout-navigation .menu,
  .layout-navigation .menu.hover li:hover > input[type="checkbox"] + .submenu {
    display: block;
  }
  .layout-navigation .menu.hover label.icon-dropdown {
    display: none;
  }
  .layout-navigation .menu.hover span.icon-dropdown {
    display: block;
  }
}

.layout-navigation .menu.click label.icon-dropdown {
  display: block;
}

.layout-navigation .menu.click span.icon-dropdown {
  display: none;
}

@media screen and (min-width: 1025px) {
  .layout-navigation .menu.click input[type="checkbox"]:checked + .submenu {
    display: block;
  }
}

.layout-navigation .menu a {
  display: block;
  position: relative;
  width: 100%;
  padding: 1em 1.5em;
  color: #222;
}

.layout-navigation .menu span.icon-dropdown {
  display: none;
}

.layout-navigation .menu li {
  display: block;
  position: relative;
  -webkit-transition: background-color 0.25s ease-in-out;
  -o-transition: background-color 0.25s ease-in-out;
  transition: background-color 0.25s ease-in-out;
  color: #222;
}

@media screen and (min-width: 1025px) {
  .layout-navigation .menu li {
    float: left;
    border-width: 0;
    border-right-width: 1px;
  }
}

.layout-navigation .menu li:hover {
  background-color: #999;
}

.layout-navigation .menu li.dynamic-cta {
  display: none;
}

.layout-navigation .menu li .icon-dropdown {
  font-size: 1rem;
  position: absolute;
  top: 0;
  right: 0;
  padding: 1em 0.5em;
  background-color: rgba(0, 0, 0, 0.125);
  color: hsla(0, 0%, 100%, 0.75);
  text-align: center;
  text-shadow: 0 0 0 transparent;
}

.layout-navigation .menu li .icon-dropdown:before {
  content: "▾";
}

@media screen and (min-width: 1025px) {
  .layout-navigation .menu li.dropdown a {
    padding: 1em 2.5em 1em 0.5em;
  }
}

.layout-navigation .menu input[type="checkbox"]:checked + .submenu {
  display: block;
}

@media screen and (min-width: 1025px) {
  .layout-navigation .menu input[type="checkbox"]:checked + .submenu {
    display: none;
  }
}

.layout-navigation .menu .submenu {
  display: none;
  border-bottom-width: 0;
  border: 1px solid rgba(0, 0, 0, 0.05);
  background-color: #666;
}

@media screen and (min-width: 1025px) {
  .layout-navigation .menu .submenu {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    min-width: 10em;
    margin: 0;
    border-width: 0;
    z-index: 3000;
  }
}

.layout-navigation .menu .submenu a:hover {
  color: #444;
}

@media screen and (min-width: 1025px) {
  .layout-navigation .menu .submenu li {
    float: none;
    border-width: 0;
    border-bottom-width: 1px;
  }
}

.layout-navigation .menu .submenu span .icon-dropdown:before {
  content: "▸";
}

.layout-navigation .menu .submenu li:last-child {
  border-width: 0;
}

.layout-navigation .menu .submenu .submenu {
  background-color: #444;
}

@media screen and (min-width: 1025px) {
  .layout-navigation .menu .submenu .submenu {
    top: 0;
    left: 100%;
  }
}

.layout-navigation .menu .submenu .submenu a {
  color: #fff;
}

@media screen and (min-width: 641px) {
  .layout-navigation .menu.mobile-responsive-only {
    display: block;
  }
}

@media screen and (min-width: 641px) {
  .layout-navigation .menu.mobile-responsive-only li {
    float: left;
    border-width: 0;
    border-right-width: 1px;
  }
}

@media screen and (min-width: 641px) {
  .layout-navigation .menu.mobile-responsive-only li.dropdown a {
    padding: 1em 2.5em 1em 0.5em;
  }
}

@media screen and (min-width: 641px) {
  .layout-navigation
    .menu.mobile-responsive-only
    input[type="checkbox"]:checked
    + .submenu {
    display: none;
  }
}

@media screen and (min-width: 641px) {
  .layout-navigation .menu.mobile-responsive-only .submenu {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    min-width: 10em;
    margin: 0;
    border-width: 0;
    z-index: 3000;
  }
}

@media screen and (min-width: 641px) {
  .layout-navigation .menu.mobile-responsive-only .submenu li {
    float: none;
    border-width: 0;
    border-bottom-width: 1px;
  }
}

@media screen and (min-width: 641px) {
  .layout-navigation .menu.mobile-responsive-only .submenu .submenu {
    top: 0;
    left: 100%;
  }
}

@media screen and (min-width: 641px) {
  .layout-navigation
    .menu.mobile-responsive-only.hover
    li:hover
    > input[type="checkbox"]
    + .submenu {
    display: block;
  }
}

@media screen and (min-width: 641px) {
  .layout-navigation .menu.mobile-responsive-only.hover label.icon-dropdown {
    display: none;
  }
}

@media screen and (min-width: 641px) {
  .layout-navigation
    .menu.mobile-responsive-only.click
    input[type="checkbox"]:checked
    + .submenu,
  .layout-navigation .menu.mobile-responsive-only.hover span.icon-dropdown {
    display: block;
  }
}

@media screen and (min-width: 1025px) {
  .layout-navigation.align-right .menu li {
    float: right;
  }
}

.layout-navigation.align-right .menu .submenu li {
  padding-left: 35px;
  float: none;
}

.layout-navigation.align-right .menu .submenu .icon-dropdown {
  right: auto;
  left: 0;
}

.layout-navigation.align-right .menu .submenu .icon-dropdown:before {
  display: inline-block;
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  content: "▸";
}

@media screen and (min-width: 1025px) {
  .layout-navigation.align-right .menu .submenu .submenu {
    top: 0;
    right: 100%;
    left: auto;
  }
}

@media screen and (min-width: 641px) {
  .layout-navigation.align-right .menu.mobile-responsive-only li {
    float: right;
  }
}

@media screen and (min-width: 1025px) {
  .layout-navigation.align-right
    .menu.mobile-responsive-only
    .submenu
    .submenu {
    top: 0;
    right: 100%;
    left: auto;
  }
}

@media screen and (min-width: 1025px) {
  .layout-navigation.full-width .menu > li:first-child:nth-last-child(2),
  .layout-navigation.full-width .menu > li:first-child:nth-last-child(2) ~ li {
    width: 50%;
  }
  .layout-navigation.full-width .menu > li:first-child:nth-last-child(3),
  .layout-navigation.full-width .menu > li:first-child:nth-last-child(3) ~ li {
    width: 33.33333%;
  }
  .layout-navigation.full-width .menu > li:first-child:nth-last-child(4),
  .layout-navigation.full-width .menu > li:first-child:nth-last-child(4) ~ li {
    width: 25%;
  }
  .layout-navigation.full-width .menu > li:first-child:nth-last-child(5),
  .layout-navigation.full-width .menu > li:first-child:nth-last-child(5) ~ li {
    width: 20%;
  }
  .layout-navigation.full-width .menu > li:first-child:nth-last-child(6),
  .layout-navigation.full-width .menu > li:first-child:nth-last-child(6) ~ li {
    width: 16.66667%;
  }
  .layout-navigation.full-width .menu > li:first-child:nth-last-child(7),
  .layout-navigation.full-width .menu > li:first-child:nth-last-child(7) ~ li {
    width: 14.28571%;
  }
}

.layout-navigation .menu-toggle-checkbox:checked + .menu {
  display: block;
  margin-top: 18px;
}

.layout-navigation .menu-toggle {
  display: block;
  position: relative;
  padding: 1em 1.5em;
  border-bottom-width: 1px;
  border: 0 solid rgba(0, 0, 0, 0.05);
  background-color: #222;
  color: #ddd;
  z-index: 300;
}

@media screen and (min-width: 1025px) {
  .layout-navigation .menu-toggle {
    display: none;
  }
}

@media screen and (min-width: 641px) {
  .layout-navigation .menu-toggle.mobile-responsive-only {
    display: none;
  }
}

.layout-navigation .menu-toggle .icon-dropdown {
  font-size: 1rem;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  padding: 1em 0.5em;
  background-color: rgba(0, 0, 0, 0.125);
  color: hsla(0, 0%, 100%, 0.75);
  text-align: center;
  text-shadow: 0 0 0 transparent;
}

.layout-navigation .menu-toggle .icon-dropdown:before {
  content: "▾";
}

.layout-package-card-1 section {
  margin-bottom: 25px;
  background-color: #ccc;
}

.layout-package-card-1 section h4 {
  font-size: 0.875rem;
  padding: 10px 0;
  background-color: #434343;
  color: #fff;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
}

.layout-package-card-1 section p {
  color: #fff;
  text-align: center;
}

.layout-package-card-1 section p.tagline {
  font-size: 0.625rem;
  margin: 5px 0;
  padding: 5px 0;
  font-weight: 700;
}

.layout-package-card-1 section p.highlight {
  background-color: #aaa;
}

.layout-package-card-1 section p.channels {
  width: 90%;
  margin: 0 auto;
  padding: 15px 0;
  border-radius: 3px;
  background-color: #434343;
}

.layout-package-card-1 section p.price {
  font-size: 2rem;
  padding: 0;
  padding-top: 10px;
  font-weight: 700;
}

.layout-package-card-1 section p sup {
  font-size: 1.125rem;
  position: relative;
  top: -12px;
  vertical-align: baseline;
}

.layout-package-card-1 section p.disclaimer {
  font-size: 0.625rem;
  padding: 10px;
  padding-top: 0;
  font-weight: 700;
}

.layout-package-card-1 section .price span {
  font-size: 0.875rem;
  font-weight: 400;
}

.layout-package-card-2 .highlight {
  font-size: 0.875rem;
  border-bottom: 1px solid #fff;
  background-color: #ec008c;
  color: #fff;
  font-weight: 700;
  text-align: center;
  visibility: hidden;
}

.layout-package-card-2 .highlight.visible {
  visibility: visible;
}

.layout-package-card-2 section {
  margin-bottom: 25px;
  background-color: #fff;
}

.layout-package-card-2 section h4 {
  font-size: 1.5rem;
  padding: 25px 0;
  background-color: gray;
  color: #fff;
  text-align: center;
}

.layout-package-card-2 section p {
  text-align: center;
}

.layout-package-card-2 section p.price {
  font-size: 1.125rem;
  padding: 10px 0;
  border-bottom: 1px solid #e6e6e6;
}

.layout-package-card-2 section p.disclaimer {
  font-size: 0.625rem;
  margin: 0 auto;
  padding: 10px 7.5%;
  border-bottom: 1px solid #e6e6e6;
}

.layout-package-card-2 section p.promotion {
  padding: 10px 7.5%;
}

.layout-package-card-2 section ul {
  font-size: 0.75rem;
  min-height: 200px;
  padding: 10px 7.5% 0 12%;
  border-bottom: 1px solid #e6e6e6;
}

.layout-package-card-2 section ul li {
  padding: 0;
  padding-bottom: 10px;
}

.layout-package-card-2 section a {
  display: block;
  padding: 10px 0;
  background-color: #00aeef;
  color: #fff;
  text-align: center;
}

.layout-price {
  position: relative;
}

.layout-price:not(.is-inline) {
  font-size: 3.75rem;
}

.layout-price:not(.is-inline) > span {
  float: left;
  line-height: 1;
}

.layout-price:not(.is-inline) > span.price-disclaimer,
.layout-price:not(.is-inline) > span.price-prefix {
  display: block;
  float: none;
  clear: both;
}

.price-prefix {
  font-size: 1rem;
}

.price-currency {
  font-size: 0.4em;
}

.price-change-amount {
  font-size: 0.5em;
}

.price-change-amount:before {
  content: ".";
}

.price-disclaimer {
  font-size: 0.6rem;
}

.is-inline .price-disclaimer {
  font-size: 1rem;
}

.price-duration {
  font-size: 0.3em;
}

.is-inline .price-duration {
  font-size: 1rem;
}

.price-duration:before {
  content: "/";
}

.layout-promotion .container {
  height: 275px;
  margin-bottom: 80px;
}

@media screen and (min-width: 1025px) {
  .layout-promotion .container {
    width: 1000px;
    height: 150px;
    margin-bottom: 0;
  }
}

.layout-promotion .content {
  padding-top: 35px;
  padding-bottom: 25px;
  text-align: center;
}

@media screen and (min-width: 1025px) {
  .layout-promotion .content {
    padding-top: 15px;
    padding-bottom: 0;
    float: right;
    text-align: left;
  }
}

@media screen and (min-width: 1025px) {
  .layout-promotion .image {
    margin: 0 auto;
    margin-top: -25px;
    float: left;
  }
}

.layout-promotion .image img {
  display: block;
  margin: 0 auto;
}

.layout-mapi-reviews {
  margin-bottom: 100px;
}

@media screen and (max-width: 1024px) {
  .layout-mapi-reviews {
    padding: 15px;
  }
}

.layout-mapi-reviews .error h3 {
  font-size: 1.5rem;
  color: #ff2800;
}

.layout-mapi-reviews .error h3:before {
  width: 30px;
  height: 30px;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23ff2800' viewBox='0 0 30 30'%3E%3Cpath d='M13.75 18.75h2.5v2.5h-2.5v-2.5zm0-10h2.5v7.5h-2.5v-7.5zm1.238-6.25C8.088 2.5 2.5 8.1 2.5 15s5.588 12.5 12.488 12.5C21.9 27.5 27.5 21.9 27.5 15S21.9 2.5 14.988 2.5zM15 25C9.475 25 5 20.525 5 15S9.475 5 15 5s10 4.475 10 10-4.475 10-10 10z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: 50% 50%;
  content: "";
  display: block;
  margin-bottom: 10px;
  background-size: cover;
}

.layout-mapi-reviews .error p {
  font-size: 1.125rem;
  color: #757575;
}

.layout-mapi-reviews h2 {
  font-size: 2.25rem;
  padding-bottom: 20px;
  color: #2a2c2b;
  font-weight: 400;
}

.layout-mapi-reviews .button-more-reviews {
  font-size: 0.875rem;
  display: block;
  margin: 20px 0;
  padding: 10px 0;
  background-color: #00afec;
  color: #fff;
  text-align: center;
}

.layout-mapi-reviews .button-more-reviews.is-hidden {
  display: none;
}

.layout-mapi-reviews .button-more-reviews:hover {
  background-color: #0089b9;
  cursor: pointer;
}

.layout-mapi-reviews .all-reviews-displayed {
  text-align: center;
}

.layout-mapi-reviews .all-reviews-displayed.is-hidden {
  display: none;
}

.layout-mapi-reviews .star-icon {
  display: inline-block;
  position: relative;
  margin: 0;
  color: #f02e69;
}

.layout-mapi-reviews .star-icon:before {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23f02e69' viewBox='0 0 30 30'%3E%3Cpath d='M27.5 11.55l-8.988-.775L15 2.5l-3.512 8.287-8.988.763 6.825 5.912-2.05 8.788L15 21.587l7.725 4.663-2.038-8.788L27.5 11.55zM15 19.25l-4.7 2.837 1.25-5.35-4.15-3.6 5.475-.475L15 7.625l2.137 5.05 5.476.475-4.15 3.6 1.25 5.35L15 19.25z'/%3E%3C/svg%3E");
}

.layout-mapi-reviews .star-icon.full:before,
.layout-mapi-reviews .star-icon:before {
  width: 30px;
  height: 30px;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  content: "";
  display: block;
}

.layout-mapi-reviews .star-icon.full:before {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23f02e69' viewBox='0 0 30 30'%3E%3Cpath d='M15 21.587l7.725 4.663-2.05-8.788L27.5 11.55l-8.988-.763L15 2.5l-3.512 8.287-8.988.763 6.825 5.912-2.05 8.788z'/%3E%3C/svg%3E");
}

.layout-mapi-reviews .star-icon.half:before {
  width: 30px;
  height: 30px;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23f02e69' viewBox='0 0 30 30'%3E%3Cpath d='M27.5 12.175l-8.988-.775L15 3.125l-3.512 8.287-8.988.763 6.825 5.912-2.05 8.788L15 22.212l7.725 4.663-2.038-8.788 6.813-5.912zm-12.5 7.7V8.25l2.137 5.05 5.476.475-4.15 3.6 1.25 5.35L15 19.875z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: 50% 50%;
  display: block;
  content: "";
}

.layout-mapi-reviews .container-stars {
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #dbdbdb;
}

.layout-mapi-reviews .container-stars button {
  font-size: 0.875rem;
  margin-top: 30px;
  padding: 10px 20px;
  border: 0;
  outline: none;
  background-color: #00afec;
  color: #fff;
  text-align: center;
}

.layout-mapi-reviews .container-stars button:hover {
  background-color: #0089b9;
}

.layout-mapi-reviews .container-stars button.is-active {
  background-color: #dbdbdb;
}

.layout-mapi-reviews .container-stars h4 {
  font-size: 0.75rem;
  color: #757575;
  font-weight: 400;
}

.layout-mapi-reviews .container-stars .star-icon {
  font-size: 1.5625rem;
}

.layout-mapi-reviews .container-stars .container-options {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: baseline;
  -ms-flex-align: baseline;
  align-items: baseline;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

@media screen and (min-width: 0px) and (max-width: 640px) {
  .layout-mapi-reviews .container-stars .container-options {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .layout-mapi-reviews .container-stars .container-options button {
    width: 100%;
    margin-top: 15px;
    margin-bottom: 15px;
  }
}

.layout-mapi-reviews .container-stars .container-options .sort-options {
  font-size: 0.875rem;
  width: 230px;
  height: 30px;
  line-height: 30px;
}

@media screen and (min-width: 0px) and (max-width: 640px) {
  .layout-mapi-reviews .container-stars .container-options .sort-options {
    width: 100%;
  }
}

.layout-mapi-reviews .container-feedback h3 {
  font-size: 1.125rem;
  margin-bottom: 20px;
  color: #2a2c2b;
  font-weight: 400;
}

.layout-mapi-reviews .container-feedback p {
  font-size: 0.875rem;
  color: #757575;
  line-height: 20px;
}

.layout-mapi-reviews .container-feedback .content-feedback .hidden-copy {
  display: none;
}

.layout-mapi-reviews .container-feedback .content-feedback .button-see-more {
  display: block;
  margin-top: 15px;
  color: #00afec;
  cursor: pointer;
}

.layout-mapi-reviews .container-feedback .feedback-date {
  font-size: 0.75rem;
  display: block;
  margin-bottom: 20px;
  color: #757575;
}

.layout-mapi-reviews .container-feedback .star-icon:before {
  width: 20px;
  height: 20px;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23f02e69' viewBox='0 0 30 30'%3E%3Cpath d='M27.5 11.55l-8.988-.775L15 2.5l-3.512 8.287-8.988.763 6.825 5.912-2.05 8.788L15 21.587l7.725 4.663-2.038-8.788L27.5 11.55zM15 19.25l-4.7 2.837 1.25-5.35-4.15-3.6 5.475-.475L15 7.625l2.137 5.05 5.476.475-4.15 3.6 1.25 5.35L15 19.25z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: 50% 50%;
  content: "";
}

.layout-mapi-reviews .container-feedback .star-icon.full:before {
  width: 20px;
  height: 20px;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23f02e69' viewBox='0 0 30 30'%3E%3Cpath d='M15 21.587l7.725 4.663-2.05-8.788L27.5 11.55l-8.988-.763L15 2.5l-3.512 8.287-8.988.763 6.825 5.912-2.05 8.788z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: 50% 50%;
  content: "";
}

.layout-mapi-reviews .container-feedback .star-icon.half:before {
  width: 20px;
  height: 20px;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23f02e69' viewBox='0 0 30 30'%3E%3Cpath d='M27.5 12.175l-8.988-.775L15 3.125l-3.512 8.287-8.988.763 6.825 5.912-2.05 8.788L15 22.212l7.725 4.663-2.038-8.788 6.813-5.912zm-12.5 7.7V8.25l2.137 5.05 5.476.475-4.15 3.6 1.25 5.35L15 19.875z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: 50% 50%;
  content: "";
}

.layout-mapi-reviews .container-review-form {
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #dbdbdb;
  color: #757575;
}

.layout-mapi-reviews .container-review-form h3 {
  font-size: 1.375rem;
  padding-bottom: 15px;
  color: #2a2c2b;
  font-weight: 400;
}

.layout-mapi-reviews .container-review-form h3 + p {
  font-size: 0.875rem;
  padding-bottom: 35px;
}

.layout-mapi-reviews .container-review-form.is-hidden {
  display: none;
}

.layout-mapi-reviews .container-review-form .form-review {
  font-size: 0.875rem;
}

.layout-mapi-reviews .container-review-form .form-review div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

@media screen and (min-width: 0px) and (max-width: 640px) {
  .layout-mapi-reviews .container-review-form .form-review div {
    display: block;
  }
}

.layout-mapi-reviews .container-review-form .form-review div p:last-child {
  margin-left: 35px;
}

@media screen and (min-width: 0px) and (max-width: 640px) {
  .layout-mapi-reviews .container-review-form .form-review div p:last-child {
    margin-left: 0;
  }
}

.layout-mapi-reviews .container-review-form .form-review input[type="email"],
.layout-mapi-reviews .container-review-form .form-review input[type="text"] {
  display: block;
  width: 230px;
  margin-top: 5px;
  margin-bottom: 20px;
  padding: 10px;
  border: 1px solid #757575;
}

@media screen and (min-width: 0px) and (max-width: 640px) {
  .layout-mapi-reviews .container-review-form .form-review input[type="email"],
  .layout-mapi-reviews .container-review-form .form-review input[type="text"] {
    width: 100%;
  }
}

.layout-mapi-reviews .container-review-form .form-review input[type="email"] {
  width: 495px;
}

@media screen and (min-width: 0px) and (max-width: 640px) {
  .layout-mapi-reviews .container-review-form .form-review input[type="email"] {
    width: 100%;
  }
}

.layout-mapi-reviews .container-review-form .form-review textarea {
  font-size: 0.875rem;
  width: 100%;
  height: 120px;
  margin-bottom: 35px;
  padding: 10px;
}

.layout-mapi-reviews .container-review-form .container-buttons {
  margin-top: 35px;
}

.layout-mapi-reviews .container-review-form .container-buttons button {
  padding: 10px 20px;
  border: 0;
  outline: none;
  background-color: transparent;
  color: #fff;
  text-align: center;
}

.layout-mapi-reviews
  .container-review-form
  .container-buttons
  button.submit:hover {
  background-color: #0089b9;
}

.layout-mapi-reviews .container-review-form .container-buttons button.submit {
  width: 200px;
  background-color: #00afec;
}

@media screen and (min-width: 0px) and (max-width: 640px) {
  .layout-mapi-reviews .container-review-form .container-buttons button.submit {
    width: 100%;
  }
}

.layout-mapi-reviews
  .container-review-form
  .container-buttons
  button.reset:hover {
  background-color: #5c5c5c;
}

.layout-mapi-reviews .container-review-form .container-buttons button.reset {
  width: 120px;
  margin-left: 10px;
  background-color: #757575;
}

@media screen and (min-width: 0px) and (max-width: 640px) {
  .layout-mapi-reviews .container-review-form .container-buttons button.reset {
    width: 100%;
    margin-top: 15px;
    margin-left: 0;
  }
}

.layout-mapi-reviews .container-review-form .rating {
  display: inline-block;
}

.layout-mapi-reviews .container-review-form .rating span {
  font-size: 0.875rem;
  display: inline-block;
  padding-bottom: 20px;
  padding-left: 10px;
  float: right;
  color: #757575;
  line-height: 40px;
}

.layout-mapi-reviews .container-review-form .rating:not(:checked) > input {
  position: absolute;
  top: -9999px;
  clip: rect(0, 0, 0, 0);
}

.layout-mapi-reviews .container-review-form .rating:not(:checked) > label {
  font-size: 1.5625rem;
  width: 1.25em;
  padding: 0 0.1em;
  float: right;
  color: #757575;
  font-weight: 700;
  white-space: nowrap;
  cursor: pointer;
  overflow: hidden;
}

.layout-mapi-reviews
  .container-review-form
  .rating:not(:checked)
  > label:active {
  position: relative;
  top: 2px;
  left: 2px;
}

.layout-mapi-reviews
  .container-review-form
  .rating:not(:checked)
  > label:before {
  width: 30px;
  height: 30px;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23757575' viewBox='0 0 30 30'%3E%3Cpath d='M27.5 11.55l-8.988-.775L15 2.5l-3.512 8.287-8.988.763 6.825 5.912-2.05 8.788L15 21.587l7.725 4.663-2.038-8.788L27.5 11.55zM15 19.25l-4.7 2.837 1.25-5.35-4.15-3.6 5.475-.475L15 7.625l2.137 5.05 5.476.475-4.15 3.6 1.25 5.35L15 19.25z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: 50% 50%;
  display: inline-block;
  content: "";
}

.layout-mapi-reviews
  .container-review-form
  .rating:not(:checked)
  > label:hover:before,
.layout-mapi-reviews
  .container-review-form
  .rating:not(:checked)
  > label:hover
  ~ label:before {
  width: 30px;
  height: 30px;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23f02e69' viewBox='0 0 30 30'%3E%3Cpath d='M27.5 11.55l-8.988-.775L15 2.5l-3.512 8.287-8.988.763 6.825 5.912-2.05 8.788L15 21.587l7.725 4.663-2.038-8.788L27.5 11.55zM15 19.25l-4.7 2.837 1.25-5.35-4.15-3.6 5.475-.475L15 7.625l2.137 5.05 5.476.475-4.15 3.6 1.25 5.35L15 19.25z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: 50% 50%;
  content: "";
}

.layout-mapi-reviews
  .container-review-form
  .rating
  > input:checked
  + label:hover:before,
.layout-mapi-reviews
  .container-review-form
  .rating
  > input:checked
  + label:hover
  ~ label:before,
.layout-mapi-reviews
  .container-review-form
  .rating
  > input:checked
  ~ label:before,
.layout-mapi-reviews
  .container-review-form
  .rating
  > input:checked
  ~ label:hover:before,
.layout-mapi-reviews
  .container-review-form
  .rating
  > input:checked
  ~ label:hover
  ~ label:before,
.layout-mapi-reviews
  .container-review-form
  .rating
  > input:hover
  ~ input:checked
  ~ label:before {
  width: 30px;
  height: 30px;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23f02e69' viewBox='0 0 30 30'%3E%3Cpath d='M15 21.587l7.725 4.663-2.05-8.788L27.5 11.55l-8.988-.763L15 2.5l-3.512 8.287-8.988.763 6.825 5.912-2.05 8.788z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: 50% 50%;
  content: "";
}

.layout-mapi-reviews
  .container-review-form
  .rating
  > input:checked
  + label:before,
.layout-mapi-reviews
  .container-review-form
  .rating
  > input:checked
  + label
  ~ label:before,
.layout-mapi-reviews
  .container-review-form
  .rating
  > input:checked
  ~ label:before,
.layout-mapi-reviews
  .container-review-form
  .rating
  > input:checked
  ~ label
  ~ label:before,
.layout-mapi-reviews
  .container-review-form
  .rating
  > input:hover
  ~ input:checked
  ~ label:before {
  width: 30px;
  height: 30px;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23f02e69' viewBox='0 0 30 30'%3E%3Cpath d='M15 21.587l7.725 4.663-2.05-8.788L27.5 11.55l-8.988-.763L15 2.5l-3.512 8.287-8.988.763 6.825 5.912-2.05 8.788z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: 50% 50%;
  display: inline-block;
  content: "";
}

.layout-mapi-reviews .container-review {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #dbdbdb;
}

@media screen and (min-width: 0px) and (max-width: 640px) {
  .layout-mapi-reviews .container-review {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

.layout-mapi-reviews .container-review.is-hidden {
  display: none;
}

.layout-mapi-reviews .container-user {
  min-width: 150px;
  margin-right: 30px;
  text-align: center;
}

@media screen and (min-width: 0px) and (max-width: 640px) {
  .layout-mapi-reviews .container-user {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    text-align: left;
  }
}

.layout-mapi-reviews .container-user.is-hidden {
  display: none;
}

.layout-mapi-reviews .container-user .avatar {
  font-size: 1.875rem;
  display: inline-block;
  width: 90px;
  margin-bottom: 10px;
  border-radius: 50%;
  background-color: #dbdbdb;
  color: #2a2c2b;
  line-height: 90px;
  text-align: center;
}

@media screen and (min-width: 0px) and (max-width: 640px) {
  .layout-mapi-reviews .container-user .avatar {
    font-size: 1.25rem;
    width: 60px;
    margin-right: 20px;
    line-height: 60px;
  }
}

.layout-mapi-reviews .container-user .user-name {
  font-size: 0.75rem;
  display: block;
  color: #2a2c2b;
  font-weight: 700;
}

.layout-mapi-reviews .container-user .user-location {
  font-size: 0.75rem;
  display: block;
  color: #757575;
  text-transform: capitalize;
}

.layout-mapi-reviews .container-user .user-location.is-hidden {
  display: none;
}

.layout-mapi-reviews .loader {
  width: 70px;
  margin: 0 auto;
  text-align: center;
}

.layout-mapi-reviews .loader > div {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background-color: #fff;
  -webkit-animation: b 1.4s infinite ease-in-out both;
  animation: b 1.4s infinite ease-in-out both;
}

.layout-mapi-reviews .loader .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.layout-mapi-reviews .loader .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

.layout-reviews {
  margin-bottom: 100px;
}

@media screen and (max-width: 1024px) {
  .layout-reviews {
    padding: 15px;
  }
}

.layout-reviews h2 {
  font-size: 2.25rem;
  padding-bottom: 20px;
  color: #2a2c2b;
  font-weight: 400;
}

.layout-reviews .button-more-reviews {
  font-size: 0.875rem;
  display: block;
  margin: 20px 0;
  padding: 10px 0;
  background-color: #00afec;
  color: #fff;
  text-align: center;
}

.layout-reviews .button-more-reviews:hover {
  background-color: #0089b9;
  cursor: pointer;
}

.layout-reviews .star-icon {
  display: inline-block;
  position: relative;
  margin: 0 2px;
  color: #f02e69;
}

.layout-reviews .star-icon.full:before,
.layout-reviews .star-icon.half:before {
  position: absolute;
  left: 0;
  color: #f02e69;
  text-shadow: 0 0 2px rgba(0, 0, 0, 0.7);
  content: "\2605";
}

.layout-reviews .star-icon.half:before {
  width: 50%;
  overflow: hidden;
}

.layout-reviews .container-stars {
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #dbdbdb;
}

.layout-reviews .container-stars button {
  font-size: 0.875rem;
  margin-top: 30px;
  padding: 10px 20px;
  border: 0;
  outline: none;
  background-color: #00afec;
  color: #fff;
  text-align: center;
}

.layout-reviews .container-stars button:hover {
  background-color: #0089b9;
}

.layout-reviews .container-stars button.is-active {
  background-color: #dbdbdb;
}

.layout-reviews .container-stars h4 {
  font-size: 0.75rem;
  color: #757575;
  font-weight: 400;
}

.layout-reviews .container-stars .star-icon {
  font-size: 1.5625rem;
}

.layout-reviews .container-stars .container-options {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: baseline;
  -ms-flex-align: baseline;
  align-items: baseline;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

@media screen and (min-width: 0px) and (max-width: 640px) {
  .layout-reviews .container-stars .container-options {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .layout-reviews .container-stars .container-options button {
    width: 100%;
    margin-top: 15px;
    margin-bottom: 15px;
  }
}

.layout-reviews .container-stars .container-options .sort-options {
  font-size: 0.875rem;
  width: 230px;
  height: 30px;
  line-height: 30px;
}

@media screen and (min-width: 0px) and (max-width: 640px) {
  .layout-reviews .container-stars .container-options .sort-options {
    width: 100%;
  }
}

.layout-reviews .container-feedback h3 {
  font-size: 1.125rem;
  margin-bottom: 20px;
  color: #2a2c2b;
  font-weight: 400;
}

.layout-reviews .container-feedback p {
  font-size: 0.875rem;
  color: #757575;
  line-height: 20px;
}

.layout-reviews .container-feedback .content-feedback .hidden-copy {
  display: none;
}

.layout-reviews .container-feedback .content-feedback .button-see-more {
  display: block;
  margin-top: 15px;
  color: #00afec;
  cursor: pointer;
}

.layout-reviews .container-feedback .feedback-date {
  font-size: 0.75rem;
  display: block;
  margin-bottom: 20px;
  color: #757575;
}

.layout-reviews .container-feedback .star-icon {
  font-size: 0.9375rem;
}

.layout-reviews .container-review-form {
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #dbdbdb;
  color: #757575;
}

.layout-reviews .container-review-form h3 {
  font-size: 1.375rem;
  padding-bottom: 15px;
  color: #2a2c2b;
  font-weight: 400;
}

.layout-reviews .container-review-form h3 + p {
  font-size: 0.875rem;
  padding-bottom: 35px;
}

.layout-reviews .container-review-form.is-hidden {
  display: none;
}

.layout-reviews .container-review-form .form-review {
  font-size: 0.875rem;
}

.layout-reviews .container-review-form .form-review div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

@media screen and (min-width: 0px) and (max-width: 640px) {
  .layout-reviews .container-review-form .form-review div {
    display: block;
  }
}

.layout-reviews .container-review-form .form-review div p:last-child {
  margin-left: 35px;
}

@media screen and (min-width: 0px) and (max-width: 640px) {
  .layout-reviews .container-review-form .form-review div p:last-child {
    margin-left: 0;
  }
}

.layout-reviews .container-review-form .form-review input[type="email"],
.layout-reviews .container-review-form .form-review input[type="text"] {
  display: block;
  width: 230px;
  margin-top: 5px;
  margin-bottom: 20px;
  padding: 10px;
  border: 1px solid #757575;
}

@media screen and (min-width: 0px) and (max-width: 640px) {
  .layout-reviews .container-review-form .form-review input[type="email"],
  .layout-reviews .container-review-form .form-review input[type="text"] {
    width: 100%;
  }
}

.layout-reviews .container-review-form .form-review input[type="email"] {
  width: 495px;
}

@media screen and (min-width: 0px) and (max-width: 640px) {
  .layout-reviews .container-review-form .form-review input[type="email"] {
    width: 100%;
  }
}

.layout-reviews .container-review-form .form-review textarea {
  font-size: 0.875rem;
  width: 100%;
  height: 120px;
  margin-bottom: 35px;
  padding: 10px;
}

.layout-reviews .container-review-form .container-buttons {
  margin-top: 35px;
}

.layout-reviews .container-review-form .container-buttons button {
  padding: 10px 20px;
  border: 0;
  outline: none;
  background-color: transparent;
  color: #fff;
  text-align: center;
}

.layout-reviews .container-review-form .container-buttons button.submit:hover {
  background-color: #0089b9;
}

.layout-reviews .container-review-form .container-buttons button.submit {
  width: 200px;
  background-color: #00afec;
}

@media screen and (min-width: 0px) and (max-width: 640px) {
  .layout-reviews .container-review-form .container-buttons button.submit {
    width: 100%;
  }
}

.layout-reviews .container-review-form .container-buttons button.reset:hover {
  background-color: #5c5c5c;
}

.layout-reviews .container-review-form .container-buttons button.reset {
  width: 120px;
  margin-left: 10px;
  background-color: #757575;
}

@media screen and (min-width: 0px) and (max-width: 640px) {
  .layout-reviews .container-review-form .container-buttons button.reset {
    width: 100%;
    margin-top: 15px;
    margin-left: 0;
  }
}

.layout-reviews .container-review-form .rating {
  display: inline-block;
}

.layout-reviews .container-review-form .rating span {
  font-size: 0.875rem;
  display: inline-block;
  padding-bottom: 20px;
  padding-left: 10px;
  float: right;
  color: #757575;
  line-height: 40px;
}

.layout-reviews .container-review-form .rating:not(:checked) > input {
  position: absolute;
  top: -9999px;
  clip: rect(0, 0, 0, 0);
}

.layout-reviews .container-review-form .rating:not(:checked) > label {
  font-size: 1.5625rem;
  width: 1.25em;
  padding: 0 0.1em;
  float: right;
  color: #757575;
  font-weight: 700;
  white-space: nowrap;
  cursor: pointer;
  overflow: hidden;
}

.layout-reviews .container-review-form .rating:not(:checked) > label:active {
  position: relative;
  top: 2px;
  left: 2px;
}

.layout-reviews .container-review-form .rating:not(:checked) > label:before {
  content: "☆ ";
}

.layout-reviews .container-review-form .rating:not(:checked) > label:hover,
.layout-reviews
  .container-review-form
  .rating:not(:checked)
  > label:hover
  ~ label,
.layout-reviews .container-review-form .rating > input:checked + label:hover,
.layout-reviews
  .container-review-form
  .rating
  > input:checked
  + label:hover
  ~ label,
.layout-reviews .container-review-form .rating > input:checked ~ label,
.layout-reviews .container-review-form .rating > input:checked ~ label:hover,
.layout-reviews
  .container-review-form
  .rating
  > input:checked
  ~ label:hover
  ~ label,
.layout-reviews
  .container-review-form
  .rating
  > input:hover
  ~ input:checked
  ~ label {
  color: #f02e69;
}

.layout-reviews .container-review-form .rating > input:checked + label:before,
.layout-reviews
  .container-review-form
  .rating
  > input:checked
  + label
  ~ label:before,
.layout-reviews .container-review-form .rating > input:checked ~ label:before,
.layout-reviews
  .container-review-form
  .rating
  > input:checked
  ~ label
  ~ label:before,
.layout-reviews
  .container-review-form
  .rating
  > input:hover
  ~ input:checked
  ~ label:before {
  content: "★ ";
}

.layout-reviews .container-review {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #dbdbdb;
}

@media screen and (min-width: 0px) and (max-width: 640px) {
  .layout-reviews .container-review {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

.layout-reviews .container-review.is-hidden {
  display: none;
}

.layout-reviews .container-user {
  min-width: 150px;
  margin-right: 30px;
  text-align: center;
}

@media screen and (min-width: 0px) and (max-width: 640px) {
  .layout-reviews .container-user {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    text-align: left;
  }
}

.layout-reviews .container-user.is-hidden {
  display: none;
}

.layout-reviews .container-user .avatar {
  font-size: 1.875rem;
  display: inline-block;
  width: 90px;
  margin-bottom: 10px;
  border-radius: 50%;
  background-color: #dbdbdb;
  color: #2a2c2b;
  line-height: 90px;
  text-align: center;
}

@media screen and (min-width: 0px) and (max-width: 640px) {
  .layout-reviews .container-user .avatar {
    font-size: 1.25rem;
    width: 60px;
    margin-right: 20px;
    line-height: 60px;
  }
}

.layout-reviews .container-user .user-name {
  font-size: 0.75rem;
  display: block;
  color: #2a2c2b;
  font-weight: 700;
}

.layout-reviews .container-user .user-location {
  font-size: 0.75rem;
  display: block;
  color: #757575;
  text-transform: capitalize;
}

.layout-reviews .container-user .user-location.is-hidden {
  display: none;
}

.layout-sandbox {
  padding: 25px 0;
}

.layout-side-by-side {
  padding: 50px 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.layout-side-by-side .row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}

@media screen and (min-width: 0px) and (max-width: 640px) {
  .layout-side-by-side .row {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }
}

.layout-side-by-side section.image-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0 25px;
}

.layout-side-by-side section.image-container img {
  display: block;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  margin: 0 auto;
}

.layout-side-by-side.reverse .row {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}

@media screen and (min-width: 0px) and (max-width: 640px) {
  .layout-side-by-side.mobile-image-top .row,
  .layout-side-by-side.reverse .row {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

@media screen and (min-width: 0px) and (max-width: 640px) {
  .layout-side-by-side.mobile-image-bottom section.image-container {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }
}

@media screen and (min-width: 0px) and (max-width: 640px) {
  .layout-side-by-side.no-mobile-image section.image-container {
    display: none;
  }
}

.layout-side-by-side.no-tablet-image section {
  width: 100%;
}

@media screen and (min-width: 641px) and (max-width: 1024px) {
  .layout-side-by-side.no-tablet-image section.image-container {
    display: none;
  }
}

@media screen and (max-width: 1024px) {
  .layout-side-by-side.desktop-only-image section {
    width: 100%;
  }
}

@media screen and (max-width: 1024px) {
  .layout-side-by-side.desktop-only-image section.image-container {
    display: none;
  }
}

.layout-side-by-side.image-to-center section.image-container {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.layout-side-by-side.image-to-bottom section.image-container {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.layout-site-header.pull-logo-left .container .brand-logo {
  margin-right: auto;
}

.layout-site-header.pull-cta-right .container .right-block {
  margin-left: auto;
}

.layout-site-header .container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

@media screen and (max-width: 1024px) {
  .layout-site-header .container .middle-block {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }
  .layout-site-header .container .cta-phone {
    color: inherit;
    text-decoration: none;
  }
  .layout-site-header .container .cta-phone span {
    display: none;
  }
  .layout-site-header .container .cta-phone:before {
    font-size: 1.5rem;
    content: "\0260E";
  }
}

.layout-site-header .container .standard-logo {
  display: none;
}

@media screen and (min-width: 641px) {
  .layout-site-header .container .standard-logo {
    display: inline;
  }
}

@media screen and (min-width: 641px) {
  .layout-site-header .container .mobile-logo {
    display: none;
  }
}

.layout-site-header .container .cta-phone {
  padding: 15px;
}

.layout-tabbed-content {
  padding: 30px;
  background-color: #e6e6e6;
}

@media screen and (min-width: 0px) and (max-width: 640px) {
  .layout-tabbed-content .mobile-tabs.align-top .tabs li {
    float: left;
    border-top: 3px solid #e6e6e6;
    border-bottom: 0;
  }
  .layout-tabbed-content .mobile-tabs.align-top .tabs li.active {
    display: block;
  }
  .layout-tabbed-content
    .mobile-tabs.align-top
    .tabs
    li:first-child:nth-last-child(2),
  .layout-tabbed-content
    .mobile-tabs.align-top
    .tabs
    li:first-child:nth-last-child(2)
    ~ li {
    width: 50%;
  }
  .layout-tabbed-content
    .mobile-tabs.align-top
    .tabs
    li:first-child:nth-last-child(3),
  .layout-tabbed-content
    .mobile-tabs.align-top
    .tabs
    li:first-child:nth-last-child(3)
    ~ li {
    width: 33.33333%;
  }
  .layout-tabbed-content
    .mobile-tabs.align-top
    .tabs
    li:first-child:nth-last-child(4),
  .layout-tabbed-content
    .mobile-tabs.align-top
    .tabs
    li:first-child:nth-last-child(4)
    ~ li {
    width: 25%;
  }
  .layout-tabbed-content
    .mobile-tabs.align-top
    .tabs
    li:first-child:nth-last-child(5),
  .layout-tabbed-content
    .mobile-tabs.align-top
    .tabs
    li:first-child:nth-last-child(5)
    ~ li {
    width: 20%;
  }
  .layout-tabbed-content .mobile-tabs.align-top .tabs li.active,
  .layout-tabbed-content .mobile-tabs.align-top .tabs li:hover {
    border-top: 3px solid #00aeff;
    border-bottom: 0;
  }
}

.layout-tabbed-content * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.layout-tabbed-content .container {
  position: relative;
}

@media screen and (min-width: 641px) {
  .layout-tabbed-content .container.align-bottom {
    padding-top: 0;
    padding-bottom: 70px;
  }
  .layout-tabbed-content .container.align-left {
    padding-top: 0;
    padding-left: 200px;
  }
  .layout-tabbed-content .container.align-right {
    padding-top: 0;
    padding-right: 200px;
  }
}

.layout-tabbed-content .tab-trigger {
  display: none;
}

@media screen and (min-width: 0px) and (max-width: 640px) {
  .layout-tabbed-content .tab-trigger {
    display: block;
    position: relative;
    width: 100%;
    padding: 20px;
    border-bottom: 3px solid #00aeff;
    background-color: #f2f2f2;
    color: #00aeff;
    cursor: pointer;
  }
  .layout-tabbed-content .tab-trigger.mobile-tabs {
    display: none;
  }
  .layout-tabbed-content .tab-trigger:after {
    position: absolute;
    top: 20px;
    right: 20px;
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    content: "\0276F";
  }
  .layout-tabbed-content .tab-trigger.open:after {
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
  }
  .layout-tabbed-content .tab-trigger + .tabs {
    display: none;
    position: absolute;
    top: 67px;
    left: 0;
    z-index: 10;
  }
  .layout-tabbed-content .tab-trigger.mobile-tabs + .tabs {
    display: block;
    position: static;
    top: 0;
  }
  .layout-tabbed-content .tab-trigger.open + .tabs {
    display: block;
  }
}

.layout-tabbed-content .tabs {
  display: block;
  width: 100%;
  padding: 0;
  border-bottom: 3px solid #e6e6e6;
  background-color: #f2f2f2;
  list-style-type: none;
}

.layout-tabbed-content .tabs:after {
  display: table;
  clear: both;
  content: "";
}

.layout-tabbed-content .tabs li {
  width: 100%;
  padding: 20px;
  border-bottom: 3px solid #f2f2f2;
  background-color: #f2f2f2;
  cursor: pointer;
}

@media screen and (min-width: 641px) {
  .layout-tabbed-content .tabs li {
    float: left;
  }
}

.layout-tabbed-content .tabs li.active,
.layout-tabbed-content .tabs li:hover {
  border-bottom: 3px solid #00aeff;
  color: #00aeff;
}

@media screen and (min-width: 0px) and (max-width: 640px) {
  .layout-tabbed-content .tabs li.active {
    display: none;
  }
}

.layout-tabbed-content .tab-container {
  background-color: #fff;
}

.layout-tabbed-content .tab-container > section {
  display: none;
  padding: 20px;
  clear: both;
}

.layout-tabbed-content .tab-container > section.active {
  display: block;
}

@media screen and (min-width: 641px) {
  .layout-tabbed-content .align-top .tabs li {
    border-top: 3px solid #e6e6e6;
    border-bottom: 0;
  }
  .layout-tabbed-content .align-top .tabs li:first-child:nth-last-child(2),
  .layout-tabbed-content
    .align-top
    .tabs
    li:first-child:nth-last-child(2)
    ~ li {
    width: 50%;
  }
  .layout-tabbed-content .align-top .tabs li:first-child:nth-last-child(3),
  .layout-tabbed-content
    .align-top
    .tabs
    li:first-child:nth-last-child(3)
    ~ li {
    width: 33.33333%;
  }
  .layout-tabbed-content .align-top .tabs li:first-child:nth-last-child(4),
  .layout-tabbed-content
    .align-top
    .tabs
    li:first-child:nth-last-child(4)
    ~ li {
    width: 25%;
  }
  .layout-tabbed-content .align-top .tabs li:first-child:nth-last-child(5),
  .layout-tabbed-content
    .align-top
    .tabs
    li:first-child:nth-last-child(5)
    ~ li {
    width: 20%;
  }
}

@media screen and (min-width: 641px) {
  .layout-tabbed-content .align-top .tabs li.active,
  .layout-tabbed-content .align-top .tabs li:hover {
    border-top: 3px solid #00aeff;
    border-bottom: 0;
  }
}

@media screen and (min-width: 641px) {
  .layout-tabbed-content .align-bottom .tabs {
    position: absolute;
    bottom: 0;
    border-top: 3px solid #e6e6e6;
    border-bottom: 0;
  }
}

@media screen and (min-width: 641px) {
  .layout-tabbed-content .align-bottom .tabs li {
    border-top: 0;
    border-bottom: 3px solid #f2f2f2;
  }
  .layout-tabbed-content .align-bottom .tabs li:first-child:nth-last-child(2),
  .layout-tabbed-content
    .align-bottom
    .tabs
    li:first-child:nth-last-child(2)
    ~ li {
    width: 50%;
  }
  .layout-tabbed-content .align-bottom .tabs li:first-child:nth-last-child(3),
  .layout-tabbed-content
    .align-bottom
    .tabs
    li:first-child:nth-last-child(3)
    ~ li {
    width: 33.33333%;
  }
  .layout-tabbed-content .align-bottom .tabs li:first-child:nth-last-child(4),
  .layout-tabbed-content
    .align-bottom
    .tabs
    li:first-child:nth-last-child(4)
    ~ li {
    width: 25%;
  }
  .layout-tabbed-content .align-bottom .tabs li:first-child:nth-last-child(5),
  .layout-tabbed-content
    .align-bottom
    .tabs
    li:first-child:nth-last-child(5)
    ~ li {
    width: 20%;
  }
}

@media screen and (min-width: 641px) {
  .layout-tabbed-content .align-bottom .tabs li.active,
  .layout-tabbed-content .align-bottom .tabs li:hover {
    border-bottom: 3px solid #00aeff;
    color: #00aeff;
  }
}

@media screen and (min-width: 641px) {
  .layout-tabbed-content .align-left .tabs {
    position: absolute;
    top: 0;
    left: 0;
    width: 200px;
    height: 100%;
    border-right: 3px solid #e6e6e6;
    border-bottom: 0;
  }
}

@media screen and (min-width: 641px) {
  .layout-tabbed-content .align-left .tabs li {
    border-top: 0;
    border-bottom: 0;
    border-left: 3px solid #f2f2f2;
  }
}

@media screen and (min-width: 641px) {
  .layout-tabbed-content .align-left .tabs li.active,
  .layout-tabbed-content .align-left .tabs li:hover {
    border-bottom: 0;
    border-left: 3px solid #00aeff;
    color: #00aeff;
  }
}

@media screen and (min-width: 641px) {
  .layout-tabbed-content .align-right .tabs {
    position: absolute;
    top: 0;
    right: 0;
    width: 200px;
    height: 100%;
    border-bottom: 0;
    border-left: 3px solid #e6e6e6;
  }
}

@media screen and (min-width: 641px) {
  .layout-tabbed-content .align-right .tabs li {
    border-top: 0;
    border-right: 3px solid #f2f2f2;
    border-bottom: 0;
  }
}

@media screen and (min-width: 641px) {
  .layout-tabbed-content .align-right .tabs li.active,
  .layout-tabbed-content .align-right .tabs li:hover {
    border-right: 3px solid #00aeff;
    border-bottom: 0;
    color: #00aeff;
  }
}

.layout-top-bar a {
  font-size: 0.75rem;
  display: inline-block;
  padding: 10px;
  color: #000;
  line-height: 1.5;
}

.layout-top-bar .container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

@media screen and (min-width: 0px) and (max-width: 640px) {
  .layout-top-bar .container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

.layout-top-bar .container .left-block {
  -ms-flex: 1;
  flex: 1;
  -webkit-box-orient: vertical;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.layout-top-bar .container .left-block,
.layout-top-bar .container .left-items {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -webkit-box-direction: normal;
}

.layout-top-bar .container .left-items {
  -ms-flex: 1;
  flex: 1;
  -webkit-box-orient: horizontal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.layout-top-bar .container .right-block {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: right;
}

@media screen and (min-width: 0px) and (max-width: 640px) {
  .layout-top-bar .container .right-block {
    text-align: left;
  }
}

.layout-top-bar .container .right-items {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

@media screen and (min-width: 0px) and (max-width: 640px) {
  .layout-top-bar .container .right-items {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
}

@media screen and (max-width: 1024px) {
  .layout-top-bar.hide-left-tablet .container .left-block,
  .layout-top-bar.hide-left-tablet .container .left-items {
    display: none;
  }
}

@media screen and (min-width: 0px) and (max-width: 640px) {
  .layout-top-bar.hide-left-mobile .container .left-block,
  .layout-top-bar.hide-left-mobile .container .left-items {
    display: none;
  }
}

@media screen and (max-width: 1024px) {
  .layout-top-bar.hide-right-tablet .container .right-block,
  .layout-top-bar.hide-right-tablet .container .right-items {
    display: none;
  }
}

@media screen and (min-width: 0px) and (max-width: 640px) {
  .layout-top-bar.hide-right-mobile .container .right-block,
  .layout-top-bar.hide-right-mobile .container .right-items {
    display: none;
  }
}

.layout-top-bar.wide-left .container .left-block,
.layout-top-bar.wide-left .container .left-items,
.layout-top-bar.wide-right .container .right-block,
.layout-top-bar.wide-right .container .right-items {
  -webkit-box-flex: 2;
  -ms-flex: 2;
  flex: 2;
}

.slick-slider {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list,
.slick-slider {
  position: relative;
  display: block;
}

.slick-list {
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-list,
.slick-slider .slick-track {
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.slick-track:after,
.slick-track:before {
  content: "";
  display: table;
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}

[dir="rtl"] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

.slick-loading .slick-list {
  background: #fff url(ajax-loader.gif) 50% no-repeat;
}

.slick-next,
.slick-prev {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0;
  font-size: 0;
  cursor: pointer;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  padding: 0;
  border: none;
}

.slick-next,
.slick-next:focus,
.slick-next:hover,
.slick-prev,
.slick-prev:focus,
.slick-prev:hover {
  background: transparent;
  color: transparent;
  outline: none;
}

.slick-next:focus:before,
.slick-next:hover:before,
.slick-prev:focus:before,
.slick-prev:hover:before {
  opacity: 1;
}

.slick-next.slick-disabled:before,
.slick-prev.slick-disabled:before {
  opacity: 0.25;
}

.slick-next:before,
.slick-prev:before {
  font-family: slick;
  font-size: 20px;
  line-height: 1;
  color: #fff;
  opacity: 0.75;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  left: -25px;
}

[dir="rtl"] .slick-prev {
  left: auto;
  right: -25px;
}

.slick-prev:before {
  content: "←";
}

[dir="rtl"] .slick-prev:before {
  content: "→";
}

.slick-next {
  right: -25px;
}

[dir="rtl"] .slick-next {
  left: -25px;
  right: auto;
}

.slick-next:before {
  content: "→";
}

[dir="rtl"] .slick-next:before {
  content: "←";
}

.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
}

.slick-dots li {
  position: relative;
  display: inline-block;
  margin: 0 5px;
  padding: 0;
}

.slick-dots li,
.slick-dots li button {
  height: 20px;
  width: 20px;
  cursor: pointer;
}

.slick-dots li button {
  border: 0;
  background: transparent;
  display: block;
  outline: none;
  line-height: 0;
  font-size: 0;
  color: transparent;
  padding: 5px;
}

.slick-dots li button:focus,
.slick-dots li button:hover {
  outline: none;
}

.slick-dots li button:focus:before,
.slick-dots li button:hover:before {
  opacity: 1;
}

.slick-dots li button:before {
  position: absolute;
  top: 0;
  left: 0;
  content: "•";
  width: 20px;
  height: 20px;
  font-family: slick;
  font-size: 6px;
  line-height: 20px;
  text-align: center;
  color: #000;
  opacity: 0.25;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-dots li.slick-active button:before {
  color: #000;
  opacity: 0.75;
}

.text-center {
  text-align: center !important;
}

.text-center img {
  margin: 0 auto;
}

h1,
h2,
h3,
h4,
h5,
h6,
li,
ol,
p,
span,
ul {
  color: #5b5d58;
  font-family: roboto, sans-serif;
}

.bold {
  font-weight: 500;
}

.bolder {
  font-weight: 700;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  padding-bottom: 10px;
}

.before h1,
.before h2,
.before h3,
.before h4,
.before h5,
.before h6 {
  text-align: center;
}

.text-left h1,
.text-left h2,
.text-left h3,
.text-left h4,
.text-left h5,
.text-left h6 {
  text-align: left;
}

h1 {
  font-size: 34px;
  line-height: 48px;
}

@media screen and (min-width: 641px) {
  h1 {
    font-size: 46px;
    line-height: 52px;
  }
}

@media screen and (min-width: 1025px) {
  h1 {
    font-size: 50px;
    line-height: 56px;
  }
}

h2,
h3 {
  font-size: 28px;
  line-height: 34px;
}

@media screen and (min-width: 641px) {
  h2,
  h3 {
    font-size: 30px;
    line-height: 36px;
  }
}

@media screen and (min-width: 1025px) {
  h2,
  h3 {
    font-size: 34px;
    line-height: 40px;
  }
}

h2,
h3,
h4,
h5,
h6,
p {
  font-weight: 100;
}

h4 {
  font-size: 22px;
  line-height: 28px;
}

h5 {
  font-size: 18px;
  line-height: 24px;
}

h6 {
  font-size: 12px;
  line-height: 16px;
}

a {
  text-decoration: none;
}

footer {
  background-color: #424340;
}

li a,
p a {
  color: inherit;
  text-decoration: underline;
}

li a:hover,
p a:hover {
  color: orange;
}

img.reverse {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

.layout-legal p {
  font-size: 10px;
}

.clearfix {
  clear: both;
}

.disabled {
  text-decoration: none !important;
}

.disabled,
.disabled:hover {
  color: #938e88;
}

.logo.orange {
  fill: #ff8200;
}

.logo.white {
  fill: #fff;
}

.darker-gray {
  background-color: #424340;
}

.dark-gray {
  background-color: #5b5d58;
}

.gray {
  background-color: #938e88;
}

.light-bluegray {
  background-color: #6a7e91;
}

.bluegray {
  background-color: #576877;
}

.dark-bluegray {
  background-color: #45525e;
}

.bluegray,
.bluegray h1,
.bluegray h2,
.bluegray h3,
.bluegray h4,
.bluegray h5,
.bluegray h6,
.bluegray li,
.bluegray p,
.bluegray span,
.dark-bluegray,
.dark-bluegray h1,
.dark-bluegray h2,
.dark-bluegray h3,
.dark-bluegray h4,
.dark-bluegray h5,
.dark-bluegray h6,
.dark-bluegray li,
.dark-bluegray p,
.dark-bluegray span,
.dark-gray,
.dark-gray h1,
.dark-gray h2,
.dark-gray h3,
.dark-gray h4,
.dark-gray h5,
.dark-gray h6,
.dark-gray li,
.dark-gray p,
.dark-gray span,
.darker-gray,
.darker-gray h1,
.darker-gray h2,
.darker-gray h3,
.darker-gray h4,
.darker-gray h5,
.darker-gray h6,
.darker-gray li,
.darker-gray p,
.darker-gray span,
.gray,
.gray h1,
.gray h2,
.gray h3,
.gray h4,
.gray h5,
.gray h6,
.gray li,
.gray p,
.gray span,
.light-bluegray,
.light-bluegray h1,
.light-bluegray h2,
.light-bluegray h3,
.light-bluegray h4,
.light-bluegray h5,
.light-bluegray h6,
.light-bluegray li,
.light-bluegray p,
.light-bluegray span {
  color: #fff;
}

.light-gray {
  background-color: #dddad7;
}

.lighter-gray {
  background-color: #f7f4f1;
}

.lightest-gray {
  background-color: #f5f5f5;
}

.lightest-blue {
  background-color: #d9f0fa;
}

.white {
  background-color: #fff;
}

.orange-text {
  color: #e57500;
}

.btn {
  display: inline-block;
  padding: 15px 30px;
  color: #fff;
}

.btn.orange {
  background-color: #e57500;
}

.btn.blue {
  background-color: #5194ad;
}

.btn.lightblue {
  background-color: #5daac7;
}

.btn:hover {
  color: #fff !important;
  text-decoration: underline;
}

.btn.large-text {
  margin-bottom: 30px;
  font-size: 22px;
}

@media screen and (min-width: 0px) and (max-width: 640px) {
  .btn.large-text {
    font-size: 14px;
  }
}

.colored-link.medium-text {
  font-size: 18px;
}

.colored-link.light-blue {
  color: #5daac7;
}

.colored-link.orange {
  color: #e57500;
}

.color-link:hover.no-decoration {
  text-decoration: none;
}

.text.medium-text {
  font-size: 16px;
}

.text.xl-text {
  font-size: 36px;
}

.text.xl-text-sm-mobile {
  font-size: 24px;
}

@media screen and (min-width: 641px) {
  .text.xl-text-sm-mobile {
    font-size: 36px;
  }
}

.tiled-columns {
  max-width: 1000px;
  margin: 0 auto;
  padding: 15px 0;
}

.tiled-columns.last {
  padding-bottom: 80px;
}

.tiled-columns .before {
  margin-bottom: 0;
  padding: 30px 0;
  background-color: #938e88;
}

.tiled-columns h3 {
  padding-top: 50px;
  padding-bottom: 10px;
  text-align: center;
}

.tiled-columns h4 {
  padding-bottom: 0;
  color: #fff;
}

.tiled-columns h6 {
  padding-bottom: 10px;
  font-weight: 500;
}

@media screen and (min-width: 1025px) {
  .tiled-columns:first-of-type {
    border-bottom: 2px solid #dddad7;
  }
}

.tiled-columns .column-1,
.tiled-columns .column-2 {
  padding: 30px 20px;
}

@media screen and (max-width: 1024px) {
  .tiled-columns .column-1,
  .tiled-columns .column-2 {
    border-bottom: 2px solid #dddad7;
  }
}

@media screen and (min-width: 1025px) {
  .tiled-columns .column-1:after {
    position: absolute;
    top: 0;
    right: 0;
    width: 2px;
    min-height: 300px;
    background-color: #dddad7;
    content: "";
  }
}

.tiled-columns img {
  width: 142px;
  height: 100px;
  margin: 5px 15px 0 0;
  float: left;
}

.tiled-columns p {
  font-size: 13px;
}

.pop-out {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  margin: 0 auto;
  background-color: #03a0c1;
  text-align: center;
  text-transform: uppercase;
  z-index: 9999;
}

@media screen and (min-width: 1025px) {
  .pop-out {
    top: 170px;
    right: 0;
    left: auto;
    width: auto;
    height: 350px;
  }
}

.pop-out h1 {
  font-size: 36px;
  line-height: 1.15;
}

@media screen and (min-width: 641px) {
  .pop-out h1 {
    font-size: 40px;
  }
}

.pop-out span {
  display: block;
  font-size: 32px;
  text-transform: none;
}

@media screen and (min-width: 641px) {
  .pop-out span {
    font-size: 36px;
  }
}

.pop-out h2 {
  font-size: 28px;
}

@media screen and (min-width: 641px) {
  .pop-out h2 {
    font-size: 32px;
  }
}

.pop-out h4 {
  padding: 20px 0;
  border-bottom: 1px solid #fff;
  font-size: 16px;
  cursor: pointer;
}

@media screen and (min-width: 1025px) {
  .pop-out h4 {
    position: absolute;
    top: 160px;
    right: -150px;
    width: 350px;
    height: 60px;
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
    background-color: #03a0c1;
    font-size: 18px;
  }
}

.pop-out h6 {
  font-size: 12px;
  text-transform: none;
}

@media screen and (min-width: 1025px) {
  .pop-out h6 {
    font-size: 16px;
  }
}

.pop-out a {
  color: #fff;
}

.pop-out a.link {
  display: block;
  padding-top: 10px;
  text-decoration: underline;
}

.pop-out a:hover {
  color: #dddad7;
}

.pop-out svg {
  width: 20px;
  height: 20px;
  margin-left: 10px;
  padding: 5px;
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  border-radius: 100%;
  background-color: #fff;
  vertical-align: sub;
  fill: #03a0c1;
}

.pop-out .promo {
  display: none;
}

.pop-out .info {
  padding: 10px 0;
  border-bottom: 1px solid #fff;
}

.pop-out .phone {
  padding: 10px 0;
}

.pop-out .ribbon {
  position: relative;
  width: 60%;
  margin: 0 auto;
  background-color: #d53a25;
}

.pop-out .ribbon:after,
.pop-out .ribbon:before {
  position: absolute;
  width: 0;
  height: 0;
  content: "";
}

.pop-out .ribbon:before {
  left: 0;
  border-left: 6px solid #03a0c1;
}

.pop-out .ribbon:after,
.pop-out .ribbon:before {
  border-top: 12px solid transparent;
  border-bottom: 12px solid transparent;
}

.pop-out .ribbon:after {
  right: 0;
  border-right: 6px solid #03a0c1;
}

@media screen and (min-width: 1025px) {
  .pop-out.open {
    width: 350px;
    height: 350px;
  }
}

@media screen and (min-width: 1025px) {
  .pop-out.open h4 {
    top: 0;
    bottom: 0;
    left: -205px;
    width: 350px;
    margin: auto 0;
  }
}

.pop-out.open .promo {
  display: block;
}

@media screen and (min-width: 1025px) {
  .pop-out.open .promo {
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
  }
}

@media screen and (min-width: 1025px) {
  .pop-out.open .info {
    padding-bottom: 20px;
  }
}

@media screen and (min-width: 1025px) {
  .pop-out.open .phone {
    padding-top: 20px;
  }
}

.pop-out.open svg {
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}

.small-disclaimer {
  font-size: 10px;
  text-decoration: underline;
}

.nowrap {
  white-space: nowrap;
}

.no-text-decoration {
  text-decoration: none;
}

.updated h1,
.updated h2,
.updated h3,
.updated h4,
.updated h5,
.updated h6 {
  margin: 0;
  padding: 0;
  color: #5b5d58;
  font-weight: 700;
}

.updated h1.light,
.updated h2.light,
.updated h3.light,
.updated h4.light,
.updated h5.light,
.updated h6.light {
  color: #fff;
}

.updated h1.orange,
.updated h2.orange,
.updated h3.orange,
.updated h4.orange,
.updated h5.orange,
.updated h6.orange {
  color: #ff8200;
}

.updated h1 {
  font-size: 30px;
  line-height: 36px;
}

@media screen and (min-width: 641px) and (max-width: 1024px) {
  .updated h1 {
    font-size: 40px;
    line-height: 46px;
  }
}

@media screen and (min-width: 1025px) {
  .updated h1 {
    font-size: 50px;
    line-height: 56px;
  }
}

.updated h2 {
  font-size: 24px;
  line-height: 30px;
}

@media screen and (min-width: 641px) and (max-width: 1024px) {
  .updated h2 {
    font-size: 30px;
    line-height: 36px;
  }
}

@media screen and (min-width: 1025px) {
  .updated h2 {
    font-size: 36px;
    line-height: 40px;
  }
}

.updated h3 {
  font-size: 18px;
  line-height: 24px;
}

@media screen and (min-width: 641px) {
  .updated h3 {
    font-size: 20px;
    line-height: 26px;
  }
}

.updated h4 {
  font-size: 18px;
  line-height: 24px;
}

.updated p {
  font-size: 16px;
  line-height: 24px;
  padding-top: 15px;
}

.updated .sticky {
  z-index: 2;
}

.layout-2-column {
  padding: 30px 0;
}

.layout-2-column p {
  font-weight: 100;
}

.layout-2-column h6,
.layout-2-column p {
  margin-bottom: 10px;
  font-size: 13px;
}

.layout-2-column a {
  font-weight: 600;
}

.layout-2-column a:hover {
  color: #e57500;
}

.layout-2-column ol,
.layout-2-column ul {
  padding-bottom: 15px;
  padding-left: inherit;
}

.layout-2-column ol li,
.layout-2-column ol p,
.layout-2-column ul li,
.layout-2-column ul p {
  padding: 7.5px 0;
  font-weight: 600;
}

.layout-2-column ol li,
.layout-2-column ul li {
  list-style-position: outside;
}

.layout-2-column ol li:first-of-type,
.layout-2-column ul li:first-of-type {
  padding-top: 0;
}

.layout-2-column ol li:last-of-type,
.layout-2-column ul li:last-of-type {
  padding-bottom: 0;
}

.layout-2-column ol.lighter li,
.layout-2-column ol.lighter p,
.layout-2-column ul.lighter li,
.layout-2-column ul.lighter p {
  font-weight: 100;
}

.layout-2-column ol.basic,
.layout-2-column ul.basic {
  padding-left: 30px;
}

.layout-2-column ol.basic li,
.layout-2-column ol.basic p,
.layout-2-column ul.basic li,
.layout-2-column ul.basic p {
  font-weight: 300;
}

.layout-2-column ol.basic li,
.layout-2-column ul.basic li {
  margin-bottom: 0;
  padding-bottom: 5px;
  line-height: 17px;
}

@media screen and (min-width: 641px) {
  .layout-2-column ol.basic li,
  .layout-2-column ul.basic li {
    line-height: 24px;
  }
}

.layout-2-column ol.app-icons,
.layout-2-column ul.app-icons {
  list-style: none;
}

.layout-2-column li {
  font-size: 13px;
}

.layout-2-column .before {
  margin: 15px 0 20px;
  text-align: center;
}

@media screen and (min-width: 0px) and (max-width: 640px) {
  .layout-2-column .before {
    margin-bottom: 20px;
  }
}

.layout-2-column .before h2 {
  padding-bottom: 20px;
}

.layout-2-column .before a {
  font-size: inherit;
}

.layout-2-column .before.text-left {
  text-align: left;
}

.layout-2-column .after {
  text-align: center;
}

.layout-2-column .after a {
  color: inherit;
  font-size: inherit;
  font-weight: inherit;
}

.layout-2-column .after a:hover {
  text-decoration: underline;
}

@media screen and (max-width: 1024px) {
  .layout-2-column .column-1,
  .layout-2-column .column-2 {
    margin-bottom: 20px;
  }
}

.layout-2-column .copy-block {
  padding-bottom: 20px;
}

@media screen and (min-width: 0px) and (max-width: 640px) {
  .layout-2-column.row-reverse-on-small .column-wrapper .row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }
}

.tiled-columns .layout-2-column {
  max-width: 1000px;
  margin: 0 auto;
  padding: 0;
}

.tiled-columns .layout-2-column .before {
  margin-bottom: 0;
  padding: 30px 0;
  background-color: #938e88;
}

.tiled-columns .layout-2-column h4 {
  padding-bottom: 0;
  color: #fff;
}

.tiled-columns .layout-2-column h6 {
  padding-bottom: 20px;
  font-weight: 500;
}

@media screen and (min-width: 1025px) {
  .tiled-columns .layout-2-column:first-of-type {
    border-bottom: 2px solid #dddad7;
  }
}

.tiled-columns .layout-2-column .column-1,
.tiled-columns .layout-2-column .column-2 {
  padding: 30px 20px;
}

@media screen and (max-width: 1024px) {
  .tiled-columns .layout-2-column .column-1,
  .tiled-columns .layout-2-column .column-2 {
    border-bottom: 2px solid #dddad7;
  }
}

@media screen and (min-width: 1025px) {
  .tiled-columns .layout-2-column .column-1:after {
    position: absolute;
    top: 0;
    right: 0;
    width: 2px;
    min-height: 300px;
    background-color: #dddad7;
    content: "";
  }
}

.tiled-columns .layout-2-column img {
  width: 142px;
  height: 100px;
  margin: 5px 15px 0 0;
  float: left;
}

.tiled-columns .layout-2-column p {
  font-size: 13px;
}

.layout-2-column.overlay {
  padding: 30px 0;
}

@media screen and (min-width: 641px) {
  .layout-2-column.overlay {
    padding: 70px 0;
  }
}

.layout-2-column.overlay h6 {
  margin-bottom: 0;
  text-transform: uppercase;
}

.layout-2-column.overlay h6.normal-type {
  text-transform: none;
}

.layout-2-column.has-form .column-2 section {
  padding: 25px;
  background-color: #938e88;
}

@media screen and (min-width: 641px) and (max-width: 1024px) {
  .layout-2-column.has-form .column-2 section {
    padding: 15px;
  }
}

@media screen and (min-width: 1025px) {
  .layout-2-column.has-form .column-2 section {
    padding: 25px 45px;
  }
}

.layout-2-column.has-form .column-2 section h3 {
  color: #fff;
  font-weight: 100;
}

.layout-2-column.has-form .column-2 section .form-legal-contain {
  padding-top: 10px;
}

.layout-2-column.has-form .column-2 section .form-legal-contain p {
  padding: 0;
}

.layout-2-column.has-form .column-2 section .thank-you-text {
  width: 100%;
  margin: 20px auto;
  padding: 20px;
  background-color: #938e88;
  color: #fff;
  text-align: center;
}

.layout-2-column.has-form .column-2 section .thank-you-text span {
  display: block;
  color: #fff;
}

.layout-2-column.has-form .column-2 section .layout-legal p {
  color: #fff;
  font-size: 10px;
  line-height: 1.5;
}

.layout-2-column.flexpay-package-2col .column-1 img,
.layout-2-column.flexpay-package-2col .column-2 img {
  display: block;
  margin: 0 auto;
}

.layout-2-column.form-hero h1 {
  padding-bottom: 20px;
  color: #e57500;
}

@media screen and (max-width: 1024px) {
  .layout-2-column.form-hero h1 {
    text-align: center;
  }
}

@media screen and (min-width: 1025px) {
  .layout-2-column.form-hero h1 {
    font-size: 50px;
  }
}

.layout-2-column.form-hero h1 span {
  display: block;
}

@media screen and (max-width: 1024px) {
  .layout-2-column.form-hero h1 span {
    max-width: 300px;
    margin: 0 auto;
  }
}

@media screen and (min-width: 1025px) {
  .layout-2-column.form-hero h1 span {
    max-width: 450px;
  }
}

.layout-2-column.form-hero h1 span,
.layout-2-column.form-hero h3 {
  color: #f7f4f1;
}

.layout-2-column.form-hero h3 {
  font-size: 30px;
  text-align: center;
}

@media screen and (max-width: 1024px) {
  .layout-2-column.form-hero h3 {
    font-size: 22px;
  }
}

.layout-2-column.form-hero p {
  padding-right: 20px;
  padding-bottom: 10px;
  padding-left: 20px;
  color: #fff;
  font-size: 16px;
}

.layout-2-column.form-hero button {
  margin-top: 0;
}

.layout-2-column.form-hero .column-2 section {
  background-color: rgba(0, 0, 0, 0.5);
}

.layout-2-column.form-hero .row {
  position: relative;
}

.layout-2-column.form-hero .parsley-required {
  padding-right: 10px;
  color: #fff;
}

.layout-2-column.form-hero .extra-content {
  padding-top: 20px;
  border-top: 1px solid #f7f4f1;
}

@media screen and (min-width: 641px) and (max-width: 1024px) {
  .layout-2-column.form-hero .extra-content {
    border: 0;
  }
}

.layout-2-column.big-disclaimer .layout-legal p {
  font-size: 18px;
  line-height: 1.5;
}

.layout-2-column.orange-disclaimer .layout-legal p {
  color: #ff8200;
  font-size: 18px;
  line-height: 1.5;
}

.layout-2-column.orange-disclaimer .layout-legal p strong {
  display: block;
}

.layout-2-column.small-table .before {
  margin-bottom: 20px;
}

.layout-2-column.small-table .column-wrapper {
  max-width: 675px;
  margin: 0 auto;
}

.layout-2-column.small-table .all .columns {
  height: 100px;
  padding: 10px 0;
  border: 1px solid #dddad7;
  background-color: #fff;
}

.layout-2-column.small-table .all.columns:first-of-type {
  padding-right: 0;
}

.layout-2-column.small-table .all.columns:last-of-type {
  padding-left: 0;
}

.layout-2-column.small-table h5,
.layout-2-column.small-table p {
  position: relative;
  top: 50%;
  margin: 0;
  padding: 0 0 0 25px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.layout-2-column.equipment-preview {
  padding-top: 10px;
  text-align: center;
}

.layout-2-column.equipment-preview .before {
  margin-bottom: 0;
}

.layout-2-column.equipment-preview img {
  display: block;
  margin: 0 auto;
}

.layout-2-column.equipment-preview h6 {
  margin: 15px 0 5px;
  color: #938e88;
  font-weight: 100;
}

.layout-2-column.equipment-preview .flex-container {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  min-height: 90px;
}

.layout-2-column.equipment-preview .flex-container,
.layout-2-column.equipment-preview .flex-container-img {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.layout-2-column.equipment-preview .flex-container-img {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  min-height: 150px;
}

@media screen and (-ms-high-contrast: none) {
  .layout-2-column.equipment-preview .flex-container-img {
    display: block;
  }
}

.layout-2-column.equipment-preview p {
  margin-bottom: 0;
  padding-top: 5px;
  font-size: 16px;
  font-weight: 100;
}

.layout-2-column.equipment-preview .link-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.layout-2-column.equipment-preview .link-container a {
  position: relative;
  padding-bottom: 5px;
  color: #6a7e91;
  font-weight: 500;
}

.layout-2-column.equipment-preview .link-container a:after {
  position: absolute;
  bottom: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  content: "";
}

.layout-2-column.equipment-preview .link-container a:hover:after {
  width: 100%;
  border-bottom: 4px solid #6a7e91;
  -webkit-animation: 0.2s a ease-in;
  animation: 0.2s a ease-in;
}

@media screen and (min-width: 0px) and (max-width: 640px) {
  .layout-2-column.equipment-preview .columns {
    margin-bottom: 15px;
  }
}

.layout-2-column.single-col {
  text-align: left;
}

.layout-2-column.single-col h2 {
  font-weight: 100;
}

.layout-2-column.single-col p {
  font-size: 13px;
}

.layout-2-column.package-preview .preview {
  padding: 30px 15px;
}

.layout-2-column.package-preview .preview a,
.layout-2-column.package-preview .preview h5,
.layout-2-column.package-preview .preview p {
  color: #fff;
}

.layout-2-column.package-preview .preview a,
.layout-2-column.package-preview .preview p {
  font-size: 13px;
}

.layout-2-column.package-preview .preview p {
  font-weight: 100;
}

.layout-2-column.package-preview .preview a {
  font-weight: 700;
}

@media screen and (min-width: 0px) and (max-width: 640px) {
  .layout-2-column.package-preview .column-2 {
    margin: 15px 0;
  }
}

.layout-2-column.package-preview .column-2 .preview {
  background-color: #ff8200;
}

.layout-2-column.package-preview .column-1 .preview {
  background-color: #576877;
}

.layout-2-column.package-preview-v2 {
  padding: 60px 0;
}

.layout-2-column.package-preview-v2 .preview {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  height: 360px;
  padding: 20px;
  -webkit-box-shadow: 15px 15px 40px rgba(0, 0, 0, 0.3);
  box-shadow: 15px 15px 40px rgba(0, 0, 0, 0.3);
}

.layout-2-column.package-preview-v2 .preview h2,
.layout-2-column.package-preview-v2 .preview h3 {
  color: #fff;
  font-size: 36px;
  font-weight: 700;
}

.layout-2-column.package-preview-v2 .preview a {
  position: relative;
  padding-bottom: 10px;
  color: #fff;
  text-decoration: none;
}

.layout-2-column.package-preview-v2 .preview a:after {
  position: absolute;
  bottom: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  border-bottom: 4px solid #fff;
  content: "";
}

.layout-2-column.package-preview-v2 .preview a:hover:after {
  width: 100%;
  -webkit-animation: 0.2s a ease-in;
  animation: 0.2s a ease-in;
}

@-webkit-keyframes a {
  0% {
    width: 0;
  }
  to {
    width: 100%;
  }
}

@keyframes a {
  0% {
    width: 0;
  }
  to {
    width: 100%;
  }
}

.layout-2-column.package-preview-v2 .preview > div {
  margin-bottom: 35px;
}

.layout-2-column.package-preview-v2 .column-wrapper {
  overflow: visible;
}

.layout-2-column section h3 {
  padding-top: 15px;
}

.layout-2-column section h3:first-child {
  padding-top: 0;
}

.layout-2-column.specials-form .layout-legal {
  padding-top: 15px;
  clear: both;
}

.layout-2-column.specials-form .layout-legal p {
  text-align: center;
}

.layout-2-column.specials-form h3 {
  color: #fff;
  font-size: 50px;
  font-weight: 700;
  line-height: 56px;
}

.layout-2-column.specials-form p {
  color: #fff;
  font-size: 18px;
  line-height: 24px;
}

.layout-2-column.specials-form .column-2 section {
  background-color: #797570;
}

@media screen and (min-width: 641px) {
  .layout-2-column.specials-form .column-2 section {
    min-height: 505px;
  }
}

@media screen and (min-width: 1025px) {
  .layout-2-column.specials-form .column-2 section {
    min-height: 495px;
  }
}

.layout-2-column.specials-form .column-2 section h3 {
  display: none;
}

.updated .layout-2-column {
  padding: 15px 0;
}

@media screen and (min-width: 641px) {
  .updated .layout-2-column {
    padding: 30px 0;
  }
}

.updated .layout-2-column.tall {
  padding: 30px 0;
}

@media screen and (min-width: 641px) {
  .updated .layout-2-column.tall {
    padding: 60px 0;
  }
}

.updated .layout-2-column.tall-top {
  padding-top: 30px;
}

@media screen and (min-width: 641px) {
  .updated .layout-2-column.tall-top {
    padding-top: 60px;
  }
}

.updated .layout-2-column.tall-bottom {
  padding-bottom: 30px;
}

@media screen and (min-width: 641px) {
  .updated .layout-2-column.tall-bottom {
    padding-bottom: 60px;
  }
}

.updated .layout-2-column .before {
  margin-top: 0;
}

.updated .layout-2-column .before h2 {
  padding-bottom: 0;
}

.updated .layout-2-column .before p {
  margin-bottom: 0;
}

.updated .layout-2-column section h3 {
  padding-top: 0;
}

.updated .layout-2-column p {
  font-size: 16px;
  line-height: 24px;
}

.updated .layout-2-column .layout-legal p {
  padding-top: 0;
  font-size: 12px;
  line-height: 18px;
}

.layout-2-column.city-geo-col-2 {
  padding-top: 30px;
  padding-bottom: 30px;
}

@media screen and (min-width: 641px) {
  .layout-2-column.city-geo-col-2 {
    padding-top: 60px;
    padding-bottom: 36px;
  }
}

.layout-2-column.city-geo-col-2 h2 {
  font-size: 24px;
  font-weight: 700;
}

@media screen and (min-width: 641px) {
  .layout-2-column.city-geo-col-2 h2 {
    font-size: 36px;
  }
}

.layout-2-column.city-geo-col-2 p {
  font-size: 16px;
}

.layout-2-column.city-geo-col-2 .column-1 {
  padding-right: 20px;
}

@media screen and (min-width: 1025px) {
  .layout-2-column.city-geo-col-2 .column-1 {
    padding-right: 135px;
  }
}

.layout-2-column.city-geo-col-2 .column-2 {
  padding-left: 20px;
}

@media screen and (min-width: 1025px) {
  .layout-2-column.city-geo-col-2 .column-2 {
    padding-left: 75px;
  }
}

.layout-2-column.city-geo-col-2 .top-section-col {
  display: none;
}

@media screen and (min-width: 641px) {
  .layout-2-column.city-geo-col-2 .top-section-col {
    display: block;
    padding-bottom: 45px;
  }
}

.layout-2-column.city-geo-col-2 .top-section-col img {
  -webkit-box-shadow: 15px 15px 40px rgba(0, 0, 0, 0.3);
  box-shadow: 15px 15px 40px rgba(0, 0, 0, 0.3);
}

.layout-2-column.city-geo-col-2 .bottom-section-col {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

@media screen and (min-width: 641px) {
  .layout-2-column.city-geo-col-2 .bottom-section-col {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    padding-top: 45px;
  }
}

@media screen and (min-width: 1025px) {
  .layout-2-column.city-geo-col-2 .bottom-section-col {
    padding-right: 100px;
    padding-left: 50px;
  }
}

@media screen and (max-width: 1024px) {
  .layout-2-column.city-geo-col-2 .bottom-section-col img {
    display: block;
    margin: 0 auto;
  }
}

.layout-2-column.city-geo-col-2 .bottom-section-col .left-section-btm {
  min-height: 120px;
  padding: 10px 0;
}

@media screen and (min-width: 641px) {
  .layout-2-column.city-geo-col-2 .bottom-section-col .left-section-btm {
    -ms-flex-preferred-size: 40%;
    flex-basis: 40%;
    padding-top: 0;
    padding-right: 8px;
    padding-bottom: 0;
  }
}

.layout-2-column.city-geo-col-2 .bottom-section-col .right-section-btm {
  -ms-flex-preferred-size: 60%;
  flex-basis: 60%;
  text-align: center;
}

.layout-2-column.city-geo-col-2 .bottom-section-col .burgler-callout {
  display: inline-block;
  color: #ff8200;
  font-size: 40px;
  line-height: 35px;
}

.layout-2-column.city-geo-col-2 .bottom-section-col .burgler-text {
  display: block;
  font-size: 18px;
  font-weight: 100;
}

.layout-2-column.city-geo-col-2 .alternate-layout {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.layout-2-column.city-geo-col-2 .alternate-layout img {
  display: block;
  margin: 0 auto;
}

.layout-2-column.city-geo-col-2 .alternate-layout .left-section-btm {
  padding-right: 0;
}

.layout-2-column.city-geo-col-2 .alternate-layout .right-section-btm {
  padding-top: 20px;
}

.layout-2-column.city-geo-col-2-feature {
  padding-bottom: 30px;
  background-color: #f5f5f5;
}

@media screen and (min-width: 641px) {
  .layout-2-column.city-geo-col-2-feature {
    padding-bottom: 0;
  }
}

.layout-2-column.city-geo-col-2-feature h2,
.layout-2-column.city-geo-col-2-feature p,
.layout-2-column.city-geo-col-2-feature ul {
  text-align: left;
}

.layout-2-column.city-geo-col-2-feature h2 {
  font-size: 24px;
  font-weight: 700;
}

@media screen and (min-width: 641px) {
  .layout-2-column.city-geo-col-2-feature h2 {
    font-size: 36px;
  }
}

.layout-2-column.city-geo-col-2-feature p {
  font-size: 16px;
}

.layout-2-column.city-geo-col-2-feature ul {
  padding-bottom: 0;
  padding-left: 20px;
}

@media screen and (min-width: 641px) {
  .layout-2-column.city-geo-col-2-feature ul {
    padding-bottom: 15px;
    padding-left: 40px;
  }
}

.layout-2-column.city-geo-col-2-feature li {
  font-size: 16px;
  font-weight: 100;
}

@media screen and (max-width: 1024px) {
  .layout-2-column.city-geo-col-2-feature li {
    padding: 0;
  }
}

@media screen and (max-width: 1024px) {
  .layout-2-column.city-geo-col-2-feature .column-1 {
    margin-bottom: 0;
  }
}

.layout-2-column.city-geo-col-2-feature .after {
  display: none;
}

@media screen and (min-width: 641px) {
  .layout-2-column.city-geo-col-2-feature .after {
    display: block;
    padding-top: 20px;
  }
}

.layout-2-column.city-geo-form .column-1 h2 {
  font-size: 24px;
  font-weight: 700;
}

@media screen and (min-width: 641px) {
  .layout-2-column.city-geo-form .column-1 h2 {
    font-size: 36px;
  }
}

.layout-2-column.city-geo-form .column-1 p {
  font-size: 16px;
}

.layout-2-column.lead-form-2col-hero {
  padding: 0;
}

@media screen and (min-width: 641px) {
  .layout-2-column.lead-form-2col-hero {
    padding: 30px 0;
  }
}

.layout-2-column.lead-form-2col-hero .column-1 {
  margin-bottom: 0;
  padding-top: 60px;
  padding-bottom: 60px;
  text-align: center;
}

@media screen and (min-width: 641px) and (max-width: 1024px) {
  .layout-2-column.lead-form-2col-hero .column-1 {
    margin-bottom: 0;
    padding-top: 90px;
    padding-bottom: 90px;
  }
}

@media screen and (min-width: 1025px) {
  .layout-2-column.lead-form-2col-hero .column-1 {
    margin-bottom: 20px;
    padding-top: 125px;
    padding-bottom: 125px;
  }
}

.layout-2-column.lead-form-2col-hero .column-1 h1 {
  color: #fff;
  font-size: 30px;
}

@media screen and (min-width: 641px) and (max-width: 1024px) {
  .layout-2-column.lead-form-2col-hero .column-1 h1 {
    font-size: 40px;
  }
}

@media screen and (min-width: 1025px) {
  .layout-2-column.lead-form-2col-hero .column-1 h1 {
    font-size: 50px;
  }
}

.layout-2-column.lead-form-2col-hero .column-1 p {
  color: #fff;
  font-size: 18px;
}

@media screen and (min-width: 641px) {
  .layout-2-column.lead-form-2col-hero .column-1 p {
    font-size: 20px;
  }
}

.layout-2-column.lead-form-2col-hero .column-1 p.large-text {
  color: #fff;
  font-size: 24px;
  line-height: 30px;
}

@media screen and (min-width: 641px) and (max-width: 1024px) {
  .layout-2-column.lead-form-2col-hero .column-1 p.large-text {
    font-size: 30px;
    line-height: 36px;
  }
}

@media screen and (min-width: 1025px) {
  .layout-2-column.lead-form-2col-hero .column-1 p.large-text {
    font-size: 36px;
    line-height: 42px;
  }
}

.layout-2-column.lead-form-2col-hero .column-2 {
  padding: 0;
}

@media screen and (min-width: 1025px) {
  .layout-2-column.lead-form-2col-hero .column-2 {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

.layout-2-column .flexpay-section-box .light-orange {
  background-color: #ff8200;
}

.layout-2-column .flexpay-section-box .section-container {
  width: 290px;
  margin: 0 auto;
  padding: 10px;
}

@media screen and (min-width: 641px) {
  .layout-2-column .flexpay-section-box .section-container {
    width: 320px;
    padding: 25px;
  }
}

@media screen and (min-width: 1025px) {
  .layout-2-column .flexpay-section-box .section-container {
    width: 400px;
    padding: 30px;
  }
}

.layout-2-column .flexpay-section-box .section-container.box-shadow {
  margin-bottom: 25px;
  -webkit-box-shadow: 7px 7px 40px rgba(0, 0, 0, 0.3);
  box-shadow: 7px 7px 40px rgba(0, 0, 0, 0.3);
}

@media screen and (min-width: 641px) {
  .layout-2-column .flexpay-section-box .section-container.box-shadow {
    margin-bottom: 45px;
    -webkit-box-shadow: 15px 15px 40px rgba(0, 0, 0, 0.3);
    box-shadow: 15px 15px 40px rgba(0, 0, 0, 0.3);
  }
}

.layout-2-column .flexpay-section-box h2 {
  color: #fff;
  font-size: 24px;
  font-weight: 700;
}

@media screen and (min-width: 641px) {
  .layout-2-column .flexpay-section-box h2 {
    font-size: 36px;
  }
}

.layout-2-column .flexpay-section-box p {
  color: #fff;
  font-size: 18px;
  line-height: 26px;
}

@media screen and (min-width: 641px) {
  .layout-2-column .flexpay-section-box p {
    font-size: 20px;
  }
}

.layout-2-column .flexpay-section-box .light {
  font-weight: 100;
}

.layout-2-column .flexpay-section-box .conjunction {
  font-size: 24px;
  font-weight: 700;
  line-height: 42px;
}

@media screen and (min-width: 641px) {
  .layout-2-column .flexpay-section-box .conjunction {
    font-size: 36px;
  }
}

.layout-2-column.single-package-card h3 {
  padding-bottom: 15px;
  font-size: 36px;
  font-weight: 700;
  line-height: 42px;
}

.faq-answer .layout-2-column .margin-left {
  margin-left: 25px;
}

@media screen and (min-width: 641px) {
  .layout-2-column .short-card h4 {
    padding-top: 40px;
    padding-bottom: 39px;
  }
}

.new-style .layout-2-column ul {
  margin-left: 25px;
}

.new-style .layout-2-column ul li {
  font-weight: 100;
  line-height: 20px;
}

.new-style .layout-2-column small a {
  color: #5b5d58;
  font-size: 10px;
  font-weight: 300;
  text-decoration: underline;
}

.layout-3-column .before p {
  padding-top: 10px;
  padding-bottom: 50px;
  text-align: center;
}

.layout-3-column .after a {
  color: inherit;
  font-weight: inherit;
}

.layout-3-column .after a:hover {
  text-decoration: underline;
}

.layout-3-column .after a.btn {
  display: block;
  width: 150px;
  margin: 30px auto 0;
  background-color: #20ad8a;
  color: #fff;
  text-align: center;
}

.layout-3-column.package-preview .preview {
  padding: 30px 15px;
}

.layout-3-column.package-preview .preview a,
.layout-3-column.package-preview .preview h5,
.layout-3-column.package-preview .preview p {
  color: #fff;
}

.layout-3-column.package-preview .preview a,
.layout-3-column.package-preview .preview p {
  font-size: 13px;
}

.layout-3-column.package-preview .preview p {
  font-weight: 100;
}

.layout-3-column.package-preview .preview a {
  font-weight: 700;
}

@media screen and (min-width: 0px) and (max-width: 640px) {
  .layout-3-column.package-preview .column-2 {
    margin: 15px 0;
  }
}

.layout-3-column.package-preview .column-2 .preview {
  background-color: #576877;
}

.layout-3-column.package-preview .column-1 .preview {
  background-color: #5daac7;
}

.layout-3-column.package-preview .column-3 .preview {
  background-color: #ff8200;
}

.layout-3-column.benefits:nth-of-type(odd) {
  padding-top: 10px;
}

.layout-3-column.benefits:nth-of-type(2n) {
  padding-bottom: 10px;
}

.layout-3-column.benefits .benefit-wrapper {
  background-color: #fff;
}

@media screen and (min-width: 641px) {
  .layout-3-column.benefits .benefit-wrapper {
    margin-top: 20px;
  }
}

.layout-3-column.benefits h4 {
  padding: 20px;
  background-color: #938e88;
  color: #fff;
}

.layout-3-column.benefits p {
  padding: 20px;
}

.layout-3-column.cards .column-wrapper {
  padding-bottom: 30px;
}

.layout-3-column.cards .columns {
  margin-bottom: 15px;
}

@media screen and (min-width: 641px) {
  .layout-3-column.cards .columns {
    margin-bottom: 0;
  }
}

.layout-3-column.cards section {
  padding: 30px;
  padding-top: 15px;
  padding-bottom: 80px;
  border: 1px solid #dddad7;
  background-color: #fff;
  -webkit-box-shadow: 0 15px 13px hsla(0, 0%, 61%, 0.4);
  box-shadow: 0 15px 13px hsla(0, 0%, 61%, 0.4);
}

@media screen and (min-width: 641px) {
  .layout-3-column.cards section {
    padding-bottom: 65px;
  }
}

.layout-3-column.cards section section {
  padding: 0;
  border: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.updated .layout-3-column {
  padding: 15px 0;
}

@media screen and (min-width: 641px) {
  .updated .layout-3-column {
    padding: 30px 0;
  }
}

.updated .layout-3-column.tall {
  padding: 30px 0;
}

@media screen and (min-width: 641px) {
  .updated .layout-3-column.tall {
    padding: 60px 0;
  }
}

.updated .layout-3-column.tall-top {
  padding-top: 30px;
}

@media screen and (min-width: 641px) {
  .updated .layout-3-column.tall-top {
    padding-top: 60px;
  }
}

.updated .layout-3-column.tall-bottom {
  padding-bottom: 30px;
}

@media screen and (min-width: 641px) {
  .updated .layout-3-column.tall-bottom {
    padding-bottom: 60px;
  }
}

.updated .layout-3-column.cards {
  padding: 20px 0;
}

@media screen and (min-width: 641px) {
  .updated .layout-3-column.cards {
    padding: 60px 0;
  }
}

.updated .layout-3-column .before {
  padding-bottom: 30px;
}

.updated .layout-3-column .before p {
  padding-top: 15px;
  padding-bottom: 10px;
}

.updated .layout-3-column .arrow-link {
  position: absolute;
  bottom: 15px;
  left: 50%;
  margin-left: -15px;
}

.layout-4-column .after a {
  color: inherit;
  font-weight: inherit;
}

.layout-4-column .after a:hover {
  text-decoration: underline;
}

footer .layout-4-column {
  padding-top: 0;
  color: #fff;
}

footer .layout-4-column h4 {
  color: #fff;
  font-size: 22px;
  font-weight: 100;
}

footer .layout-4-column a h4 {
  font-weight: 800;
}

footer .layout-4-column h5 {
  padding-bottom: 0;
  color: #fff;
  font-weight: 400;
}

footer .layout-4-column p {
  color: #fff;
}

footer .layout-4-column .after {
  padding-top: 30px;
}

footer .layout-4-column .after .layout-legal p {
  color: #9c9c9c;
  font-size: 10px;
  line-height: 16px;
}

footer .layout-4-column .column-wrapper {
  padding-bottom: 0;
}

@media screen and (min-width: 641px) {
  footer .layout-4-column .column-wrapper {
    padding-bottom: 20px;
  }
}

footer .layout-4-column .column-wrapper .row {
  padding: 30px 0;
  padding-bottom: 15px;
  border: 1px solid #dddad7;
  border-right: 0;
  border-left: 0;
}

@media screen and (min-width: 641px) and (max-width: 1024px) {
  footer .layout-4-column .columns {
    padding-right: 7px;
    padding-left: 7px;
  }
  footer .layout-4-column .columns a,
  footer .layout-4-column .columns h4,
  footer .layout-4-column .columns h5,
  footer .layout-4-column .columns p {
    text-align: left;
  }
}

footer .layout-4-column .layout-legal p {
  color: #9c9c9c;
  font-size: 10px;
  line-height: 16px;
}

footer .layout-4-column .layout-legal a {
  color: #9c9c9c;
  font-weight: 400;
  line-height: 2;
  text-decoration: underline;
}

footer .layout-4-column .layout-legal a:hover {
  color: #ff8200;
}

footer .layout-4-column .links {
  padding: 0 0 15px;
}

@media screen and (min-width: 641px) {
  footer .layout-4-column .links.two-col {
    -webkit-column-count: 2;
    column-count: 2;
  }
}

footer .layout-4-column .links a {
  display: table;
  color: #9c9c9c;
  font-size: 12px;
}

@media screen and (min-width: 641px) {
  footer .layout-4-column .links a {
    line-height: 26px;
  }
}

footer .layout-4-column .links a:hover {
  color: #ff8200;
}

footer .layout-4-column .column-1 {
  padding-bottom: 15px;
}

footer .layout-4-column .column-1 a h4 {
  display: block;
  padding-bottom: 15px;
}

@media screen and (min-width: 0px) and (max-width: 640px) {
  footer .layout-4-column .column-2:after,
  footer .layout-4-column .column-4:after {
    position: absolute;
    right: 0;
    left: 0;
    width: 95%;
    margin: 0 auto;
    content: "";
  }
}

@media screen and (min-width: 0px) and (max-width: 640px) {
  footer .layout-4-column .column-2 {
    padding-top: 15px;
    padding-bottom: 7.5px;
  }
}

@media screen and (min-width: 0px) and (max-width: 640px) {
  footer .layout-4-column .column-2:after {
    top: 0;
    border-bottom: 1px solid #938e88;
  }
}

@media screen and (min-width: 0px) and (max-width: 640px) {
  footer .layout-4-column .column-4 {
    padding-top: 7.5px;
    padding-bottom: 15px;
  }
}

footer .layout-4-column .column-4:after {
  bottom: 0;
}

.layout-4-column.benefits .before {
  padding: 20px 30px 50px;
}

.layout-4-column.benefits .before p {
  padding-top: 0;
  border: 0;
  text-align: center;
}

.layout-4-column.benefits .after {
  margin-top: 40px;
  text-align: center;
}

.layout-4-column.benefits .benefit-wrapper {
  margin-bottom: 20px;
  background-color: #fff;
}

.layout-4-column.benefits .header-wrapper {
  height: 105px;
  background-color: #5194ad;
}

.layout-4-column.benefits .header-wrapper h4 {
  position: relative;
  top: 50%;
  margin: auto 0;
  padding: 20px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  color: #fff;
  text-align: center;
  vertical-align: middle;
}

.layout-4-column.benefits img {
  display: block;
  margin: 40px auto;
}

.layout-4-column.benefits p {
  padding: 20px;
  border-top: 1px solid #dddad7;
}

.updated footer .layout-4-column {
  padding-top: 0;
}

.updated footer .layout-4-column .layout-legal p {
  color: #9c9c9c;
  font-size: 10px;
  line-height: 16px;
}

.updated footer .layout-4-column .layout-legal a {
  color: #9c9c9c;
  font-weight: 400;
}

.updated footer .layout-4-column h5 {
  color: #fff;
  font-weight: 400;
}

.updated footer .layout-4-column .links a {
  color: #9c9c9c;
  font-size: 12px;
}

.updated footer .layout-4-column .links a:hover {
  color: #ff8200;
}

.updated footer .layout-4-column .column-2:after,
.updated footer .layout-4-column .column-3:after,
.updated footer .layout-4-column .column-4:after {
  display: none;
}

.updated footer .layout-4-column .column-wrapper {
  padding-bottom: 0;
}

.updated footer .layout-4-column .column-wrapper .row {
  padding: 30px 0;
  padding-bottom: 15px;
  border: 1px solid #dddad7;
  border-right: 0;
  border-left: 0;
}

.updated footer .layout-4-column .after {
  padding-top: 30px;
}

.layout-5-column .after a {
  color: inherit;
  font-weight: inherit;
}

.layout-5-column .after a:hover {
  text-decoration: underline;
}

.layout-5-column.equipment-preview {
  padding: 10px 0;
  text-align: center;
}

.layout-5-column.equipment-preview img {
  display: block;
  margin: 0 auto;
}

.layout-5-column.equipment-preview h4 {
  padding-bottom: 10px;
  font-weight: 700;
}

@media screen and (min-width: 1025px) {
  .layout-5-column.equipment-preview h4 {
    padding-bottom: 35px;
  }
}

.layout-5-column.equipment-preview h6 {
  margin: 15px 0 5px;
  color: #938e88;
  font-weight: 100;
}

.layout-5-column.equipment-preview .flex-container {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  min-height: 90px;
}

.layout-5-column.equipment-preview .flex-container,
.layout-5-column.equipment-preview .flex-container-img {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.layout-5-column.equipment-preview .flex-container-img {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  min-height: 150px;
}

@media screen and (-ms-high-contrast: none) {
  .layout-5-column.equipment-preview .flex-container-img {
    display: block;
  }
}

.layout-5-column.equipment-preview p {
  padding-top: 5px;
  font-weight: 100;
}

.layout-5-column.equipment-preview .link-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.layout-5-column.equipment-preview .link-container a {
  position: relative;
  padding-bottom: 5px;
  color: #6a7e91;
  font-weight: 500;
}

.layout-5-column.equipment-preview .link-container a:after {
  position: absolute;
  bottom: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  content: "";
}

.layout-5-column.equipment-preview .link-container a:hover:after {
  width: 100%;
  border-bottom: 4px solid #6a7e91;
  -webkit-animation: 0.2s a ease-in;
  animation: 0.2s a ease-in;
}

@media screen and (min-width: 0px) and (max-width: 640px) {
  .layout-5-column.equipment-preview .columns {
    margin-bottom: 15px;
  }
}

.layout-5-column.preview-end {
  padding-bottom: 45px;
}

.layout-5-column.benefits .before {
  padding: 20px 30px 50px;
}

.layout-5-column.benefits .before p {
  padding: 15px 0;
  border: 0;
  text-align: center;
}

.layout-5-column.benefits .after {
  margin-top: 40px;
  text-align: center;
}

.layout-5-column.benefits .collapse {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

@media screen and (min-width: 1025px) {
  .layout-5-column.benefits .collapse {
    -ms-flex-pack: distribute;
    justify-content: space-around;
  }
}

.layout-5-column.benefits .all {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 95%;
  margin: 0 2.5%;
}

@media screen and (min-width: 641px) and (max-width: 1024px) {
  .layout-5-column.benefits .all {
    width: 45%;
    margin: 0 2.5%;
  }
}

@media screen and (min-width: 1025px) {
  .layout-5-column.benefits .all {
    width: 18%;
    margin: 0 1%;
  }
}

.layout-5-column.benefits .benefit-wrapper {
  margin-bottom: 20px;
  background-color: #fff;
}

.layout-5-column.benefits .header-wrapper {
  height: 105px;
  background-color: #5194ad;
}

.layout-5-column.benefits .header-wrapper h4 {
  position: relative;
  top: 50%;
  margin: auto 0;
  padding: 20px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  color: #fff;
  text-align: center;
  vertical-align: middle;
}

.layout-5-column.benefits .header-wrapper img {
  display: block;
  margin: 40px auto;
}

.layout-5-column.benefits .header-wrapper p {
  padding: 20px;
  border-top: 1px solid #dddad7;
}

.updated .layout-5-column {
  padding: 15px 0;
}

@media screen and (min-width: 641px) {
  .updated .layout-5-column {
    padding: 30px 0;
  }
}

.updated .layout-5-column.tall {
  padding: 30px 0;
}

@media screen and (min-width: 641px) {
  .updated .layout-5-column.tall {
    padding: 60px 0;
  }
}

.updated .layout-5-column.tall-top {
  padding-top: 30px;
}

@media screen and (min-width: 641px) {
  .updated .layout-5-column.tall-top {
    padding-top: 60px;
  }
}

.updated .layout-5-column.tall-bottom {
  padding-bottom: 30px;
}

@media screen and (min-width: 641px) {
  .updated .layout-5-column.tall-bottom {
    padding-bottom: 60px;
  }
}

.layout-6-column .after a {
  color: inherit;
  font-weight: inherit;
}

.layout-6-column .after a:hover {
  text-decoration: underline;
}

.layout-6-column.equipment-preview {
  padding: 10px 0;
  text-align: center;
}

.layout-6-column.equipment-preview h4 {
  padding-bottom: 20px;
  font-weight: 700;
}

.layout-6-column.equipment-preview img {
  display: block;
  margin: 0 auto;
}

.layout-6-column.equipment-preview .flex-container {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  min-height: 90px;
}

.layout-6-column.equipment-preview .flex-container,
.layout-6-column.equipment-preview .flex-container-img {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.layout-6-column.equipment-preview .flex-container-img {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  min-height: 150px;
}

@media screen and (-ms-high-contrast: none) {
  .layout-6-column.equipment-preview .flex-container-img {
    display: block;
  }
}

.layout-6-column.equipment-preview h6 {
  margin: 15px 0 5px;
  color: #938e88;
  font-weight: 100;
}

.layout-6-column.equipment-preview p {
  padding-top: 5px;
  padding-bottom: 10px;
  font-weight: 100;
}

.layout-6-column.equipment-preview .link-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.layout-6-column.equipment-preview .link-container a {
  position: relative;
  padding-bottom: 5px;
  color: #6a7e91;
  font-weight: 500;
}

.layout-6-column.equipment-preview .link-container a:after {
  position: absolute;
  bottom: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  content: "";
}

.layout-6-column.equipment-preview .link-container a:hover:after {
  width: 100%;
  border-bottom: 4px solid #6a7e91;
  -webkit-animation: 0.2s a ease-in;
  animation: 0.2s a ease-in;
}

@media screen and (min-width: 0px) and (max-width: 640px) {
  .layout-6-column.equipment-preview .columns {
    margin-bottom: 15px;
  }
}

.updated .layout-6-column {
  padding: 15px 0;
}

@media screen and (min-width: 641px) {
  .updated .layout-6-column {
    padding: 30px 0;
  }
}

.updated .layout-6-column.tall {
  padding: 30px 0;
}

@media screen and (min-width: 641px) {
  .updated .layout-6-column.tall {
    padding: 60px 0;
  }
}

.updated .layout-6-column.tall-top {
  padding-top: 30px;
}

@media screen and (min-width: 641px) {
  .updated .layout-6-column.tall-top {
    padding-top: 60px;
  }
}

.updated .layout-6-column.tall-bottom {
  padding-bottom: 30px;
}

@media screen and (min-width: 641px) {
  .updated .layout-6-column.tall-bottom {
    padding-bottom: 60px;
  }
}

.layout-basic-content {
  padding: 60px 0;
}

.layout-basic-content p {
  max-width: 900px;
  font-size: 13px;
}

.layout-basic-content.back-to-top-btn {
  position: fixed;
  bottom: 50px;
  left: 50%;
  width: 200px;
  margin-left: -100px;
  z-index: 1000;
}

.layout-basic-content.back-to-top-btn a.btn.orange {
  width: 100%;
  background-color: #ff8200;
}

.layout-basic-content.back-to-top-btn a.btn.orange img {
  display: inline;
}

.layout-basic-content.collapse-bottom {
  padding-bottom: 0;
}

.layout-basic-content.full p {
  max-width: 100%;
}

footer .layout-basic-content {
  padding: 30px 0;
}

.layout-basic-content.form-response {
  display: none;
  position: relative;
  padding: 30px 0;
  background-color: #ff8200;
}

.layout-basic-content.form-response h2 {
  max-width: 100%;
  font-weight: 400;
}

.layout-basic-content.form-response h2 strong {
  display: block;
}

.layout-basic-content.form-response:before {
  display: block;
  position: absolute;
  top: -50px;
  left: 50%;
  width: 0;
  height: 0;
  margin-left: -25px;
  border-top-width: 0;
  border: 25px solid transparent;
  border-bottom-color: #ff8200;
  content: "";
}

.updated .layout-basic-content {
  padding: 15px 0;
}

@media screen and (min-width: 641px) {
  .updated .layout-basic-content {
    padding: 30px 0;
  }
}

.updated .layout-basic-content.tall {
  padding: 30px 0;
}

@media screen and (min-width: 641px) {
  .updated .layout-basic-content.tall {
    padding: 60px 0;
  }
}

.updated .layout-basic-content.tall-top {
  padding-top: 30px;
}

@media screen and (min-width: 641px) {
  .updated .layout-basic-content.tall-top {
    padding-top: 60px;
  }
}

.updated .layout-basic-content.tall-bottom {
  padding-bottom: 30px;
}

@media screen and (min-width: 641px) {
  .updated .layout-basic-content.tall-bottom {
    padding-bottom: 60px;
  }
}

.updated .layout-basic-content p {
  font-size: 16px;
  line-height: 24px;
  max-width: 100%;
}

.layout-call-to-action {
  padding: 10px 0;
  background-color: #5daac7;
}

@media screen and (min-width: 641px) {
  .layout-call-to-action {
    padding: 15px 0;
  }
}

@media screen and (min-width: 1025px) {
  .layout-call-to-action {
    padding: 25px 0;
  }
}

.layout-call-to-action a,
.layout-call-to-action h2,
.layout-call-to-action span {
  color: #fff;
}

.layout-call-to-action h2,
.layout-call-to-action span {
  font-size: 18px;
}

@media screen and (min-width: 641px) {
  .layout-call-to-action h2,
  .layout-call-to-action span {
    font-size: 22px;
  }
}

.layout-call-to-action h2 {
  padding-bottom: 0;
  font-weight: 300;
}

@media screen and (max-width: 1024px) {
  .layout-call-to-action h2 span {
    display: block;
  }
}

.layout-call-to-action a span {
  text-decoration: none;
}

.layout-call-to-action strong {
  display: block;
}

@media screen and (min-width: 1025px) {
  .layout-call-to-action strong {
    display: inline-block;
    margin-left: 20px;
    padding: 5px 25px;
    background-color: #457e94;
  }
}

.layout-call-to-action.headline,
.layout-call-to-action.headline h3 {
  color: #fff;
}

.layout-call-to-action.headline.gray {
  background-color: #576877;
}

.layout-call-to-action.headline.blue {
  background-color: #3c50ad;
}

.layout-call-to-action.before-triangle:before {
  display: block;
  width: 50px;
  height: 50px;
  margin: -35px auto;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  background-color: #ff8200;
  content: "";
}

@media screen and (min-width: 641px) and (max-width: 1024px) {
  .layout-call-to-action.before-triangle:before {
    margin: -40px auto;
  }
}

@media screen and (min-width: 1025px) {
  .layout-call-to-action.before-triangle:before {
    margin: -50px auto;
  }
}

.layout-call-to-action.before-triangle {
  margin-top: 25px;
}

.layout-call-to-action.before-triangle.no-margin-top {
  margin-top: 0;
}

.layout-call-to-action.before-triangle .container {
  padding-top: 25px;
  padding-bottom: 5px;
}

@media screen and (min-width: 641px) {
  .layout-call-to-action.before-triangle .container {
    padding-top: 30px;
    padding-bottom: 0;
  }
}

@media screen and (min-width: 1025px) {
  .layout-call-to-action.before-triangle .container {
    padding-top: 50px;
  }
}

.layout-call-to-action.blue-gray {
  background-color: #6a7e91;
}

@media screen and (min-width: 1025px) {
  .layout-call-to-action.blue-gray strong {
    background-color: #45525e;
  }
}

.layout-call-to-action.orange {
  background-color: #ff8200;
}

@media screen and (min-width: 1025px) {
  .layout-call-to-action.orange strong {
    background-color: #cc6800;
  }
}

.layout-call-to-action.one-orange {
  background-color: #ff8200;
}

@media screen and (min-width: 1025px) {
  .layout-call-to-action.one-orange strong {
    background-color: #ff8200;
  }
}

.layout-call-to-action.gray {
  background-color: #938e88;
}

@media screen and (min-width: 1025px) {
  .layout-call-to-action.gray strong {
    background-color: #5b5d58;
  }
}

.layout-call-to-action.overlay {
  background-image: url("../images/cta-overlay1.png");
  background-repeat: no-repeat;
  background-position: 37% top;
  background-size: cover;
}

@media screen and (min-width: 1025px) {
  .layout-call-to-action.overlay {
    background-position: 0 0;
  }
}

.layout-call-to-action.overlay.orange strong {
  background-color: #e57500;
}

@media screen and (min-width: 641px) {
  .layout-call-to-action.overlay strong {
    display: inline-block;
    margin-top: 10px;
    margin-left: 20px;
    padding: 5px 25px;
    background-color: rgba(36, 163, 131, 0.9);
  }
}

@media screen and (min-width: 641px) {
  .layout-call-to-action.overlay strong.orange {
    background-color: #ff8200;
  }
}

.layout-call-to-action.overlay2 {
  background-image: url("../images/cta-overlay1.png");
  background-repeat: no-repeat;
  background-position: 42% top;
  background-size: cover;
}

@media screen and (min-width: 1025px) {
  .layout-call-to-action.overlay2 {
    background-position: 0 0;
  }
}

.layout-call-to-action.overlay2 strong {
  background-color: #ff8200;
}

@media screen and (min-width: 641px) {
  .layout-call-to-action.inline {
    height: 180px;
    padding-top: 50px;
  }
}

.layout-call-to-action.inline strong {
  display: inline-block;
  margin: 0;
  padding: 5px 0;
  font-weight: 200;
}

@media screen and (min-width: 641px) {
  .layout-call-to-action.inline strong {
    margin: 10px auto;
    margin-left: 30px;
    padding: 10px 20px;
    background-color: #e57500;
  }
}

.layout-call-to-action.inline strong strong {
  margin: 0;
  padding: 0;
  font-weight: 700;
}

.layout-call-to-action.inline .bold {
  font-weight: 700;
}

.layout-call-to-action.v2-packages .mobile-phone {
  font-weight: 100;
}

.layout-call-to-action.v2-packages strong {
  display: inline;
  margin: 0;
  padding: 0;
}

.smart-monitoring .layout-call-to-action.overlay {
  /* background-image: url("../images/smartmonitoring/overlay-mobile.png"); */
  background-size: cover;
}

@media screen and (min-width: 641px) {
  /*   .smart-monitoring .layout-call-to-action.overlay {
    background-image: url("../images/smartmonitoring/overlay.png");
  } */
}

.smart-monitoring .layout-call-to-action.overlay strong {
  background-color: #e57500;
}

@media screen and (min-width: 0px) and (max-width: 640px) {
  .smart-monitoring .layout-call-to-action.overlay strong {
    margin-top: 15px;
    padding: 8px 25px;
    font-weight: 100;
  }
  .smart-monitoring .layout-call-to-action.overlay strong strong {
    margin-top: 0;
    padding: 0;
  }
}

.layout-feature {
  padding: 30px 0;
}

@media screen and (min-width: 641px) {
  .layout-feature {
    padding: 60px 0;
  }
}

.layout-feature p {
  margin-bottom: 20px;
}

.layout-feature a:hover {
  color: #e57500;
}

.layout-feature ul {
  display: inline-block;
  width: 39%;
}

.layout-feature ul:first-of-type {
  margin-right: 20px;
}

@media screen and (min-width: 641px) {
  .layout-feature ul:first-of-type {
    text-align: right;
    list-style-type: none;
  }
}

@media screen and (min-width: 641px) {
  .layout-feature ul:first-of-type li {
    position: relative;
    padding-right: 0.6em;
  }
}

@media screen and (min-width: 641px) {
  .layout-feature ul:first-of-type li:after {
    position: absolute;
    top: -6px;
    right: 0;
    width: 0;
    height: 0;
    font-size: 26px;
    content: "•";
  }
}

.layout-feature ul:last-of-type {
  margin-left: 20px;
  text-align: left;
}

.layout-feature.hero {
  margin-top: 10px;
  padding: 75px 0;
  background-image: url("../images/home-page-new.png");
}

@media screen and (min-width: 0px) and (max-width: 640px) {
  .layout-feature.hero {
    padding: 20px 0;
  }
}

.layout-feature.hero h1,
.layout-feature.hero h2,
.layout-feature.hero h3,
.layout-feature.hero h4,
.layout-feature.hero h5,
.layout-feature.hero h6,
.layout-feature.hero p,
.layout-feature.hero span {
  color: #fff;
}

.layout-feature.hero h1 {
  font-size: 50px;
  font-weight: 600;
}

@media screen and (min-width: 0px) and (max-width: 640px) {
  .layout-feature.hero h1 {
    font-size: 32px;
  }
}

@media screen and (min-width: 641px) and (max-width: 1024px) {
  .layout-feature.hero h1 {
    font-size: 42px;
  }
}

.layout-feature.hero h1 span {
  display: block;
  font-size: 20px;
  font-weight: 100;
  line-height: 1.5;
}

@media screen and (min-width: 641px) {
  .layout-feature.hero h1 span {
    font-size: 34px;
  }
}

.layout-feature.hero h2 {
  padding-top: 15px;
  font-size: 20px;
  font-weight: 100;
  line-height: 1.5;
}

@media screen and (min-width: 641px) {
  .layout-feature.hero h2 {
    font-size: 34px;
  }
}

.updated .layout-feature.hero h2 {
  font-size: 24px;
  line-height: 30px;
}

@media screen and (min-width: 641px) and (max-width: 1024px) {
  .updated .layout-feature.hero h2 {
    font-size: 30px;
    line-height: 36px;
  }
}

@media screen and (min-width: 1025px) {
  .updated .layout-feature.hero h2 {
    font-size: 36px;
    line-height: 40px;
  }
}

.layout-feature.hero h4 {
  font-weight: 100;
}

.layout-feature.hero p {
  width: 90%;
  max-width: 500px;
  margin: 40px auto;
  padding: 10px;
  background-color: rgba(255, 130, 0, 0.9);
  font-size: 34px;
  font-weight: 200;
}

@media screen and (min-width: 0px) and (max-width: 640px) {
  .layout-feature.hero p {
    margin-top: 15px;
    margin-bottom: 15px;
    font-size: 18px;
  }
}

.layout-feature.hero p span {
  font-weight: 600;
}

.layout-feature.hero p.light-blue {
  background-color: rgba(97, 166, 193, 0.9);
}

.layout-feature.hero p.light-bluegray {
  background-color: rgba(106, 126, 145, 0.9);
}

.layout-feature.hero.ppc {
  padding: 50px 0 20px;
}

.updated .layout-feature.hero {
  padding: 60px 0;
}

@media screen and (min-width: 641px) and (max-width: 1024px) {
  .updated .layout-feature.hero {
    padding: 90px 0;
  }
}

@media screen and (min-width: 1025px) {
  .updated .layout-feature.hero {
    padding: 150px 0;
  }
}

.city-geos .layout-feature.hero {
  padding: 89px 0;
}

@media screen and (min-width: 641px) {
  .city-geos .layout-feature.hero {
    padding: 235px 0;
    font-size: 50px;
  }
}

.city-geos .layout-feature.hero h1 {
  font-size: 28px;
}

@media screen and (min-width: 641px) {
  .city-geos .layout-feature.hero h1 {
    font-size: 50px;
  }
}

.layout-feature .icon-feature {
  margin-top: 50px;
}

.layout-feature .icon-feature h4 {
  margin: 20px 0;
}

.layout-feature .icon-feature .icon {
  display: inline-block;
  margin: 0 20px;
}

.layout-feature .icon-feature p {
  width: 100px;
  margin: 0 auto 20px;
  text-align: center;
}

.updated .layout-feature {
  padding: 15px 0;
}

@media screen and (min-width: 641px) {
  .updated .layout-feature {
    padding: 30px 0;
  }
}

.updated .layout-feature.tall {
  padding: 30px 0;
}

@media screen and (min-width: 641px) {
  .updated .layout-feature.tall {
    padding: 60px 0;
  }
}

.updated .layout-feature.tall-top {
  padding-top: 30px;
}

@media screen and (min-width: 641px) {
  .updated .layout-feature.tall-top {
    padding-top: 60px;
  }
}

.updated .layout-feature.tall-bottom {
  padding-bottom: 30px;
}

@media screen and (min-width: 641px) {
  .updated .layout-feature.tall-bottom {
    padding-bottom: 60px;
  }
}

.updated .layout-feature p {
  background-color: #ff8200;
}

.updated .layout-feature p.error {
  display: none;
  margin: 0 auto;
  padding: 10px;
  background-color: #d53a25;
  font-size: 14px;
}

.layout-feature.city-geos-video-feature {
  background-color: #f5f5f5;
}

.layout-feature.city-geos-video-feature h2 {
  font-size: 24px;
  font-weight: 700;
}

@media screen and (min-width: 641px) {
  .layout-feature.city-geos-video-feature h2 {
    font-size: 36px;
  }
}

.layout-feature.city-geos-video-feature p {
  background-color: inherit;
}

.layout-feature.city-geos-video-feature a {
  color: #5b5d58;
}

.layout-feature.city-geos-video-feature iframe {
  width: 290px;
  padding-bottom: 25px;
  border: 0;
}

@media screen and (min-width: 641px) {
  .layout-feature.city-geos-video-feature iframe {
    width: 560px;
    height: 315px;
  }
}

.layout-feature.packages-feature {
  padding-bottom: 5px;
}

@media screen and (min-width: 641px) and (max-width: 1024px) {
  .layout-feature.packages-feature.padding-adjust {
    padding-top: 5px;
  }
}

.layout-feature.video-feature h2 {
  font-size: 24px;
  font-weight: 700;
}

@media screen and (min-width: 641px) {
  .layout-feature.video-feature h2 {
    font-size: 30px;
  }
}

@media screen and (min-width: 1025px) {
  .layout-feature.video-feature h2 {
    font-size: 36px;
  }
}

.layout-feature.video-feature .video-container {
  max-width: 260px;
  margin: 0 auto;
}

@media screen and (min-width: 641px) {
  .layout-feature.video-feature .video-container {
    max-width: 569px;
    margin: 0 auto;
  }
}

.new-style .layout-feature .hero p {
  max-width: 300px;
  font-size: 22px;
}

.layout-geo-finder-bar {
  position: relative;
  padding: 50px 1.5% 25px;
}

.layout-geo-finder-bar:after {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  width: 95%;
  height: 1px;
  margin: 0 auto;
  background-color: #938e88;
  content: "";
}

.layout-geo-finder-bar label,
.layout-geo-finder-bar select,
.layout-geo-finder-bar span {
  height: 45px;
}

@media screen and (min-width: 0px) and (max-width: 640px) {
  .layout-geo-finder-bar section form {
    margin-top: 30px;
  }
}

.layout-geo-finder-bar section form label {
  padding-right: 30px;
  font-size: 22px;
  font-weight: 100;
  cursor: default;
}

@media screen and (max-width: 1024px) {
  .layout-geo-finder-bar section form label {
    padding-right: 0;
    font-size: 18px;
  }
}

.layout-geo-finder-bar section.medium-8 {
  padding-left: 0;
}

.layout-geo-finder-bar select {
  position: relative;
  outline: none;
  background-color: #fff;
}

.layout-geo-finder-bar span {
  display: block;
  position: absolute;
  right: 0;
  width: 45px;
  background-color: #ff8200;
  content: "";
  z-index: 10;
  pointer-events: none;
}

@media screen and (max-width: 1024px) {
  .layout-geo-finder-bar span {
    right: 10px;
    bottom: 0;
  }
}

.layout-geo-finder-bar span svg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 20px;
  height: 20px;
  margin: auto;
  fill: #fff;
}

.layout-top-bar {
  background-color: #5b5d58;
}

.layout-top-bar a {
  margin: 5px 0;
  padding: 0 10px;
  color: #fff;
  font-size: 12px;
}

.layout-top-bar a:hover {
  color: #ff8200;
}

.layout-top-bar a:first-of-type {
  border-right: 1px solid #fff;
  content: "";
}

.layout-top-bar .container .right-items {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.layout-hero.overlay {
  padding: 70px 0;
}

@media screen and (min-width: 0px) and (max-width: 640px) {
  .layout-hero.overlay {
    padding: 30px 0;
    background-color: #5b5d58;
  }
}

.layout-hero.overlay h6 {
  margin-bottom: 0;
  text-transform: uppercase;
}

.layout-hero.overlay h6.normal-type {
  text-transform: none;
}

.layout-hero.overlay.overlay-box {
  padding: 0;
}

.layout-hero h2,
.layout-hero p {
  padding-bottom: 20px;
  font-weight: 100;
}

.layout-hero li,
.layout-hero p {
  font-size: 13px;
}

.layout-hero ol,
.layout-hero ul {
  padding-bottom: 30px;
}

.layout-hero ol li,
.layout-hero ol p,
.layout-hero ul li,
.layout-hero ul p {
  padding: 7.5px 0;
  font-weight: 600;
}

.layout-hero ol li:first-of-type,
.layout-hero ul li:first-of-type {
  padding-top: 0;
}

.layout-hero ol li:last-of-type,
.layout-hero ul li:last-of-type {
  padding-bottom: 0;
}

.layout-hero ol.lighter li,
.layout-hero ol.lighter p,
.layout-hero ul.lighter li,
.layout-hero ul.lighter p {
  font-weight: 100;
}

.layout-hero ol.app-icons,
.layout-hero ul.app-icons {
  list-style: none;
}

.layout-hero.orange-disclaimer .disclaimer {
  color: #ff8200;
  font-size: 18px;
  line-height: 1.5;
}

.layout-hero.orange-disclaimer .disclaimer strong {
  display: block;
}

.layout-hero.big-disclaimer .disclaimer {
  font-size: 18px;
}

.layout-hero.overlay-box {
  padding: 0;
  background-image: none;
}

@media screen and (min-width: 641px) and (max-width: 1024px) {
  .layout-hero.overlay-box {
    background-position: 31% bottom;
  }
}

@media screen and (min-width: 641px) {
  .layout-hero.overlay-box {
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;
  }
}

.layout-hero.overlay-box h4 {
  color: #fff;
  font-size: 20px;
  font-weight: 700;
}

@media screen and (min-width: 641px) {
  /*
  .equipment-piece .layout-hero.overlay-box {
    background-image: url("../images/keyless-section2.png");
  }
*/
}

@media screen and (min-width: 641px) {
  /*
  .availability .layout-hero.overlay-box {
    background-image: url("../images/availability-section2.jpg");
  }
  */
}

.layout-hero.overlay-box .content-wrapper {
  padding-right: 0;
  padding-left: 0;
}

@media screen and (min-width: 641px) {
  .layout-hero.overlay-box .content-wrapper {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

@media screen and (min-width: 641px) and (max-width: 1024px) {
  .layout-hero.overlay-box .content {
    padding-top: 90px;
    padding-bottom: 90px;
  }
}

@media screen and (min-width: 1025px) {
  .layout-hero.overlay-box .content {
    padding-top: 120px;
    padding-bottom: 120px;
  }
}

.layout-hero.overlay-box .opaque {
  position: relative;
  padding: 30px 15px;
  background-color: hsla(0, 0%, 100%, 0.3);
  color: #fff;
  z-index: 0;
}

@media screen and (min-width: 641px) {
  .layout-hero.overlay-box .opaque {
    -webkit-box-shadow: 15px 15px 40px rgba(0, 0, 0, 0.5);
    box-shadow: 15px 15px 40px rgba(0, 0, 0, 0.5);
  }
}

@media screen and (min-width: 1025px) {
  .layout-hero.overlay-box .opaque {
    padding: 30px;
  }
}

.layout-hero.overlay-box .opaque h2 {
  margin: 0;
  margin-bottom: 5px;
  padding: 0;
  color: #fff;
}

.layout-hero.overlay-box .opaque p {
  font-size: 16px;
  line-height: 24px;
  margin: 0;
  margin-top: 10px;
  padding: 0;
  color: #fff;
  font-weight: 400;
}

.layout-hero.overlay-box .opaque p a:hover {
  color: inherit;
}

.layout-hero.overlay-box .opaque ul {
  margin: 0;
  margin-top: 10px;
  margin-left: 25px;
  padding: 0;
}

.layout-hero.overlay-box .opaque ul li {
  font-size: 16px;
  line-height: 24px;
  margin: 0;
  padding: 0;
  padding-left: 10px;
  color: #fff;
  font-weight: 400;
}

.layout-hero.overlay-box .opaque .bg-box {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.layout-hero.overlay-box .opaque.blue .bg-box {
  background-color: rgba(93, 170, 199, 0.8);
  background-blend-mode: multiply;
}

.layout-hero.overlay-box .opaque.orange {
  background-color: rgba(255, 130, 0, 0.78);
  background-blend-mode: multiply;
}

.layout-hero.overlay-box .opaque.light-orange {
  background-color: #ff8200;
}

.layout-hero.overlay-box .opaque.blue-gray {
  background-color: rgba(106, 126, 145, 0.78);
  background-blend-mode: multiply;
}

.updated .layout-hero h2 {
  font-weight: 700;
}

.layout-hero.hero2-home section h2 {
  font-size: 36px;
  font-weight: 700;
}

.layout-hero.hero2-home .opaque {
  -webkit-box-shadow: 15px 15px 40px rgba(0, 0, 0, 0.5);
  box-shadow: 15px 15px 40px rgba(0, 0, 0, 0.5);
}

.layout-hero.city-geo-hero2 .content h2 {
  font-weight: 700;
}

.layout-hero.city-geo-hero2 .content p {
  font-weight: 100;
}

.layout-hero.city-geo-hero2 .layout-legal p {
  font-size: 10px;
}

.layout-hero.hero-testimonial {
  padding: 120px 0;
}

@media screen and (min-width: 0px) and (max-width: 640px) {
  .layout-hero.hero-testimonial .container {
    padding-right: 30px;
    padding-left: 30px;
  }
}

.layout-hero.hero-testimonial.border-right .content-wrapper {
  border-right-width: 3px;
  border-right-style: solid;
  border-right-color: #fff;
}

.layout-hero.hero-testimonial.border-left .content-wrapper {
  border-left-width: 3px;
  border-left-style: solid;
  border-left-color: #fff;
}

.layout-hero.hero-testimonial h2 {
  padding-bottom: 25px;
  color: #fff;
  font-size: 24px;
  font-weight: 700;
}

@media screen and (min-width: 641px) and (max-width: 1024px) {
  .layout-hero.hero-testimonial h2 {
    font-size: 30px;
  }
}

@media screen and (min-width: 1025px) {
  .layout-hero.hero-testimonial h2 {
    font-size: 36px;
  }
}

.layout-hero.hero-testimonial p {
  padding-bottom: 0;
  color: #fff;
  font-size: 16px;
}

.layout-hero.packages-choice-hero .content section {
  -webkit-box-shadow: 15px 15px 40px rgba(0, 0, 0, 0.3);
  box-shadow: 15px 15px 40px rgba(0, 0, 0, 0.3);
}

@media screen and (min-width: 641px) {
  .layout-hero.packages-choice-hero .content section {
    -webkit-box-shadow: none;
    box-shadow: none;
  }
}

.layout-hero.packages-choice-hero .content section h2 {
  font-size: 36px;
  font-weight: 700;
}

.layout-hero.packages-choice-hero .content section p {
  font-size: 20px;
  line-height: 26px;
}

.layout-hero.packages-choice-hero .content section .light {
  font-weight: 100;
}

.layout-hero.packages-choice-hero .content section .conjunction {
  font-size: 36px;
  font-weight: 700;
  line-height: 42px;
}

.new-style .layout-hero-1 ul li {
  font-weight: 100;
  line-height: 20px;
}

.layout-form form {
  margin-bottom: 10px;
}

.layout-form h6 {
  margin-top: 15px;
  padding-bottom: 5px;
}

.layout-form li {
  padding: 7.5px 0;
}

.layout-form label {
  color: #fff;
  cursor: default;
}

.layout-form label.error {
  margin-bottom: 10px;
  padding: 2.5px 10px;
  border: 1px solid #ccc;
  border-top: 0;
  background-color: #bc3320;
  font-weight: 100;
}

.layout-form input {
  margin-bottom: 0;
}

.layout-form input[type="text"] {
  background-color: #fff;
}

.layout-form button {
  margin-top: 10px;
  margin-bottom: 0;
  padding: 10px 0;
  border: 2px solid #fff;
  background-color: transparent;
  text-transform: uppercase;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.layout-form button:hover {
  background-color: #e57500;
}

.layout-form .container {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}

.layout-form .input-container {
  margin-bottom: 25px;
}

.layout-form .input-container.legal-container {
  margin-bottom: 10px;
}

.layout-form .input-container:last-of-type {
  margin-bottom: 0;
}

.layout-form .input-container.inline-group .input-group {
  display: -mx-flexbox;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

@media (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .layout-form .input-container.inline-group .input-group {
    min-width: 100px;
  }
}

.layout-form .input-container.inline-group .input-container {
  -ms-flex: 1;
}

.layout-form .inline-group {
  margin-bottom: 20px;
}

.layout-form .group-label {
  padding-bottom: 10px;
  color: #fff;
}

.layout-form .disclaimer a:hover,
.layout-form li a:hover {
  text-decoration: underline;
}

.form-hero .layout-form input [type="submit"] {
  padding: 20px;
  border: 0;
  background-color: #ff8200;
}

.form-hero .layout-form form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.form-hero .layout-form form h2 {
  font-weight: 500;
  text-align: center;
}

.form-hero .layout-form form p:first-of-type {
  padding: 0 20px 20px;
}

.form-hero .layout-form form .half-width {
  width: 50%;
}

.form-hero .layout-form form .half-width:nth-of-type(odd) {
  padding-right: 10px;
}

.form-hero .layout-form form .half-width:nth-of-type(2n) {
  padding-left: 10px;
}

.form-hero .layout-form form .full-width {
  width: 100%;
}

.faq .layout-form ul {
  padding-left: 0;
}

.faq .layout-form ul li {
  position: relative;
  padding: 10px 0 10px 20px;
  list-style: none;
}

@media screen and (min-width: 641px) {
  .faq .layout-form ul li {
    padding-top: 15px;
    padding-bottom: 15px;
  }
}

.faq .layout-form ul li:before {
  position: absolute;
  top: 8px;
  left: 0;
  color: #ff8200;
  font-size: 24px;
  content: "+";
}

@media screen and (min-width: 0px) and (max-width: 640px) {
  .faq .layout-form ul li:before {
    top: 3px;
  }
}

.faq .layout-form ul a {
  color: #5b5d58;
  font-weight: 600;
}

.faq .layout-form ul a:hover {
  text-decoration: underline;
}

.faq .layout-form h1 {
  font-size: 34px;
}

.faq .layout-form h4,
.faq .layout-form p {
  margin-top: 10px;
}

.faq-answer .layout-form h1 {
  font-size: 34px;
}

.faq-answer .layout-form h4,
.faq-answer .layout-form h5,
.faq-answer .layout-form li,
.faq-answer .layout-form p {
  margin-top: 10px;
}

.faq-answer .layout-form h5 {
  margin-bottom: -10px;
}

.faq-answer .layout-form ul {
  padding-left: 0;
  list-style: inside;
}

.layout-form .zip-form {
  display: table;
  max-width: 290px;
  margin: 0 auto;
  margin-top: 20px;
}

@media screen and (min-width: 641px) {
  .layout-form .zip-form {
    max-width: 382px;
  }
  .layout-form .zip-form input {
    max-width: 265px;
    font-size: 18px;
  }
}

.layout-form .zip-form input {
  display: inline-block;
  max-width: 175px;
  height: 50px;
  padding-left: 15px;
  border: 0;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  font-size: 16px;
}

.layout-form .zip-form .submit {
  position: relative;
  left: 0;
  height: 50px;
  margin-top: 0;
  padding: 0 25px;
  border: 0;
  border-radius: 0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  background-color: #ff8200;
  color: #fff;
}

.layout-form .zip-form .input-container {
  display: block;
  float: left;
}

@media screen and (min-width: 641px) {
  .layout-form .new-layout .input-container {
    width: 50%;
    padding-right: 2%;
    float: left;
  }
}

.layout-form .new-layout .input-container.full-width,
.layout-form .new-layout .input-container.inline-group,
.layout-form .new-layout .input-container.legal {
  width: 100%;
}

.layout-form .new-layout .input-container.legal {
  margin-bottom: 5px;
}

.layout-form .new-layout .input-container.inline-group {
  display: block;
}

.layout-form .new-layout .input-container.inline-group .group-label,
.layout-form .new-layout .input-container.inline-group .input-container,
.layout-form .new-layout .input-container.inline-group .input-group {
  display: block;
  float: left;
}

.layout-form .new-layout .input-container.inline-group .group-label {
  width: 50%;
}

.layout-form .new-layout .input-container.inline-group .input-group {
  padding: 0;
  padding-left: 1%;
}

.layout-form .new-layout .input-container.inline-group .input-container {
  margin-right: 0;
}

.layout-form .new-layout .input-container ::-webkit-input-placeholder {
  color: #c3c1be;
}

.layout-form .new-layout > .input-container:first-child {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  visibility: hidden;
}

.layout-form .new-layout label {
  font-size: 16px;
  font-weight: 400;
}

.layout-form .new-layout label.required-field:before {
  color: #fff;
  font-size: 1.1rem;
}

.layout-form .new-layout .control__indicator {
  border-width: 0;
  background-color: #fff;
}

.layout-form .new-layout button {
  border: 0;
  border-radius: 5px;
  background-color: #ff8200;
}

.lightest-blue .layout-form .group-label,
.lightest-blue .layout-form label,
.lightest-blue .layout-form label.required-field:before {
  color: #5b5d58;
}

.lightest-blue .layout-form input {
  border: 0;
}

.updated .layout-form .thank-you-text {
  padding: 50px 30px;
  background-color: rgba(93, 170, 199, 0.15);
  text-align: center;
}

.updated .layout-form .thank-you-text span {
  display: block;
  padding-bottom: 15px;
  font-size: 24px;
  font-weight: 700;
  line-height: 30px;
}

@media screen and (min-width: 641px) and (max-width: 1024px) {
  .updated .layout-form .thank-you-text span {
    font-size: 30px;
    line-height: 36px;
  }
}

@media screen and (min-width: 1025px) {
  .updated .layout-form .thank-you-text span {
    font-size: 36px;
    line-height: 40px;
  }
}

.layout-form.new-lead-form-hero {
  background-color: #fff;
}

@media screen and (min-width: 1025px) {
  .layout-form.new-lead-form-hero {
    width: 350px;
    margin: 0 auto;
  }
}

.layout-form.new-lead-form-hero .container {
  margin-bottom: 40px;
  padding: 7px 15px;
  -webkit-box-shadow: 15px 15px 40px 0 rgba(0, 0, 0, 0.5);
  box-shadow: 15px 15px 40px 0 rgba(0, 0, 0, 0.5);
}

.layout-form.new-lead-form-hero .container .before * {
  color: #e57500;
  font-size: 24px;
  font-weight: 700;
}

@media screen and (min-width: 641px) and (max-width: 1024px) {
  .layout-form.new-lead-form-hero .container .before * {
    font-size: 30px;
  }
}

@media screen and (min-width: 1025px) {
  .layout-form.new-lead-form-hero .container .before * {
    font-size: 36px;
  }
}

.layout-form.new-lead-form-hero .container .text-decoration-underline {
  text-decoration: underline;
}

.layout-form.new-lead-form-hero .input-container {
  margin-bottom: 15px;
}

.layout-form.new-lead-form-hero .input-container .group-label,
.layout-form.new-lead-form-hero .input-container label {
  color: #5b5d58;
  font-size: 16px;
}

.layout-form.new-lead-form-hero .input-container label.required-field:before {
  display: none;
}

.layout-form.new-lead-form-hero
  .input-container
  input::-webkit-input-placeholder {
  color: #9c9c9c;
}

.layout-form.new-lead-form-hero .input-container input:-ms-input-placeholder {
  color: #9c9c9c;
}

.layout-form.new-lead-form-hero .input-container input::placeholder {
  color: #9c9c9c;
}

.layout-form.new-lead-form-hero .input-container button {
  border-radius: 5px;
  background-color: #e57500;
  color: #fff;
}

.layout-form.new-lead-form-hero .inline-group {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  margin-top: 15px;
  margin-bottom: 15px;
}

@media screen and (min-width: 1025px) {
  .layout-form.new-lead-form-hero .inline-group {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
}

.layout-form.new-lead-form-hero .inline-group .input-group {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  padding: 1px;
}

.layout-form.new-lead-form-hero .inline-group > .input-group:first-of-type {
  margin-left: 15px;
}

.layout-form.new-lead-form-hero .legal {
  margin-bottom: 0;
}

.layout-form.new-lead-form-hero .legal .layout-legal {
  width: 100%;
}

.layout-form.new-lead-form-hero .legal p {
  margin-bottom: 10px;
  font-size: 10px;
  font-weight: 400;
  line-height: 16px;
}

.layout-form.new-lead-form-hero .control input:checked ~ .control__indicator {
  background-color: #5b5d58;
}

.layout-form.new-lead-form-hero .display-in-row {
  display: inline-block;
  width: 47%;
}

@media screen and (min-width: 641px) and (max-width: 1024px) {
  .layout-form.new-lead-form-hero .display-in-row {
    width: 21.5%;
  }
}

@media screen and (min-width: 0px) and (max-width: 640px) {
  .layout-form.new-lead-form-hero .display-in-row:nth-of-type(2n) {
    margin-right: 4%;
  }
}

@media screen and (min-width: 1025px) {
  .layout-form.new-lead-form-hero .display-in-row:nth-of-type(2n) {
    margin-right: 4%;
  }
}

@media screen and (min-width: 641px) and (max-width: 1024px) {
  .layout-form.new-lead-form-hero .container-margin {
    margin-right: 3.5%;
  }
}

.layout-form.new-lead-form-hero .hidden-field-container {
  margin: 0;
}

.layout-form.new-lead-form-hero .submit-container {
  margin-bottom: 0;
}

.layout-form.new-lead-form-hero .spin {
  padding-top: 210px;
  padding-bottom: 210px;
}

.layout-form.new-lead-form-hero .form-thank-you {
  margin-top: 125px;
  padding: 20px;
  text-align: center;
}

.layout-form.new-lead-form-hero .form-thank-you h3 {
  color: #e57500;
  font-size: 36px;
  font-weight: 700;
  line-height: 42px;
}

.layout-form.new-lead-form-hero .form-thank-you p {
  font-size: 16px;
  line-height: 24px;
}

.layout-form.new-lead-form-hero .form-thank-you.error h3 {
  padding-top: 0;
  font-size: 24px;
}

.layout-form.new-lead-form-hero .form-thank-you.error p {
  margin-bottom: 5px;
}

.layout-form.new-lead-form-hero .parsley-required {
  color: #ef4129;
}

.layout-form .spin {
  width: 200px;
  margin: 10px auto;
  text-align: center;
}

.layout-form .spin > div {
  display: inline-block;
  width: 30px;
  height: 30px;
  margin: 0 5px;
  border-radius: 100%;
  background-color: #e57500;
  -webkit-animation: b 1.4s infinite ease-in-out both;
  animation: b 1.4s infinite ease-in-out both;
}

.layout-form .spin .b1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.layout-form .spin .b2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes b {
  0%,
  80%,
  to {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes b {
  0%,
  80%,
  to {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.layout-navigation {
  color: #424340;
}

.layout-navigation > label .icon-dropdown {
  display: none;
}

.layout-navigation svg {
  width: 10px;
  height: 10px;
  text-align: right;
  fill: #ff8200;
}

@media screen and (max-width: 1024px) {
  .layout-navigation svg {
    display: none;
  }
}

.layout-navigation .responsive-menu-toggle-image {
  display: block;
  width: 24px;
  margin-left: auto;
  margin-top: -35px;
}

.layout-navigation .menu {
  background-color: #fff;
  z-index: 999;
}

@media screen and (min-width: 1025px) {
  .layout-navigation .menu {
    position: relative;
    top: 14px;
    height: 34px;
    background-color: transparent;
  }
}

.layout-navigation .menu > li {
  border-top: 1px solid #dddad7;
}

@media screen and (min-width: 1025px) {
  .layout-navigation .menu > li {
    padding: 0 35px;
    border: 0;
    border-width: 0;
  }
}

@media screen and (max-width: 1024px) {
  .layout-navigation .menu > li:active,
  .layout-navigation .menu > li:focus,
  .layout-navigation .menu > li:hover {
    outline: none;
    background-color: #f5f5f5;
  }
}

.layout-navigation .menu > li:first-of-type a {
  padding-right: 5px;
}

.layout-navigation .menu > li:hover {
  background-color: transparent;
}

.layout-navigation .menu > li a {
  padding-left: 20px;
  color: #5b5d58;
  text-decoration: none;
}

@media screen and (max-width: 1024px) {
  .layout-navigation .menu > li a {
    height: 40px;
    padding: 0;
    padding-left: 10px;
    color: #5b5d58;
    font-size: 16px;
    font-weight: 700;
  }
}

.layout-navigation .menu > li .icon-dropdown {
  right: 10px;
  margin-top: 7.5px;
  padding: 20px;
  background-color: transparent;
  color: #ff8200;
  font-size: 24px;
  line-height: 0;
}

.layout-navigation .menu > li .icon-dropdown:before {
  position: absolute;
  top: 25%;
  right: 10px;
  width: 10px;
  height: 7px;
  background-image: url("../images/nav-icon-down.png");
  background-repeat: no-repeat;
  content: "";
}

@media screen and (min-width: 1025px) {
  .layout-navigation .menu > li.dropdown a {
    padding-left: 0;
  }
}

.layout-navigation .menu li.dropdown,
.layout-navigation .menu li.standard {
  position: relative;
  line-height: 40px;
}

@media screen and (min-width: 1025px) {
  .layout-navigation .menu li.dropdown:after,
  .layout-navigation .menu li.standard:after {
    position: absolute;
    top: 39px;
    left: 0;
    width: 0;
    height: 4px;
    margin-left: 50%;
    padding: 0;
    -webkit-transition: width 0.2s, margin 0.2s;
    -o-transition: width 0.2s, margin 0.2s;
    transition: width 0.2s, margin 0.2s;
    background-color: #ff8200;
    content: "";
  }
}

.layout-navigation .menu li.dropdown:not(.active) .icon-dropdown,
.layout-navigation .menu li.standard:not(.active) .icon-dropdown {
  top: 0;
  right: 10px;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}

.layout-navigation .menu li.dropdown a,
.layout-navigation .menu li.standard a {
  height: 40px;
  padding: 0;
  padding-left: 10px;
  color: #5b5d58;
  font-size: 16px;
  font-weight: 700;
}

.layout-navigation .menu li.dropdown:hover,
.layout-navigation .menu li.standard:hover {
  background-color: #f5f5f5;
}

@media screen and (min-width: 1025px) {
  .layout-navigation .menu li.dropdown:hover,
  .layout-navigation .menu li.standard:hover {
    background-color: transparent;
  }
}

.layout-navigation .menu li.dropdown:hover:after,
.layout-navigation .menu li.standard:hover:after {
  width: 175px;
  margin-left: 0;
}

.layout-navigation .menu li.dropdown .dropdown:after,
.layout-navigation .menu li.standard .dropdown:after {
  display: none;
}

.layout-navigation .menu li.active .icon-dropdown {
  top: -15px;
  right: 0;
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.layout-navigation .menu li ul li {
  padding-left: 0;
}

.layout-navigation .menu li .active .icon-dropdown {
  top: -15px;
  right: 0;
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.layout-navigation .menu li.phone {
  background-color: #ff8200;
  color: #fff;
  font-weight: 200;
  text-align: center;
}

@media screen and (min-width: 1025px) {
  .layout-navigation .menu li.phone {
    display: none;
  }
}

.layout-navigation .menu li.phone a {
  color: #fff;
  font-weight: 200;
}

.layout-navigation .menu li.phone img {
  width: auto;
  margin-top: -4px;
  margin-left: -40px;
  padding-left: 0;
}

.layout-navigation .menu .submenu {
  width: 100%;
  margin: 0 auto;
  border: 0;
  background-color: #fff;
}

@media screen and (min-width: 1025px) {
  .layout-navigation .menu .submenu {
    display: block;
    top: 43px;
    left: 0;
    width: 95%;
    -webkit-transition: visibility 0.2s, opacity 0.1s;
    -o-transition: visibility 0.2s, opacity 0.1s;
    transition: visibility 0.2s, opacity 0.1s;
    -webkit-transition-delay: 0.3s;
    -o-transition-delay: 0.3s;
    transition-delay: 0.3s;
    background-color: #fff;
    -webkit-box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.5);
    box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.5);
    opacity: 0;
    visibility: hidden;
  }
  .layout-navigation .menu .submenu:before {
    position: absolute;
    top: -8px;
    width: 100%;
    height: 8px;
    background-color: #ff8200;
    content: "";
  }
}

.layout-navigation .menu .submenu svg {
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
  fill: #424340;
}

.layout-navigation .menu .submenu img {
  position: relative;
  top: -1px;
  width: auto;
}

@media screen and (max-width: 1024px) {
  .layout-navigation .menu .submenu .submenu a {
    padding-left: 60px;
  }
}

@media screen and (max-width: 1024px) {
  .layout-navigation .menu .submenu li {
    border-top: 1px solid #dddad7;
  }
  .layout-navigation .menu .submenu li:last-child {
    border-width: 1px;
  }
}

@media screen and (min-width: 1025px) {
  .layout-navigation .menu .submenu li {
    padding: 0 15px;
    border-bottom: 1px solid #dddad7;
  }
  .layout-navigation .menu .submenu li:after {
    display: none;
  }
}

.layout-navigation .menu .submenu li:hover {
  background-color: #f5f5f5;
}

.layout-navigation .menu .submenu li a {
  padding-left: 30px;
  color: #424340;
  font-weight: 400;
}

@media screen and (min-width: 1025px) {
  .layout-navigation .menu .submenu li a {
    height: auto;
    padding: 10px 0;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.3em;
  }
}

.layout-navigation .menu .submenu li a:hover {
  color: inherit;
}

@media screen and (min-width: 1025px) {
  .layout-navigation .menu .submenu li .submenu {
    top: 0;
    right: 100%;
    left: auto;
    -webkit-transition: none;
    -o-transition: none;
    transition: none;
    background-color: #fff;
  }
}

@media screen and (min-width: 1025px) {
  .layout-navigation .menu .submenu .dropdown .submenu {
    border-left: 1px solid #dddad7;
  }
  .layout-navigation .menu .submenu .dropdown .submenu:before {
    content: none;
  }
}

.layout-navigation .menu .submenu .dropdown ul {
  background-color: #fff;
}

.layout-navigation .menu .submenu .dropdown a {
  color: #424340;
}

.layout-navigation .menu .submenu:before {
  display: none;
}

@media screen and (min-width: 1025px) {
  .layout-navigation .menu.hover li:hover > input[type="checkbox"] + .submenu {
    width: 175px;
    opacity: 1;
    visibility: visible;
  }
}

@media screen and (min-width: 1025px) {
  .layout-navigation .menu.hover span.icon-dropdown {
    display: none;
  }
}

@media screen and (min-width: 1025px) {
  .layout-navigation .dropdown:first-child .submenu .submenu {
    left: -99%;
  }
}

.layout-navigation .menu-toggle {
  padding: 0 45px;
  border: 0;
  background-color: transparent;
  color: #5b5d58;
  font-size: 16px;
}

.layout-package-card-2 {
  margin: 0 auto;
  margin-top: 8px;
}

.layout-package-card-2 h4,
.layout-package-card-2 li,
.layout-package-card-2 p,
.layout-package-card-2 span,
.layout-package-card-2 ul {
  color: #424340;
}

.layout-package-card-2 section h4 {
  padding: 25px;
  font-weight: 300;
}

.layout-package-card-2 section a:first-of-type {
  padding: 0;
  background-color: inherit;
  text-align: left;
}

.layout-package-card-2 section a:last-of-type {
  font-size: 18px;
}

@media screen and (min-width: 0px) and (max-width: 640px) {
  .layout-package-card-2 section a:last-of-type {
    padding: 10.5px 45px;
    text-align: center;
  }
}

@media screen and (min-width: 1025px) {
  .layout-package-card-2 section a:last-of-type {
    padding: 18px 45px;
  }
}

.layout-package-card-2 section.control .price,
.layout-package-card-2 section.control h4,
.layout-package-card-2 section.protect .price,
.layout-package-card-2 section.protect h4,
.layout-package-card-2 section.starter .price,
.layout-package-card-2 section.starter h4 {
  padding-left: 30px;
  text-align: left;
}

.layout-package-card-2 section.control .price,
.layout-package-card-2 section.protect .price,
.layout-package-card-2 section.starter .price {
  padding-bottom: 10px;
  font-size: 32px;
}

.layout-package-card-2 section.control .price:after,
.layout-package-card-2 section.control .price:before,
.layout-package-card-2 section.protect .price:after,
.layout-package-card-2 section.protect .price:before,
.layout-package-card-2 section.starter .price:after,
.layout-package-card-2 section.starter .price:before {
  display: block;
  font-size: 13px;
  font-weight: 100;
}

.layout-package-card-2 section.control .price:after,
.layout-package-card-2 section.protect .price:after,
.layout-package-card-2 section.starter .price:after {
  content: "per month";
}

.layout-package-card-2 section.control .promotion,
.layout-package-card-2 section.control ul,
.layout-package-card-2 section.protect .promotion,
.layout-package-card-2 section.protect ul,
.layout-package-card-2 section.starter .promotion,
.layout-package-card-2 section.starter ul {
  font-size: 13px;
}

.layout-package-card-2 section.control ul,
.layout-package-card-2 section.protect ul,
.layout-package-card-2 section.starter ul {
  min-height: 240px;
}

.layout-package-card-2 section.control ul li,
.layout-package-card-2 section.protect ul li,
.layout-package-card-2 section.starter ul li {
  font-weight: 200;
  list-style: disc outside;
}

.layout-package-card-2 section.control .promotion,
.layout-package-card-2 section.protect .promotion,
.layout-package-card-2 section.starter .promotion {
  font-weight: 100;
  text-align: left;
}

.layout-package-card-2 section.control .promotion span,
.layout-package-card-2 section.protect .promotion span,
.layout-package-card-2 section.starter .promotion span {
  font-weight: 600;
}

.layout-package-card-2 section.control.flexpay .price,
.layout-package-card-2 section.protect.flexpay .price,
.layout-package-card-2 section.starter.flexpay .price {
  margin-bottom: 0;
  padding-bottom: 0;
}

.layout-package-card-2 section.control.short ul,
.layout-package-card-2 section.protect.short ul,
.layout-package-card-2 section.starter.short ul {
  min-height: 176px;
}

.layout-package-card-2 section.starter a:last-of-type,
.layout-package-card-2 section.starter h4 {
  background-color: #5daac7;
}

.layout-package-card-2 section.protect a:last-of-type,
.layout-package-card-2 section.protect h4 {
  background-color: #576877;
}

.layout-package-card-2 section.control a:last-of-type,
.layout-package-card-2 section.control h4 {
  background-color: #ff8200;
}

.layout-package-card-2 section.flexpay a:last-of-type {
  font-size: 18px;
  text-align: center;
}

@media screen and (min-width: 0px) and (max-width: 640px) {
  .layout-package-card-2 section.flexpay a:last-of-type {
    padding: 15px 45px;
  }
}

.layout-package-card-2 section.flexpay a:last-of-type,
.layout-package-card-2 section.flexpay h4 {
  background-color: #5daac7;
}

.layout-package-card-2 section.flexpay a:last-of-type:hover {
  color: #fff;
  text-decoration: underline;
}

.layout-package-card-2 section.flexpay .price:before {
  content: "Starting at";
}

.layout-package-card-2 section .disclaimer {
  display: none;
  padding: 0;
  padding-right: 7.5%;
  padding-bottom: 10px;
  padding-left: 5.5%;
  border: 0;
  font-size: 13px;
  text-align: left;
}

.layout-package-card-2 section .price,
.layout-package-card-2 section .promotion {
  padding-bottom: 20px;
}

.layout-package-card-2 section .promotion,
.layout-package-card-2 section ul {
  padding-top: 20px;
}

.layout-package-card-2 .highlight {
  margin-bottom: 0;
  padding: 5px 0;
  border: 0;
  background-color: #20ad8a;
  font-weight: 500;
}

.layout-package-card-2 .highlight.hidden {
  display: none;
}

.layout-package-card-2 .flexpay .price {
  padding-bottom: 0;
  border-bottom: 0;
}

.layout-package-card-2 .flexpay .disclaimer {
  display: block;
  padding-top: 0;
  border-bottom: 1px solid #b3b3b3;
}

.layout-package-card-2 .flexpay ul li {
  padding-bottom: 5px;
}

.layout-package-card-2 .flexpay ul:before {
  position: relative;
  top: -10px;
  left: -18px;
  color: #424340;
  font-family: roboto, sans-serif;
  font-weight: 300;
  content: "Includes six points of security protection";
}

.package-page.new .layout-package-card-2 section .price:before {
  display: none;
}

.spanish .layout-package-card-2 section.control .price:before,
.spanish .layout-package-card-2 section.protect .price:before,
.spanish .layout-package-card-2 section.starter .price:before {
  content: "Desde";
}

.spanish .layout-package-card-2 section.control .price:after,
.spanish .layout-package-card-2 section.protect .price:after,
.spanish .layout-package-card-2 section.starter .price:after {
  content: "al mes";
}

@media screen and (min-width: 641px) {
  .layout-promotion {
    margin: 20px auto;
  }
}

@media screen and (min-width: 1025px) {
  .layout-promotion {
    margin-top: 60px;
    margin-bottom: 100px;
  }
}

@media screen and (min-width: 0px) and (max-width: 640px) {
  .layout-promotion .container {
    margin-bottom: 120px;
  }
}

@media screen and (min-width: 1025px) {
  .layout-promotion.margined .image {
    margin-top: -60px;
  }
}

@media screen and (max-width: 1024px) {
  .layout-promotion img {
    max-width: 230px;
  }
}

.layout-promotion p {
  margin-bottom: 10px;
}

.layout-sandbox.testimonials {
  padding: 30px 0;
  text-align: center;
}

.layout-sandbox.testimonials h6 {
  padding: 0 10px;
}

.layout-sandbox.testimonials h6.quotee {
  width: 95%;
  margin: 0 auto;
}

@media screen and (min-width: 0px) and (max-width: 640px) {
  .layout-sandbox.testimonials h6.quotee {
    width: 85%;
  }
}

@media screen and (min-width: 641px) and (max-width: 1024px) {
  .layout-sandbox.testimonials h6.quotee {
    width: 75%;
  }
}

.layout-sandbox.testimonials p {
  position: relative;
  width: 95%;
  max-width: 750px;
  margin: 0 auto;
  padding: 20px;
  font-size: 22px;
  font-weight: 100;
  line-height: 1.5;
  text-align: left;
}

@media screen and (min-width: 0px) and (max-width: 640px) {
  .layout-sandbox.testimonials p {
    width: 85%;
  }
}

@media screen and (min-width: 641px) and (max-width: 1024px) {
  .layout-sandbox.testimonials p {
    width: 75%;
  }
}

.layout-sandbox.testimonials p:before {
  position: absolute;
  top: 0;
  left: 0;
  color: #ff8200;
  font-size: 50px;
  content: "“";
}

.layout-sandbox.testimonials .slick-slider {
  overflow: hidden;
}

.layout-sandbox.testimonials .slick-slider .slick-track {
  padding-bottom: 30px;
}

.layout-sandbox.testimonials .slick-slider .slick-dots {
  bottom: 0;
}

.layout-sandbox.testimonials .slick-slider .slick-dots li button:before {
  color: #ff8200;
  font-size: 46px;
}

.layout-sandbox.policy {
  padding: 40px 20px;
}

.layout-sandbox.policy h4 {
  padding-top: 20px;
}

.layout-sandbox.policy ul {
  padding-left: 0;
}

.layout-sandbox.policy ul li {
  padding: 10px 0;
  list-style-position: inside;
}

.layout-sandbox.policy ul.numbered {
  list-style-type: decimal;
}

.layout-sandbox.policy a {
  text-decoration: underline;
}

.layout-sandbox.img-cta {
  position: relative;
}

.layout-sandbox.img-cta .container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  max-width: 700px;
  z-index: 1;
}

.layout-sandbox.img-cta .image-contain {
  margin-right: 15px;
}

.layout-sandbox.img-cta .copy {
  max-width: 430px;
  height: 150px;
  margin: auto;
  margin-right: 0;
  margin-left: 15px;
}

.layout-sandbox.img-cta:before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 180px;
  margin: auto;
  background-color: #f7f4f1;
  content: "";
}

.layout-side-by-side {
  position: relative;
  padding: 30px 0;
}

@media screen and (min-width: 641px) {
  .layout-side-by-side {
    padding: 60px 0;
  }
}

@media screen and (min-width: 1025px) {
  .layout-side-by-side {
    padding: 90px 0;
  }
}

.layout-side-by-side h2,
.layout-side-by-side p {
  padding-bottom: 20px;
  font-weight: 100;
}

.layout-side-by-side li,
.layout-side-by-side p {
  font-size: 13px;
}

.layout-side-by-side ol,
.layout-side-by-side ul {
  padding-bottom: 30px;
  padding-left: inherit;
}

.layout-side-by-side ol li,
.layout-side-by-side ol p,
.layout-side-by-side ul li,
.layout-side-by-side ul p {
  padding: 7.5px 0;
  font-weight: 600;
}

.layout-side-by-side ol li,
.layout-side-by-side ul li {
  list-style-position: outside;
}

.layout-side-by-side ol li:first-of-type,
.layout-side-by-side ul li:first-of-type {
  padding-top: 0;
}

.layout-side-by-side ol li:last-of-type,
.layout-side-by-side ul li:last-of-type {
  padding-bottom: 0;
}

.layout-side-by-side ol.lighter li,
.layout-side-by-side ol.lighter p,
.layout-side-by-side ul.lighter li,
.layout-side-by-side ul.lighter p {
  font-weight: 100;
}

.layout-side-by-side ol.app-icons,
.layout-side-by-side ul.app-icons {
  list-style: none;
}

@media screen and (max-width: 1024px) {
  .layout-side-by-side img {
    max-height: 330px;
  }
}

.layout-side-by-side a:hover {
  text-decoration: underline;
}

.layout-side-by-side .disclaimer a {
  color: inherit;
}

.layout-side-by-side .disclaimer a:hover {
  text-decoration: underline;
}

@media screen and (max-width: 1024px) {
  .layout-side-by-side .content-container {
    padding-top: 25px;
  }
}

.layout-side-by-side section .content-disclaimer p {
  line-height: 1.5;
}

.layout-side-by-side.collapse-bottom .row,
.layout-side-by-side.collapse-top .row {
  max-width: 1000px;
}

.layout-side-by-side.collapse-top {
  padding-top: 20px;
}

.layout-side-by-side.collapse-bottom {
  padding-bottom: 20px;
}

@media screen and (min-width: 1025px) {
  .layout-side-by-side.light-content img {
    -webkit-transform: translateY(5%);
    transform: translateY(5%);
  }
}

@media screen and (min-width: 1025px) {
  .layout-side-by-side.lighter-content img {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

.layout-side-by-side.image-to-bottom {
  padding-bottom: 0;
}

@media screen and (min-width: 0px) and (max-width: 640px) {
  .layout-side-by-side.image-to-bottom {
    padding-bottom: 30px;
  }
}

@media screen and (min-width: 0px) and (max-width: 640px) {
  .layout-side-by-side.image-to-bottom .image-container {
    display: none;
  }
}

.layout-side-by-side.split-ul h4 {
  margin-top: 15px;
  font-weight: 100;
}

.layout-side-by-side.split-ul ul {
  -webkit-column-count: 2;
  column-count: 2;
}

.layout-side-by-side.split-ul p a {
  text-decoration: underline;
}

.layout-side-by-side.orange-disclaimer .disclaimer {
  color: #ff8200;
  font-size: 18px;
  line-height: 1.5;
}

.layout-side-by-side.orange-disclaimer .disclaimer strong {
  display: block;
}

.layout-side-by-side.big-disclaimer .disclaimer {
  font-size: 18px;
}

.equipment-piece .layout-side-by-side h4 {
  margin: 10px 0;
  font-weight: 100;
}

@media screen and (min-width: 641px) {
  .equipment-piece .layout-side-by-side section img {
    max-width: 550px;
    max-height: 500px;
  }
}

@media screen and (min-width: 0px) and (max-width: 640px) {
  .layout-side-by-side.img-hide .image-container {
    display: none;
  }
}

.layout-side-by-side.img-box-shadow img {
  -webkit-box-shadow: 15px 15px 20px rgba(73, 73, 73, 0.5);
  box-shadow: 15px 15px 20px rgba(73, 73, 73, 0.5);
}

@media screen and (min-width: 641px) {
  .layout-side-by-side.img-box-shadow img {
    -webkit-box-shadow: 10px 10px 40px rgba(0, 0, 0, 0.3);
    box-shadow: 10px 10px 40px rgba(0, 0, 0, 0.3);
  }
}

.layout-side-by-side.sbs-v2 h2 {
  font-size: 36px;
  font-weight: 700;
}

.layout-side-by-side.sbs-v2 p {
  font-size: 16px;
}

.layout-side-by-side.sbs-v2 .flex-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
  justify-content: space-around;
}

@media screen and (min-width: 1025px) {
  .layout-side-by-side.sbs-v2 .flex-container {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
}

.layout-side-by-side.sbs-v2 .flex-container ul {
  margin-right: 40px;
}

@media screen and (min-width: 641px) {
  .layout-side-by-side.sbs-v2 .flex-container ul {
    margin-right: 80px;
  }
}

.layout-side-by-side.sbs-v2 ul {
  padding-left: 40px;
}

@media screen and (min-width: 641px) {
  .layout-side-by-side.sbs-v2 ul {
    padding-left: 30px;
  }
}

.layout-side-by-side.sbs-v2 ul li {
  font-weight: 400;
}

.layout-side-by-side.sbs-v2 .image-container img {
  -webkit-box-shadow: 15px 15px 40px rgba(73, 73, 73, 0.5);
  box-shadow: 15px 15px 40px rgba(73, 73, 73, 0.5);
}

@media screen and (min-width: 641px) {
  .layout-side-by-side.sbs-v2 .image-container img {
    -webkit-box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.3);
    box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.3);
  }
}

.layout-side-by-side.sbs-v2 .image-container.no-img-box-shadow img {
  -webkit-box-shadow: none;
  box-shadow: none;
}

@media screen and (min-width: 1025px) {
  .layout-side-by-side.sbs-v2 .image-container.image-left img {
    margin-right: 65%;
    margin-left: 35%;
  }
}

.layout-side-by-side.sbs-v2 .content-container.no-padding-top {
  padding-top: 0;
}

@media screen and (min-width: 0px) and (max-width: 640px) {
  .layout-side-by-side.sbs-v2 .content-container.no-padding-top-mobile {
    padding-top: 0;
  }
}

@media screen and (min-width: 0px) and (max-width: 640px) {
  .layout-side-by-side.sbs-v2 .content-container.padding-bottom-mobile {
    padding-bottom: 40px;
  }
}

.updated .layout-side-by-side {
  padding: 15px 0;
}

@media screen and (min-width: 641px) {
  .updated .layout-side-by-side {
    padding: 30px 0;
  }
}

.updated .layout-side-by-side.tall {
  padding: 30px 0;
}

@media screen and (min-width: 641px) {
  .updated .layout-side-by-side.tall {
    padding: 60px 0;
  }
}

.updated .layout-side-by-side.tall-top {
  padding-top: 30px;
}

@media screen and (min-width: 641px) {
  .updated .layout-side-by-side.tall-top {
    padding-top: 60px;
  }
}

.updated .layout-side-by-side.tall-bottom {
  padding-bottom: 30px;
}

@media screen and (min-width: 641px) {
  .updated .layout-side-by-side.tall-bottom {
    padding-bottom: 60px;
  }
}

.updated .layout-side-by-side.image-to-bottom {
  padding-bottom: 0;
}

@media screen and (min-width: 0px) and (max-width: 640px) {
  .updated .layout-side-by-side.image-to-bottom.reverse .row {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }
}

@media screen and (min-width: 0px) and (max-width: 640px) and (min-width: 0px) and (max-width: 640px) {
  .updated .layout-side-by-side.image-to-bottom .image-container {
    display: block;
  }
}

.updated .layout-side-by-side h2 {
  padding: 0;
  font-weight: 700;
}

.updated .layout-side-by-side p {
  font-size: 16px;
  line-height: 24px;
  margin: 0;
  margin-top: 15px;
  padding: 0;
  color: #5b5d58;
  font-weight: 400;
}

.updated .layout-side-by-side ul {
  margin: 0;
  margin-top: 15px;
  margin-left: 10px;
  padding-bottom: 0;
}

.updated .layout-side-by-side ul li {
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 5px;
  padding: 0;
  color: #5b5d58;
  font-weight: 400;
}

@media screen and (min-width: 641px) and (max-width: 1024px) {
  .updated .layout-side-by-side .row {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }
}

.updated .layout-side-by-side section img {
  width: auto;
  max-height: 100%;
  margin: 0 auto;
}

@media screen and (min-width: 0px) and (max-width: 640px) {
  .updated .layout-side-by-side section.small-img img {
    width: 50%;
  }
}

@media screen and (min-width: 0px) and (max-width: 640px) {
  .updated .layout-side-by-side section.medium-img img {
    width: 75%;
  }
}

.updated .layout-side-by-side section.content-container,
.updated .layout-side-by-side section.image-container {
  width: 100%;
}

@media screen and (min-width: 1025px) {
  .updated .layout-side-by-side section.content-container,
  .updated .layout-side-by-side section.image-container {
    width: 50%;
  }
}

.updated .layout-side-by-side section.image-container {
  margin-top: 30px;
  padding: 0 0.9375rem;
}

@media screen and (min-width: 1025px) {
  .updated .layout-side-by-side section.image-container {
    margin-top: 0;
    padding: 0 25px;
  }
}

.updated .layout-side-by-side section.content-container {
  padding-top: 0;
}

.updated .layout-side-by-side.mobile-image-top.box-shadow .image-container {
  margin-bottom: 30px;
}

@media screen and (min-width: 641px) {
  .updated .layout-side-by-side.mobile-image-top.box-shadow .image-container {
    margin-bottom: 0;
  }
}

.updated .layout-side-by-side.mobile-image-top .image-container {
  margin-top: 0;
}

@media screen and (min-width: 0px) and (max-width: 640px) {
  .updated .layout-side-by-side.mobile-image-bottom .image-container {
    padding-bottom: 15px;
  }
}

@media screen and (min-width: 641px) and (max-width: 1024px) {
  .updated .layout-side-by-side.mobile-image-bottom .image-container {
    padding-bottom: 15px;
  }
  .updated .layout-side-by-side.mobile-image-bottom img {
    padding-top: 30px;
  }
}

@media screen and (min-width: 1025px) {
  .updated .layout-side-by-side.mobile-image-bottom img {
    padding-top: 0;
  }
}

.updated .layout-side-by-side.box-shadow img {
  -webkit-box-shadow: 15px 15px 40px rgba(73, 73, 73, 0.5);
  box-shadow: 15px 15px 40px rgba(73, 73, 73, 0.5);
}

@media screen and (min-width: 641px) {
  .updated .layout-side-by-side.box-shadow.double-image img {
    -webkit-box-shadow: none;
    box-shadow: none;
  }
}

@media screen and (max-width: 1024px) {
  .updated .layout-side-by-side.reverse section.content-container {
    padding-top: 15px;
  }
}

.new-style .layout-side-by-side ul {
  margin-left: 25px;
}

.new-style .layout-side-by-side ul li {
  font-weight: 100;
  line-height: 20px;
}

.layout-side-by-side.city-geo-sbs {
  padding-top: 20px;
}

@media screen and (min-width: 641px) {
  .layout-side-by-side.city-geo-sbs {
    padding-top: 35px;
    padding-bottom: 35px;
  }
}

@media screen and (min-width: 1025px) {
  .layout-side-by-side.city-geo-sbs {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

.layout-side-by-side.city-geo-sbs h2 {
  font-size: 24px;
  font-weight: 700;
}

@media screen and (min-width: 641px) {
  .layout-side-by-side.city-geo-sbs h2 {
    font-size: 36px;
  }
}

.layout-side-by-side.city-geo-sbs h3 {
  padding-top: 25px;
  font-size: 20px;
  font-weight: 700;
}

.layout-side-by-side.city-geo-sbs p {
  font-size: 16px;
  font-weight: 100;
}

.layout-side-by-side.city-geo-sbs section.image-container {
  padding-bottom: 20px;
}

@media screen and (min-width: 641px) {
  .layout-side-by-side.city-geo-sbs section.image-container {
    padding-bottom: 0;
  }
}

.layout-side-by-side.city-geo-sbs section.content-container {
  padding-bottom: 20px;
}

@media screen and (min-width: 1025px) {
  .layout-side-by-side.city-geo-sbs section.content-container {
    padding-bottom: 0;
  }
}

.layout-side-by-side.city-geo-sbs.img-box-shadow img {
  -webkit-box-shadow: 10px 10px 40px rgba(0, 0, 0, 0.3);
  box-shadow: 10px 10px 40px rgba(0, 0, 0, 0.3);
}

@media screen and (min-width: 641px) {
  .layout-side-by-side.city-geo-sbs.img-box-shadow img {
    -webkit-box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.3);
    box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.3);
  }
}

.layout-side-by-side.city-geo-sbs.img-box-shadow-mobile img {
  -webkit-box-shadow: 10px 10px 40px rgba(0, 0, 0, 0.3);
  box-shadow: 10px 10px 40px rgba(0, 0, 0, 0.3);
}

@media screen and (min-width: 641px) {
  .layout-side-by-side.city-geo-sbs.img-box-shadow-mobile img {
    -webkit-box-shadow: none;
    box-shadow: none;
  }
}

.layout-side-by-side.city-geo-sbs.padding-top {
  padding-top: 30px;
}

.layout-side-by-side.city-geo-sbs .content-container {
  padding-top: 0;
}

@media screen and (min-width: 641px) {
  .layout-side-by-side.city-geo-sbs .content-container {
    padding-top: 25px;
  }
}

.layout-side-by-side.city-geo-sbs ul {
  padding-bottom: 0;
  padding-left: 30px;
}

@media screen and (min-width: 641px) {
  .layout-side-by-side.city-geo-sbs ul {
    padding-bottom: 30px;
  }
}

.layout-side-by-side.city-geo-sbs ul li {
  font-size: 16px;
  font-weight: 100;
}

.layout-site-header {
  height: 64px;
  padding: 13px 0;
  padding-top: 13px;
}

@media screen and (max-width: 1024px) {
  .layout-site-header {
    height: 60px;
    padding-top: 15px;
  }
  .layout-site-header .right-block {
    display: none;
  }
}

.layout-site-header .brand-logo {
  -ms-flex-item-align: auto;
  align-self: auto;
  padding-top: 9px;
}

@media screen and (min-width: 1025px) {
  .layout-site-header .brand-logo {
    -ms-flex-item-align: baseline;
    align-self: baseline;
  }
}

.layout-site-header .brand-logo img {
  max-width: 130px;
}

@media screen and (min-width: 641px) {
  .layout-site-header .brand-logo img {
    max-width: 172px;
  }
}

.layout-site-header .container {
  display: block;
}

@media screen and (min-width: 1025px) {
  .layout-site-header .container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}

.layout-site-header .container .cta-phone {
  padding: 11px;
}

@media screen and (min-width: 1025px) {
  .layout-site-header .container .cta-phone {
    padding-right: 5px;
  }
}

.layout-site-header .container .cta-phone:before {
  font-size: 34px;
}

.layout-site-header .menu-toggle {
  position: relative;
  background-color: transparent;
}

.layout-site-header .menu-toggle:before {
  display: block;
  position: absolute;
  right: 0;
  padding-right: 45px;
  background-image: url("../images/icon-menu.png");
  background-repeat: no-repeat;
  background-position: 100%;
  content: "Menu";
}

.layout-site-header .menu-toggle.open:before {
  /* 
  background-image: url("../images/icon-close.png");
*/
  content: "Close";
}

.layout-site-header .responsive-menu-toggle-image {
  width: auto;
}

.layout-site-header .middle-block {
  display: block;
  position: static;
  top: 0;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0 auto;
  margin-right: 30px;
  float: right;
}

@media screen and (min-width: 1025px) {
  .layout-site-header .middle-block {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: relative;
    top: -10px;
    margin: 0 auto;
    float: none;
  }
}

.layout-site-header .middle-block .layout-navigation {
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.layout-site-header
  .middle-block
  .layout-navigation
  .menu-toggle-checkbox:checked
  + .menu {
  position: absolute;
  top: 88px;
  left: 0;
  width: 100%;
  max-height: 400px;
  -webkit-box-shadow: 0 12px 20px 0 hsla(0, 0%, 61%, 0.4);
  box-shadow: 0 12px 20px 0 hsla(0, 0%, 61%, 0.4);
  overflow-y: scroll;
}

@media screen and (orientation: landscape) {
  .layout-site-header
    .middle-block
    .layout-navigation
    .menu-toggle-checkbox:checked
    + .menu {
    max-height: 200px;
  }
}

.layout-site-header .right-block {
  -ms-flex-item-align: end;
  align-self: flex-end;
  font-size: 22px;
  font-weight: 100;
}

.layout-site-header .right-block span {
  color: #938e88;
}

.layout-site-header .right-block strong {
  color: #ff8200;
}

.layout-site-header .right-block a {
  color: inherit;
}

.layout-site-header .right-block a:hover {
  text-decoration: none;
}

.layout-site-header.pull-cta-right .container .right-block {
  margin-left: 0;
}

@media screen and (max-width: 1075px) and (min-width: 1020px) {
  .layout-site-header.pull-cta-right .container .right-block {
    font-size: 18px;
  }
}

.layout-site-header.pull-cta-right .container .right-block a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-item-align: end;
  align-self: flex-end;
}

.layout-site-header img {
  width: 150px;
  max-width: 250px;
  padding-left: 15px;
}

@media screen and (min-width: 641px) {
  .layout-site-header img {
    width: auto;
  }
}

.layout-site-header .mobile-nav-button img {
  padding-top: 20px;
}

.affix .layout-site-header {
  background-color: #fff;
  -webkit-box-shadow: 5px 5px 20px #9c9c9c;
  box-shadow: 5px 5px 20px #9c9c9c;
}

@media screen and (max-width: 1024px) {
  .affix .layout-site-header.plain {
    -webkit-box-shadow: none;
    box-shadow: none;
  }
}

.affix
  .layout-site-header
  .middle-block
  .layout-navigation
  .menu-toggle-checkbox:checked
  + .menu {
  top: 60px;
}

.layout-package-card-v3 {
  width: 290px;
  margin-right: auto;
  margin-bottom: 40px;
  margin-left: auto;
  -webkit-box-shadow: 15px 15px 40px rgba(0, 0, 0, 0.3);
  box-shadow: 15px 15px 40px rgba(0, 0, 0, 0.3);
}

@media screen and (min-width: 1025px) {
  .layout-package-card-v3 {
    width: 315px;
    margin-bottom: 50px;
  }
}

.layout-package-card-v3 h4 {
  padding: 9px 0;
  color: #fff;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
}

.layout-package-card-v3 .price-container {
  padding: 10px 0;
  background-color: #ff8200;
}

.layout-package-card-v3 .price-container .pre-price-text {
  margin-right: 10%;
  margin-bottom: 0;
  color: #fff;
  font-size: 10px;
  font-weight: 300;
  line-height: 16px;
  text-align: center;
}

.layout-package-card-v3 .price-container .price-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.layout-package-card-v3 .price-container .price-flex .currency {
  color: #fff;
  font-size: 20px;
  line-height: 26px;
}

.layout-package-card-v3 .price-container .price-flex .price-dollars {
  color: #fff;
  font-size: 50px;
  font-weight: 700;
  line-height: 42px;
}

.layout-package-card-v3 .price-container .price-flex .price-cents {
  margin-bottom: 0;
  color: #fff;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
}

.layout-package-card-v3 .price-container .price-flex .price-condition {
  padding-left: 2px;
  color: #fff;
  font-size: 20px;
  font-weight: 100;
  line-height: 26px;
}

.layout-package-card-v3 .price-container .price-flex .price-term {
  padding-left: 3px;
  color: #fff;
  font-size: 10px;
  font-weight: 100;
  line-height: 16px;
}

.layout-package-card-v3 .details-container {
  background-color: #fff;
}

.layout-package-card-v3 .details-container .detail-callout {
  margin-bottom: 0;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
}

.layout-package-card-v3 .details-container ul {
  margin-left: 15px;
  padding-top: 15px;
  padding-right: 10px;
  padding-left: 10px;
}

.layout-package-card-v3 .details-container ul li {
  font-size: 16px;
  font-weight: 100;
}

.layout-package-card-v3 .details-container ul li:not(.has-icon) {
  margin-left: 65px;
}

.layout-package-card-v3 .details-container ul .has-icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  list-style-type: none;
}

.layout-package-card-v3 .details-container ul img {
  width: 50px;
  margin-right: 20px;
}

.layout-package-card-v3 .details-container .button-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding-bottom: 25px;
}

.layout-package-card-v3 .details-container .button-container a {
  position: relative;
  padding-top: 30px;
  padding-bottom: 5px;
  color: #e57500;
  font-size: 18px;
  text-align: center;
}

.layout-package-card-v3 .details-container .button-container a:after {
  position: absolute;
  bottom: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  content: "";
}

.layout-package-card-v3 .details-container .button-container a:hover:after {
  width: 100%;
  -webkit-animation: 0.2s a ease-in;
  animation: 0.2s a ease-in;
}

.layout-package-card-v3 .flexpay-starter h4 {
  background-color: #e57500;
}

.layout-package-card-v3 .flexpay-starter .price-container {
  background-color: #ff8200;
}

.layout-package-card-v3 .flexpay-starter .details-container {
  border: 1px solid #938e88;
}

.layout-package-card-v3 .flexpay-starter .details-container .detail-callout {
  border-bottom: 1px solid #938e88;
}

.layout-package-card-v3
  .flexpay-starter
  .details-container
  .button-container
  a {
  color: #e57500;
}

.layout-package-card-v3
  .flexpay-starter
  .details-container
  .button-container
  a:after {
  border-bottom: 4px solid #e57500;
}

.layout-package-card-v3 .smart-home-plan h4 {
  background-color: #576877;
}

.layout-package-card-v3 .smart-home-plan .price-container {
  background-color: #6a7e91;
}

.layout-package-card-v3
  .smart-home-plan
  .details-container
  .button-container
  a {
  color: #576877;
}

.layout-package-card-v3
  .smart-home-plan
  .details-container
  .button-container
  a:after {
  border-bottom: 4px solid #576877;
}

.layout-package-card-v3 .smart-home-video-plan h4 {
  background-color: #5194ad;
}

.layout-package-card-v3 .smart-home-video-plan .price-container {
  background-color: #5daac7;
}

.layout-package-card-v3
  .smart-home-video-plan
  .details-container
  .button-container
  a {
  color: #5194ad;
}

.layout-package-card-v3
  .smart-home-video-plan
  .details-container
  .button-container
  a:after {
  border-bottom: 4px solid #5194ad;
}

.not-found-page .layout-feature.hero {
  /*
  background-image: url("../images/index-hero.png");
*/
  background-repeat: no-repeat;
  background-size: cover;
}

.business-page .layout-feature.hero {
  /*  background-image: url("../images/business/hero.png"); */
  background-repeat: no-repeat;
  background-size: cover;
}

.about .layout-feature.hero {
  /* background-image: url("../images/about/hero.png"); */
  background-repeat: no-repeat;
  background-size: cover;
}

.package-page-ppc .layout-feature.hero,
.package-page .layout-feature.hero {
  background-image: url("../images/package-hero-new.png");
  background-repeat: no-repeat;
  background-position: 40% top;
  background-size: cover;
}

@media screen and (min-width: 1025px) {
  .package-page-ppc .layout-feature.hero,
  .package-page .layout-feature.hero {
    background-position: 0 0;
  }
}

.vivint-starter .layout-feature.hero {
  /*  background-image: url("../images/vivintstarter/hero.png"); */
  background-repeat: no-repeat;
  background-size: cover;
}

.zwave .layout-feature.hero {
  /*
  background-image: url("../images/zwave-hero.jpg");
*/
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
}

.smart-protect .layout-feature.hero {
  /*  background-image: url("../images/smartprotect/hero.png"); */
  background-repeat: no-repeat;
  background-size: cover;
}

.equipment-piece .layout-feature.hero {
  /*  
  background-image: url("../images/keyless-hero.jpg");
*/
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
}

.smart-home .layout-feature.hero {
  /*
  background-image: url("../images/smart-home-hero.jpg");
*/
  background-repeat: no-repeat;
  background-size: cover;
}

.doorbell-camera-ppc .layout-feature.hero,
.doorbell-camera .layout-feature.hero {
  background-image: url("../images/home-services/doorbell-hero.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.security-cameras .layout-feature.hero {
  /* background-image: url("../images/home-services/camera-hero.png"); */
  background-repeat: no-repeat;
  background-size: cover;
}

.mobile-control .layout-feature.hero {
  /* background-image: url("../images/mobile-control/hero.png"); */
  background-repeat: no-repeat;
  background-size: cover;
}

.geo .layout-feature.hero {
  /*  background-image: url("../images/geo/hero.png"); */
  background-repeat: no-repeat;
  background-size: cover;
}

.automation-guide-ppc .layout-feature.hero,
.automation-guide .layout-feature.hero {
  /* background-image: url("../images/geo/overlay.png"); */
  background-repeat: no-repeat;
  background-size: cover;
}

.nest .layout-feature.hero {
  /*  background-image: url("../images/home-services/nest-hero.png"); */
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: cover;
}

.sky-control .layout-feature.hero {
  /*   background-image: url("../images/home-services/sky-controL-hero.png"); */
}

.amazon-echo .layout-feature.hero,
.sky-control .layout-feature.hero {
  background-repeat: no-repeat;
  background-position: 100%;
  background-size: cover;
}

.amazon-echo .layout-feature.hero {
  /*   background-image: url("../images/home-services/echo-hero.png"); */
}

.specials .layout-feature.hero {
  background-image: url("../images/specials-hero.png");
}

.specials .layout-feature.hero,
.vivint-playback .layout-feature.hero {
  background-repeat: no-repeat;
  background-position: 100%;
  background-size: cover;
}

.vivint-playback .layout-feature.hero {
  /* background-image: url("../images/home-services/playback-hero.png"); */
}

.garage-page .layout-feature.hero {
  /* background-image: url("../images/home-services/garager-doo-hero.png"); */
}

.flood-freeze-page .layout-feature.hero,
.garage-page .layout-feature.hero {
  background-repeat: no-repeat;
  background-position: 100%;
  background-size: cover;
}

.flood-freeze-page .layout-feature.hero {
  /* background-image: url("../images/home-services/flood-freez-hero.png"); */
}

.pendant-page .layout-feature.hero {
  /*  background-image: url("../images/home-services/pendant-hero.png"); */
}

.home-monitoring .layout-feature.hero,
.pendant-page .layout-feature.hero {
  background-repeat: no-repeat;
  background-position: 100%;
  background-size: cover;
}

.home-monitoring .layout-feature.hero {
  /*  background-image: url("../images/home-services/monitoring-hero.png"); */
}

.ping-camera-page .layout-feature.hero {
  /*  background-image: url("../images/home-services/ping-camera-hero.png"); */
  background-repeat: no-repeat;
  background-position: 100%;
  background-size: cover;
}

.contacts-page .layout-feature.hero {
  /* background-image: url("../images/contact-heros.png"); */
  background-repeat: no-repeat;
  background-position: 100%;
  background-size: cover;
}

.availability .layout-feature.hero {
  /*
  background-image: url("../images/availability-hero.jpg");
*/

  background-position: 36% top;
}

@media screen and (min-width: 1025px) {
  .availability .layout-feature.hero {
    padding-right: 25%;
    padding-left: 25%;
    background-position: 0 0;
  }
}

.geo-las-vegas-sec .layout-feature.hero {
  /*  background-image: url("../images/geo/las-vegas-security-hero-mobile.png"); */
  background-repeat: no-repeat;
  background-position: 100%;
  background-size: cover;
}

@media screen and (min-width: 641px) {
  /*   .geo-las-vegas-sec .layout-feature.hero {
    background-image: url("../images/geo/las-vegasy-securit-hero.png"); 
  } */
}

.geo-calgary-sec .layout-feature.hero {
  /*   background-image: url("../images/geo/calgary-security-hero-mobile.png"); */
  background-repeat: no-repeat;
  background-position: 100%;
  background-size: cover;
}

@media screen and (min-width: 641px) {
  /*   .geo-calgary-sec .layout-feature.hero {
    background-image: url("../images/geo/calgary-security-hero.png");
  }*/
}

.geo-lexington-sec .layout-feature.hero {
  /* background-image: url("../images/geo/lexington-security-hero-mobile.png"); */
  background-repeat: no-repeat;
  background-position: 100%;
  background-size: cover;
}

@media screen and (min-width: 641px) {
  /*   .geo-lexington-sec .layout-feature.hero {
    background-image: url("../images/geo/lexington-security-hero.png");
  } */
}

.geo-fayetteville-sec .layout-feature.hero {
  /*   background-image: url("../images/geo/fayetteville-security-hero-mobile.png"); */
  background-repeat: no-repeat;
  background-position: 100%;
  background-size: cover;
}

@media screen and (min-width: 641px) {
  /*   .geo-fayetteville-sec .layout-feature.hero {
    background-image: url("../images/geo/fayetteville-security-hero.png");
  } */
}

.geo-montgomery-sec .layout-feature.hero {
  /*   background-image: url("../images/geo/montgomery-security-hero-mobile.png"); */
  background-repeat: no-repeat;
  background-position: 100%;
  background-size: cover;
}

@media screen and (min-width: 641px) {
  /*   .geo-montgomery-sec .layout-feature.hero {
    background-image: url("../images/geo/montgomery-security-hero.png");
  } */
}

.geo-bakersfield-sec .layout-feature.hero {
  /*   background-image: url("../images/geo/bakersfield-automation-hero-mobile.png"); */
  background-repeat: no-repeat;
  background-position: 100%;
  background-size: cover;
}

@media screen and (min-width: 641px) {
  /*   .geo-bakersfield-sec .layout-feature.hero {
    background-image: url("../images/geo/bakersfield-automation-hero.png");
  } */
}

.geo-anchorage-auto .layout-feature.hero {
  /*   background-image: url("../images/geo/anchorage-automation-hero-mobile.png"); */
  background-repeat: no-repeat;
  background-position: 100%;
  background-size: cover;
}

@media screen and (min-width: 641px) {
  /*   .geo-anchorage-auto .layout-feature.hero {
    background-image: url("../images/geo/anchorage-automation-hero.png");
  } */
}

.geo-fairbanks-auto .layout-feature.hero {
  /*   background-image: url("../images/geo/anchorage-automation-hero-mobile.png"); */
  background-repeat: no-repeat;
  background-position: 100%;
  background-size: cover;
}

@media screen and (min-width: 641px) {
  /*   .geo-fairbanks-auto .layout-feature.hero {
    background-image: url("../images/geo/anchorage-automation-hero.png");
  } */
}

.geo-reno-auto .layout-feature.hero {
  /*   background-image: url("../images/geo/reno-automation-hero-mobile.png"); */
  background-repeat: no-repeat;
  background-position: 100%;
  background-size: cover;
}

@media screen and (min-width: 641px) {
  /*   .geo-reno-auto .layout-feature.hero {
    background-image: url("../images/geo/reno-automation-hero.png");
  } */
}

.geo-winnipeg-auto .layout-feature.hero {
  /*  background-image: url("../images/geo/winnipeg-automation-hero-mobile.png"); */
  background-repeat: no-repeat;
  background-position: 100%;
  background-size: cover;
}

@media screen and (min-width: 641px) {
  /*   .geo-winnipeg-auto .layout-feature.hero {
    background-image: url("../images/geo/winnipeg-automation-hero.png");
  } */
}

.layout-feature.hero .phone-side {
  margin: 0 auto;
}

.layout-feature.hero .phone-side p {
  width: 100%;
  max-width: 500px;
  border-radius: 5px;
  font-size: 20px;
  -webkit-box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.3);
  box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.3);
}

.layout-feature.hero .phone-side img {
  display: inline;
  position: relative;
  top: -2px;
  left: -5px;
}

@media screen and (max-width: 1024px) {
  .layout-feature.hero .phone-side.fixed {
    position: fixed;
    top: 60px;
    left: 0;
    width: 100%;
    z-index: 1;
  }
  .layout-feature.hero .phone-side.fixed p {
    max-width: 100%;
    margin: 0;
    border-radius: 0;
    -webkit-box-shadow: 0 3px 10px rgba(0, 0, 0, 0.3);
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.3);
  }
}

@supports (-ms-ime-align: auto) {
  .layout-feature.hero .phone-side.fixed {
    top: 60px;
  }
}

.element .layout-feature.hero {
  /* 
  background-image: url("../images/element-hero.png");
*/
  background-repeat: no-repeat;
  background-position: 100% 0;
  background-size: cover;
}

@media screen and (min-width: 0px) and (max-width: 640px) {
  .element .layout-feature.hero {
    background-position: 65% bottom;
  }
}

.element .layout-feature.hero .feature-content {
  width: 50%;
  float: left;
  text-align: left;
}

@media screen and (min-width: 0px) and (max-width: 640px) {
  .element .layout-feature.hero .feature-content {
    width: 60%;
    text-align: left;
  }
  .element .layout-feature.hero .feature-content h1 {
    font-size: 22px;
    line-height: 1.4em;
  }
  .element .layout-feature.hero .feature-content h2 {
    font-size: 18px;
    line-height: 1.4em;
  }
}

.element .layout-feature.hero .feature-content h2 {
  font-weight: 400;
}

.viv-smart-home .layout-feature.hero {
  /* background-image: url("../images/smart-home-hero-new.jpg"); */
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
}

.smart-monitoring .layout-feature.hero {
  /*   background-image: url("../images/smartmonitoring/hero.png"); */
  background-repeat: no-repeat;
  background-position: 10% top;
  background-size: cover;
}

@media screen and (min-width: 641px) {
  .smart-monitoring .layout-feature.hero {
    background-position: 0 0;
  }
}

.element .smart-monitoring .layout-feature.hero {
  /* background-image: url("../images/element-hero.png"); */
  background-repeat: no-repeat;
  background-position: 100% 0;
  background-size: cover;
}

@media screen and (min-width: 0px) and (max-width: 640px) {
  .element .smart-monitoring .layout-feature.hero {
    background-position: 65% bottom;
  }
}

.element .smart-monitoring .layout-feature.hero .feature-content {
  width: 50%;
  float: left;
  text-align: left;
}

@media screen and (min-width: 0px) and (max-width: 640px) {
  .element .smart-monitoring .layout-feature.hero .feature-content {
    width: 60%;
    text-align: left;
  }
  .element .smart-monitoring .layout-feature.hero .feature-content h1 {
    font-size: 22px;
    line-height: 1.4em;
  }
  .element .smart-monitoring .layout-feature.hero .feature-content h2 {
    font-size: 18px;
    line-height: 1.4em;
  }
}

.element .smart-monitoring .layout-feature.hero .feature-content h2 {
  font-weight: 400;
}

.layout-feature h5 {
  text-align: left;
}

.layout-feature #locations {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-right: 0;
  padding-left: 0;
  text-align: left;
}

.layout-feature #locations a {
  display: block;
  margin-right: 15px;
  padding: 5px 0;
  color: #5daac7;
  font-size: 13px;
  text-decoration: none;
}

.layout-feature #locations a:hover {
  color: #ff8200;
  text-decoration: underline;
}

.layout-feature h3 {
  margin-bottom: 15px;
  font-weight: 700;
}

.layout-feature h3 a {
  color: inherit;
}

.layout-feature h3 small {
  display: block;
  color: #5b5d58;
  font-size: 13px;
  line-height: 15px;
}

.layout-feature p a {
  color: #e57500;
}

.layout-feature .box.orange {
  width: 30%;
  margin-left: 35%;
  padding: 20px 30px;
  background-color: #cc6800;
  text-align: left;
}

@media screen and (min-width: 0px) and (max-width: 640px) {
  .layout-feature .box.orange {
    width: 100%;
    margin-left: 0;
  }
}

.layout-feature .box.orange a,
.layout-feature .box.orange h4 {
  color: #fff;
}

.layout-feature .box.orange h4 {
  font-weight: 700;
}

.layout-feature .box.orange h4 small {
  display: block;
  font-size: 13px;
  font-weight: 400;
}

.layout-feature .box.orange .btn {
  font-size: 13px;
}

.about .layout-hero.overlay {
  /*  background-image: url("../images/about/overlay.png"); */
  background-repeat: no-repeat;
  background-size: cover;
}

.business-page .layout-hero.overlay {
  /*   background-image: url("../images/business/overlay.png"); */
  background-repeat: no-repeat;
  background-size: cover;
}

.equipment-page .layout-hero.overlay.hero2-equipment {
  background-image: none;
}

@media screen and (min-width: 641px) and (max-width: 1024px) {
  .equipment-page .layout-hero.overlay.hero2-equipment {
    /* background-image: url("../images/equip-hero2-v2-medium.png"); */
    background-repeat: no-repeat;
    background-size: cover;
  }
}

@media screen and (min-width: 1025px) {
  .equipment-page .layout-hero.overlay.hero2-equipment {
    /* background-image: url("../images/equip-hero2-v2.png"); */
    background-repeat: no-repeat;
    background-size: cover;
  }
}

.home-page .layout-hero.overlay.hero-testimonial {
  /*  background-image: url("../images/homepage-v2/hero-testimonial-section-small.png"); */
  background-repeat: no-repeat;
  background-size: cover;
}

@media screen and (min-width: 641px) and (max-width: 1024px) {
  /*   .home-page .layout-hero.overlay.hero-testimonial {
    background-image: url("../images/homepage-v2/hero-testimonial-section-medium.png");
  } */
}

@media screen and (min-width: 1025px) {
  /*   .home-page .layout-hero.overlay.hero-testimonial {
    background-image: url("../images/homepage-v2/hero-testimonial-section.png");
  } */
}

.home-page .layout-hero.overlay.hero2-home {
  background-image: none;
}

@media screen and (min-width: 641px) {
  .home-page .layout-hero.overlay.hero2-home {
    /* background-image: url("../images/homepage-v2/hero2-homepage.png"); */
    background-repeat: no-repeat;
    background-size: cover;
  }
}

@media screen and (min-width: 641px) {
  .package-page .layout-hero.overlay.packages-choice-hero {
    /*     background-image: url("../images/packages/family-equipment-viewing.png");
 */
    background-repeat: no-repeat;
    background-size: cover;
  }
}

.package-page-ppc-canada .layout-hero.overlay.hero-testimonial,
.package-page-ppc .layout-hero.overlay.hero-testimonial {
  /*   background-image: url("../images/packages/packages-price-ppc-testimonial-mobile.png");
 */
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: cover;
}

@media screen and (min-width: 641px) and (max-width: 1024px) {
  .package-page-ppc-canada .layout-hero.overlay.hero-testimonial,
  .package-page-ppc .layout-hero.overlay.hero-testimonial {
    /*     background-image: url("../images/packages/packages-price-ppc-testimonial.png");
 */
    background-position: 100%;
  }
}

@media screen and (min-width: 1025px) {
  .package-page-ppc-canada .layout-hero.overlay.hero-testimonial,
  .package-page-ppc .layout-hero.overlay.hero-testimonial {
    /*     background-image: url("../images/packages/packages-price-ppc-testimonial.png");
 */
    background-position: 50%;
  }
}

.smart-protect .layout-hero.overlay {
  /*   background-image: url("../images/smartprotect/overlay.png");
 */
  background-repeat: no-repeat;
  background-size: cover;
}

.smart-control .layout-hero.overlay.hero-testimonial {
  /* background-image: url("../images/smart-home-auto-testimonial-v2.png"); */
  background-repeat: no-repeat;
  background-size: cover;
}

@media screen and (min-width: 641px) {
  .smart-control .layout-hero.overlay.hero2-smrt-home {
    /* background-image: url("../images/smart-home-auto-hero2-v2.png"); */
    background-repeat: no-repeat;
    background-size: cover;
  }
}

.vivint-starter .layout-hero.overlay {
  /*   background-image: url("../images/vivintstarter/overlay.png");
 */
  background-repeat: no-repeat;
  background-size: cover;
}

.doorbell-camera .layout-hero.overlay {
  /*   background-image: url("../images/home-services/doorbell-overlay.png");
 */
  background-repeat: no-repeat;
  background-size: cover;
}

.mobile-control .layout-hero.overlay {
  /*   background-image: url("../images/mobile-control/overlay.png");
 */
  background-repeat: no-repeat;
  background-size: cover;
}

.geo .layout-hero.overlay {
  /*   background-image: url("../images/geo/overlay.png");
 */
  background-repeat: no-repeat;
  background-size: cover;
}

.automation-guide-ppc .layout-hero.overlay,
.automation-guide .layout-hero.overlay {
  /* background-image: url("../images/automation-overlay.png"); */
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: cover;
}

.sky-control .layout-hero.overlay {
  /*   background-image: url("../images/home-services/sky-control-overlay.png");
 */
  background-repeat: no-repeat;
  background-size: cover;
}

.specials .layout-hero.overlay {
  background-image: url("../images/installation-overlay.png");
  background-repeat: no-repeat;
  background-position: 100%;
  background-size: cover;
}

.viv-smart-home .layout-hero.overlay.first {
  background-color: #fff;
}

@media screen and (min-width: 641px) {
  /*   .viv-smart-home .layout-hero.overlay.first {
     background-image: url("../images/smart-home-overlay1.jpg"); 
  } */
}

@media screen and (min-width: 641px) and (max-width: 1024px) {
  .viv-smart-home .layout-hero.overlay.first {
    background-position: 31% top;
  }
}

.viv-smart-home .layout-hero.overlay.second {
  background-color: #fff;
}

@media screen and (min-width: 641px) {
  /*   .viv-smart-home .layout-hero.overlay.second {
    background-image: url("../images/smart-home-overlay2.jpg");
  } */
}

.viv-smart-home .layout-hero.overlay.third {
  background-color: #fff;
}

@media screen and (min-width: 641px) {
  /*   .viv-smart-home .layout-hero.overlay.third {
    background-image: url("../images/smart-home-overlay3.jpg");
  } */
}

@media screen and (min-width: 641px) and (max-width: 1024px) {
  .viv-smart-home .layout-hero.overlay.third {
    background-position: 27% top;
  }
}

.zwave .layout-hero.overlay.first {
  background-color: #fff;
}

@media screen and (min-width: 641px) {
  /*   .zwave .layout-hero.overlay.first {
    background-image: url("../images/zwave-overlay1.jpg");
  } */
}

.zwave .layout-hero.overlay.second {
  background-color: #fff;
}

@media screen and (min-width: 641px) {
  /*   .zwave .layout-hero.overlay.second {
    background-image: url("../images/zwave-overlay2.jpg");
  } */
}

.zwave .layout-hero.overlay.third {
  background-color: #fff;
}

@media screen and (min-width: 641px) {
  /*   .zwave .layout-hero.overlay.third {
    background-image: url("../images/zwave-overlay3.jpg");
  } */
}

.element .layout-hero.overlay.alexa {
  /*  background-image: url("../images/element-echo-bg.png"); */
  background-repeat: no-repeat;
  background-size: cover;
}

@media screen and (min-width: 0px) and (max-width: 640px) {
  .element .layout-hero.overlay.alexa {
    background-color: #938e88;
  }
  .element .layout-hero.overlay.alexa img {
    margin: 0 auto;
  }
}

.element .layout-hero.overlay.alexa h2,
.element .layout-hero.overlay.alexa p,
.element .layout-hero.overlay.alexa ul li {
  color: #fff;
}

.smart-monitoring .layout-hero.overlay {
  /*   background-image: url("../images/smartmonitoring/safe.png");
 */
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
}

@media screen and (min-width: 0px) and (max-width: 640px) {
  .smart-monitoring .layout-hero.overlay {
    padding-top: 210px;
    background-color: #424340;
    /*     background-image: url("../images/smartmonitoring/safe-mobile.png");
 */
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: contain;
  }
}

.element .smart-monitoring .layout-hero.overlay.alexa {
  /*  background-image: url("../images/element-echo-bg.png"); */
  background-repeat: no-repeat;
  background-size: cover;
}

@media screen and (min-width: 0px) and (max-width: 640px) {
  .element .smart-monitoring .layout-hero.overlay.alexa {
    background-color: #938e88;
  }
  .element .smart-monitoring .layout-hero.overlay.alexa img {
    margin: 0 auto;
  }
}

.element .smart-monitoring .layout-hero.overlay.alexa h2,
.element .smart-monitoring .layout-hero.overlay.alexa p,
.element .smart-monitoring .layout-hero.overlay.alexa ul {
  color: #fff;
}

.layout-hero.overlay.city-geo-hero2 {
  background-image: none;
}

@media screen and (min-width: 641px) {
  .layout-hero.overlay.city-geo-hero2 {
    /*     background-image: url("../images/geo/city-geo-section6-technician.png");
 */
    background-repeat: no-repeat;
    background-size: cover;
  }
}

.nest .layout-2-column.overlay {
  /*   background-image: url("../images/home-services/nest-overlay.png");
 */
}

.nest .layout-2-column.overlay,
.vivint-playback .layout-2-column.overlay {
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
}

.vivint-playback .layout-2-column.overlay {
  /*   background-image: url("../images/home-services/playback-overlay.png");
 */
}

.garage-page .layout-2-column.overlay {
  /*   background-image: url("../images/home-services/garage-door-overlay.png");
 */
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
}

.flood-freeze-page .layout-2-column.overlay {
  /*   background-image: url("../images/home-services/flood-freeze-overlay.png");
 */
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
}

.doorbell-camera-ppc .layout-2-column.overlay {
  /*   background-image: url("../images/home-services/doorbell-overlay.png");
 */
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
}

.equipment-page .layout-2-column.overlay.lead-form-2col-hero {
  background-image: none;
}

@media screen and (min-width: 1025px) {
  .equipment-page .layout-2-column.overlay.lead-form-2col-hero {
    /* background-image: url("../images/equipment-page-v2.png"); */
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;
  }
}

@media screen and (min-width: 0px) and (max-width: 640px) {
  .equipment-page .layout-2-column.overlay.lead-form-2col-hero .column-1 {
    /* background-image: url("../images/equipment-page-v2-small.png"); */
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;
  }
}

@media screen and (min-width: 641px) and (max-width: 1024px) {
  .equipment-page .layout-2-column.overlay.lead-form-2col-hero .column-1 {
    /* background-image: url("../images/equipment-page-v2-medium.png"); */
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;
  }
}

.home-page .layout-2-column.overlay.lead-form-2col-hero {
  background-image: none;
}

@media screen and (min-width: 1025px) {
  .home-page .layout-2-column.overlay.lead-form-2col-hero {
    background-image: url("../images/home-page-new.png");
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;
  }
}

@media screen and (min-width: 0px) and (max-width: 640px) {
  .home-page .layout-2-column.overlay.lead-form-2col-hero .column-1 {
    background-image: url("../images/home-page-new-small.png");
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;
  }
}

@media screen and (min-width: 641px) and (max-width: 1024px) {
  .home-page .layout-2-column.overlay.lead-form-2col-hero .column-1 {
    background-image: url("../images/home-page-new-medium.png");
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;
  }
}

.package-page .layout-2-column.overlay.lead-form-2col-hero {
  background-image: none;
}

@media screen and (min-width: 1025px) {
  .package-page .layout-2-column.overlay.lead-form-2col-hero {
    /*     background-image: url("../images/packages/packages-page-v2.png");
 */
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;
  }
}

@media screen and (min-width: 0px) and (max-width: 640px) {
  .package-page .layout-2-column.overlay.lead-form-2col-hero .column-1 {
    /*     background-image: url("../images/packages/packages-page-v2-small.png");
 */
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;
  }
}

@media screen and (min-width: 641px) and (max-width: 1024px) {
  .package-page .layout-2-column.overlay.lead-form-2col-hero .column-1 {
    /*     background-image: url("../images/packages/packages-page-v2.png");
 */
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;
  }
}

.package-page-ppc-canada .layout-2-column.overlay.lead-form-2col-hero,
.package-page-ppc .layout-2-column.overlay.lead-form-2col-hero {
  background-image: none;
}

@media screen and (min-width: 1025px) {
  .package-page-ppc-canada .layout-2-column.overlay.lead-form-2col-hero,
  .package-page-ppc .layout-2-column.overlay.lead-form-2col-hero {
    /*     background-image: url("../images/packages/packages-price-page-ppc-v2.png");
 */
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;
  }
}

@media screen and (min-width: 0px) and (max-width: 640px) {
  .package-page-ppc-canada
    .layout-2-column.overlay.lead-form-2col-hero
    .column-1,
  .package-page-ppc .layout-2-column.overlay.lead-form-2col-hero .column-1 {
    /*     background-image: url("../images/packages/packages-price-page-ppc-v2-small.png");
 */
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;
  }
}

@media screen and (min-width: 641px) and (max-width: 1024px) {
  .package-page-ppc-canada
    .layout-2-column.overlay.lead-form-2col-hero
    .column-1,
  .package-page-ppc .layout-2-column.overlay.lead-form-2col-hero .column-1 {
    /*     background-image: url("../images/packages/packages-price-page-ppc-v2.png");
 */
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;
  }
}

.pendant-page .layout-2-column.overlay {
  /*   background-image: url("../images/home-services/pendant-overlay.png");
 */
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
}

.ping-camera-page .layout-2-column.overlay {
  /*   background-image: url("../images/home-services/ping-camera-overlay.png");
 */
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
}

.smart-control .layout-2-column.overlay.lead-form-2col-hero {
  background-image: none;
}

@media screen and (min-width: 1025px) {
  .smart-control .layout-2-column.overlay.lead-form-2col-hero {
    /* background-image: url("../images/smart-home-automation-v2.png"); */
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;
  }
}

@media screen and (min-width: 0px) and (max-width: 640px) {
  .smart-control .layout-2-column.overlay.lead-form-2col-hero .column-1 {
    /* background-image: url("../images/smart-home-automation-v2-small.png"); */
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;
  }
}

@media screen and (min-width: 641px) and (max-width: 1024px) {
  .smart-control .layout-2-column.overlay.lead-form-2col-hero .column-1 {
    /*   background-image: url("../images/smart-home-automation-v2.png"); */
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;
  }
}

.specials .layout-2-column.overlay.lead-form-2col-hero {
  background-image: none;
}

@media screen and (min-width: 1025px) {
  .specials .layout-2-column.overlay.lead-form-2col-hero {
    /* background-image: url("../images/specials-v2.png"); */
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;
  }
}

@media screen and (min-width: 0px) and (max-width: 640px) {
  .specials .layout-2-column.overlay.lead-form-2col-hero .column-1 {
    /*   background-image: url("../images/specials-v2-small.png"); */
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;
  }
}

@media screen and (min-width: 641px) and (max-width: 1024px) {
  .specials .layout-2-column.overlay.lead-form-2col-hero .column-1 {
    /* background-image: url("../images/specials-v2.png"); */
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;
  }
}

.after-hours .layout-2-column.form-hero {
  /* background-image: url("../images/after-hours-hero.png"); */
  background-repeat: no-repeat;
  background-size: cover;
}

.generic-geo .layout-2-column.form-hero {
  /*  background-image: url("../images/geo/generic-hero.png"); */
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: cover;
}

.home-page .layout-2-column.package-preview-v2 .column-1 .preview {
  /*   background-image: url("../images/homepage-v2/package-preview-console.png");
 */
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: cover;
}

.home-page .layout-2-column.package-preview-v2 .column-2 .preview {
  /*   background-image: url("../images/homepage-v2/package-preview-video.png");
 */
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: cover;
}

.layout-4-column .box {
  display: block;
  height: 165px;
  padding: 20px 10px;
  color: #fff;
  font-size: 20px;
  font-weight: 700;
  text-align: center;
}

@media screen and (min-width: 0px) and (max-width: 640px) {
  .layout-4-column .box {
    margin-bottom: 25px;
  }
}

.layout-4-column .box:before {
  display: block;
  position: absolute;
  bottom: 5px;
  left: 50%;
  width: 0;
  height: 0;
  margin-left: -10px;
  border-bottom-width: 0;
  border: 10px solid transparent;
  border-top-color: #cc6800;
  color: #000;
  content: "";
  z-index: 100;
}

@media screen and (min-width: 0px) and (max-width: 640px) {
  .layout-4-column .box:before {
    bottom: 37px;
  }
}

.layout-4-column .box:after {
  display: block;
  position: absolute;
  bottom: 0;
  left: 5%;
  width: 90%;
  height: 37px;
  background-color: #fff;
  content: "";
}

@media screen and (min-width: 0px) and (max-width: 640px) {
  .layout-4-column .box:after {
    bottom: 25px;
  }
}

.layout-4-column .box.orange {
  background-color: #cc6800;
}

.layout-4-column .box.orange:after {
  background-color: #ff8200;
}

.layout-4-column .box.gray {
  background-color: #45525e;
}

.layout-4-column .box.gray:before {
  border-color: transparent;
  border-top-color: #45525e;
}

.layout-4-column .box.gray:after {
  background-color: #6a7e91;
}

.layout-4-column .box.teal {
  background-color: #457e94;
}

.layout-4-column .box.teal:before {
  border-color: transparent;
  border-top-color: #457e94;
}

.layout-4-column .box.teal:after {
  background-color: #5daac7;
}

.layout-4-column .box.blue {
  background-color: #334494;
}

.layout-4-column .box.blue:before {
  border-color: transparent;
  border-top-color: #334494;
}

.layout-4-column .box.blue:after {
  background-color: #455cc7;
}

.layout-4-column .box.btn:hover {
  text-decoration: none;
}

.not-found-page .layout-hero-2 {
  padding: 180px 0;
}

.dash li {
  position: relative;
  font-weight: 400;
  list-style-type: none;
}

.dash li strong {
  display: block;
  position: relative;
  left: -10px;
  color: #e57500;
  font-weight: 700;
}

.dash li strong span {
  color: #e57500;
  font-family: inherit;
  font-weight: 700;
}

.small-centered {
  text-align: inherit;
}

@media screen and (min-width: 0px) and (max-width: 640px) {
  .small-centered {
    text-align: center;
  }
}

.wistia-responsive-padding {
  position: relative;
  padding: 0;
  padding-top: 56.25%;
}

.wistia-responsive-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@media screen and (min-width: 641px) {
  .package-page.new .layout-call-to-action.overlay2.orange,
  .package-page.new .layout-call-to-action.overlay.orange {
    height: 180px;
    padding-top: 55px;
  }
}

.package-page.new .layout-call-to-action.overlay2.orange strong,
.package-page.new .layout-call-to-action.overlay.orange strong {
  background-color: #ff8200;
  font-weight: 600;
}

@media screen and (min-width: 641px) {
  .package-page.new .layout-call-to-action.overlay2.orange strong,
  .package-page.new .layout-call-to-action.overlay.orange strong {
    margin-left: 30px;
  }
}

.package-page.new .layout-package-card-2.second .control a,
.package-page.new .layout-package-card-2.second .control h4 {
  background-color: #6a7e91;
}

.package-page.new .small-disclaimer {
  font-size: 10px;
  text-decoration: underline;
}

.flex-pay-box section {
  margin: 0 auto;
  padding: 25px 40px;
  padding-top: 10px;
  background-color: #ff8200;
}

@media screen and (min-width: 641px) {
  .flex-pay-box section {
    width: 80%;
  }
}

.flex-pay-box section.box-shadow {
  margin-bottom: 40px;
  -webkit-box-shadow: 15px 15px 40px rgba(0, 0, 0, 0.3);
  box-shadow: 15px 15px 40px rgba(0, 0, 0, 0.3);
}

.flex-pay-box section p {
  color: #fff;
  font-size: 22px;
  line-height: 28px;
}

.flex-pay-box section p strong {
  display: block;
  padding: 15px 0;
  font-size: 34px;
}

@media screen and (min-width: 641px) {
  .new-style .layout-call-to-action.overlay {
    height: 180px;
    padding-top: 55px;
  }
}

@media screen and (min-width: 0px) and (max-width: 640px) {
  .new-style .border-box {
    width: 100%;
    margin-top: 15px;
    padding: 15px 0;
    border: 1px solid #605d59;
    background-color: transparent;
    text-align: center;
  }
  .new-style .border-box span {
    display: block;
    width: 60%;
    margin: 0 auto;
    margin-bottom: 10px;
  }
  .new-style .border-box strong {
    display: block;
    width: 100%;
    padding: 10px 0;
    background-color: #e57500;
    font-weight: 200;
  }
  .new-style .border-box strong strong {
    display: inline;
    font-weight: 700;
  }
}

.btn-see-more.text-center {
  display: block;
  padding-top: 15px;
  /*  background-image: url("../images/down-icon.png"); */
  background-repeat: no-repeat;
  background-position: 68% 76%;
  color: #424340;
  font-size: 18px;
  font-weight: 200;
  text-align: center;
}

.btn-see-more.text-center.open {
  /*   background-image: url("../images/up-icon.png"); */
}

.blue-opaque .btn-see-more.text-center,
.bluegray .btn-see-more.text-center,
.dark-gray .btn-see-more.text-center,
.opaque .btn-see-more.text-center {
  /* background-image: url("../images/down-icon-white.png"); */
  color: #fff;
}

.blue-opaque .btn-see-more.text-center.open,
.bluegray .btn-see-more.text-center.open,
.dark-gray .btn-see-more.text-center.open,
.opaque .btn-see-more.text-center.open {
  /* background-image: url("../images/up-icon-white.png"); */
}

.collapse-list h3 {
  margin: 10px 0;
  margin-bottom: 0;
  padding-left: 25px;
  /*  background-image: url("../images/icon-plus.png"); */
  background-repeat: no-repeat;
  background-position: left 6px;
}

@media screen and (min-width: 1025px) {
  .collapse-list h3 {
    padding-left: 0;
    background-image: none;
  }
  .collapse-list h3:hover {
    cursor: default;
  }
}

.collapse-list h3.minus {
  /*  background-image: url("../images/icon-minus.png"); */
}

.collapse-list h3:hover {
  cursor: pointer;
}

.collapse-list .column-1,
.collapse-list.layout-2-column .before {
  margin-bottom: 0;
}

@media screen and (min-width: 1025px) {
  .collapse-list .column-1 {
    margin-bottom: 20px;
  }
}

.collapse-list .column-2 {
  padding-bottom: 0;
}

.collapse-list .active {
  display: block !important;
}

.equipment-list .before h3 {
  padding-top: 30px;
}

.equipment-list img {
  padding-bottom: 10px;
}

.equipment-list .columns {
  padding-bottom: 30px;
}

@media screen and (min-width: 641px) {
  .equipment-list .columns {
    padding-bottom: 0;
  }
}

.equipment-list.second .before h3 {
  padding-top: 0;
}

.block {
  display: block;
  padding-bottom: 5px;
}
header {
  height: 60px;
}
@media screen and (min-width: 1024px) {
  .layout-navigation {
    margin-top: -60px;
  }
  .layout-navigation .menu li.dropdown,
  .layout-navigation .menu li.standard {
    background: #fff;
    z-index: 999;
  }
}
